import { deletePaintProposalAPI, getAllPaintProposalAPI, getAllProposalTemplatesAPI, getPaintProposalDetailAPI, savePaintPropsosalAPI, updatePaintProposalAPI } from "../../../utils/requests/proposals";
import { PROPOSAL_CREATE_FAIL, PROPOSAL_CREATE_REQUEST, PROPOSAL_CREATE_SUCCESS, PROPOSAL_DELETE_FAIL, PROPOSAL_DELETE_REQUEST, PROPOSAL_DELETE_SUCCESS, PROPOSAL_DETAIL_FAIL, PROPOSAL_DETAIL_REQUEST, PROPOSAL_DETAIL_SUCCESS, PROPOSAL_DOWNLOAD_FAIL, PROPOSAL_DOWNLOAD_REQUEST, PROPOSAL_DOWNLOAD_SUCCESS, PROPOSAL_LIST_FAIL, PROPOSAL_LIST_REQUEST, PROPOSAL_LIST_SUCCESS, PROPOSAL_SET_SELECTED_SECTIONS, PROPOSAL_SET_VALUES, PROPOSAL_TEMPLATE_LIST_FAIL, PROPOSAL_TEMPLATE_LIST_REQUEST, PROPOSAL_TEMPLATE_LIST_SUCCESS, PROPOSAL_UPDATE_FAIL, PROPOSAL_UPDATE_REQUEST, PROPOSAL_UPDATE_SUCCESS } from "../../constants/proposalConstants";
import axios from "axios";
import { saveSelectedSections } from "../proposals/proposals.actions";
import { isEmpty, toastError, toastSuccess } from "../../../utils/helpers/helper";

export const getProposalTemplates = () => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_TEMPLATE_LIST_REQUEST,
    });
    return getAllProposalTemplatesAPI()
      .then(async (response) => {
        dispatch({
          type: PROPOSAL_TEMPLATE_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROPOSAL_TEMPLATE_LIST_FAIL,
          payload: error,
        });
      });
  };
}

export const savePaintProposal = (data, download = false) => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_CREATE_REQUEST,
    });
    return savePaintPropsosalAPI(data)
      .then(async (response) => {
        toastSuccess("Proposal Saved Successfully");

        dispatch({
          type: PROPOSAL_CREATE_SUCCESS,
          payload: data,
        });

        dispatch(saveSelectedSections(data.id, data.sections));
        dispatch(saveTemplateType(data.id, data.proposal_template));

        if (download) {
          document.location.href = `/paint_proposal?show_tab=recent&download_id=${response.data?.id}&name=${data?.proposal_template?.name.includes("Paint") ? "Paint Proposal" : "Drywall Proposal"}`;
        } else {
          document.location.href = `/paint_proposal?show_tab=recent`;
        }
      })
      .catch((error) => {
        toastError("Proposal Creation Failed");
        dispatch({
          type: PROPOSAL_CREATE_FAIL,
          payload: error.data,
        });
      });
  };
};

export const getPaintProposals = (page = 1) => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_LIST_REQUEST,
    });
    return getAllPaintProposalAPI(page)
      .then(async (response) => {
        dispatch({
          type: PROPOSAL_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROPOSAL_LIST_FAIL,
          payload: error,
        });
      });
  };
};

export const getPaintProposalDetail = (proposalId) => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_DETAIL_REQUEST,
    });
    return getPaintProposalDetailAPI(proposalId)
      .then(async (response) => {
        dispatch({
          type: PROPOSAL_DETAIL_SUCCESS,
          payload: response,
        });

        dispatch({
          type: PROPOSAL_SET_SELECTED_SECTIONS,
          payload: response.sections,
        });

        dispatch({ type: PROPOSAL_SET_VALUES, payload: {
          ...response,
          proposal_template: response.proposal_template.name,
          user_scope: response.proposal_template.user_scope,
          scope: response.proposal_template.scope,
          disclosure: response.proposal_template.disclosure
        } });
      })
      .catch((error) => {
        dispatch({
          type: PROPOSAL_DETAIL_FAIL,
          payload: error,
        });
      });
  };
};

export const updatePaintProposal = (proposalId, data, download = false) => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_UPDATE_REQUEST,
    });
    return updatePaintProposalAPI(proposalId, data)
      .then(async (response) => {
        toastSuccess("Proposal Updated Successfully");
        dispatch({
          type: PROPOSAL_UPDATE_SUCCESS,
          payload: response,
        });

        dispatch(saveSelectedSections(data.id, data.sections));
        dispatch(saveTemplateType(data.id, data.proposal_template));

        if (download) {
          toastSuccess("Proposal Download Started");
          document.location.href = `/paint_proposal?show_tab=recent&download_id=${proposalId}&name=${data?.proposal_template?.name.includes("Paint") ? "Paint Proposal" : "Drywall Proposal"}`;
        } else {
          document.location.href = "/paint_proposal?show_tab=recent";
        }
      })
      .catch((error) => {
        toastError("Proposal Update Failed");
        dispatch({
          type: PROPOSAL_UPDATE_FAIL,
          payload: error.data,
        });
      });
  };
};

export const deletePaintProposal = (proposalId, editId) => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_DELETE_REQUEST,
    });
    return deletePaintProposalAPI(proposalId)
      .then(async (response) => {
        toastSuccess("Proposal Deleted Successfully");
        dispatch({
          type: PROPOSAL_DELETE_SUCCESS,
          payload: proposalId,
        });
        if (editId !== undefined) {
          window.location.href = "/paint_proposal?show_tab=recent";
        }
      })
      .catch((error) => {
        toastError("Proposal Deletion Failed");
        dispatch({
          type: PROPOSAL_DELETE_FAIL,
          payload: error,
        });
      });
  };
};

export const downloadPaintProposal =
  (proposalId, name) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROPOSAL_DOWNLOAD_REQUEST,
      });
      let { access } = getState();
      if (isEmpty(access)) {
        access = JSON.parse(localStorage.getItem("token"));
      }
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        responseType: "blob",
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/paint_proposals/download/${proposalId}`,
        {proposalId: proposalId},
        config
      );
      dispatch({
        type: PROPOSAL_DOWNLOAD_SUCCESS,
      });
      const url = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${name}.docx`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;

      toastError("Proposal Download Failed");
      dispatch({
        type: PROPOSAL_DOWNLOAD_FAIL,
        payload: error.data,
      });
    }
  };

export const saveTemplateType = (user_id, template_name) => {
  return async () => {
    localStorage.setItem(`template_${user_id}`, template_name);
  };
};
