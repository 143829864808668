export const CRM_LIST_REQUEST = "CRM_LIST_REQUEST";
export const CRM_LIST_SUCCESS = "CRM_LIST_SUCCESS";
export const CRM_LIST_FAIL = "CRM_LIST_FAIL";

export const CRM_CREATE_REQUEST = "CRM_CREATE_REQUEST";
export const CRM_CREATE_SUCCESS = "CRM_CREATE_SUCCESS";
export const CRM_CREATE_FAIL = "CRM_CREATE_FAIL";

export const CRM_UPDATE_REQUEST = "CRM_UPDATE_REQUEST";
export const CRM_UPDATE_SUCCESS = "CRM_UPDATE_SUCCESS";
export const CRM_UPDATE_FAIL = "CRM_UPDATE_FAIL";

export const CRM_DELETE_REQUEST = "CRM_DELETE_REQUEST";
export const CRM_DELETE_SUCCESS = "CRM_DELETE_SUCCESS";
export const CRM_DELETE_FAIL = "CRM_DELETE_FAIL";