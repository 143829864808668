import React, { useState, useEffect, useCallback } from "react";
import { Table, Modal, Button, Form, Row, Col, Container, Dropdown, ButtonGroup } from "react-bootstrap";
import { DatatableWrapper, Pagination, PaginationOptions, TableBody, TableHeader } from "react-bs-datatable";
import AddContactModal from "../../components/addContactModal";
import { useDispatch } from "react-redux";
import { deleteCrmContact, getCrmContacts, updateCrmContact } from "../../store/actions/crm/crm.action";
import { useSelector } from "react-redux";
import "./index.css";
import EmailModal from "../../components/emailModal";
import { updateContactLastDateByName } from "../../store/actions/projects/projects.action";
import { addUserContactEmail } from "../../store/actions/contacts/contacts.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faEdit, faEllipsisV, faEnvelope, faSave, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import Spinner from "../../components/spinner/Spinner";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";
import { addPageFavorite, createPageView, fetchPageFavorite, removePageFavorite } from "../../store/actions/users/users.actions";
import { useNavigate } from "react-router-dom";

const ContactManagement = () => {
  const path = window.location.pathname.slice(1);
  const { user } = useSelector((state) => state.auth);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { user: profile } = useSelector((state) => state.userDetails);
  const { contacts: contactsList, loading, error } = useSelector((state) => state.crmActions);
  const { lastDate } = useSelector((state) => state.projects);
  const { hasFeatureAccess, enforceAccess } = useFeatureAccess("Contact Management");
  const { isFavorite, loading: fetchLoader } = useSelector((state) => state.fetchPageFavorite);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [contact, setContact] = useState("");
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem("rowsPerPage")) || 20);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [contacts, setContacts] = useState([]);
  const [crmActivity, setCrmActivity] = useState(
    localStorage.getItem(`crm_activity_${user?.id}`)
      ? localStorage.getItem(`crm_activity_${user?.id}`) == "true"
      : true
  );
  const [sortState, setSortState] = useState(() => {
    const storedSort = localStorage.getItem("crmTableSort");
    return storedSort
      ? JSON.parse(storedSort)
      : { prop: "created_at", order: "desc" };
  });

  useEffect(() => {
    const userIsSubscribed = user?.subscriptions?.length > 0;
    const modifiedContacts = contactsList?.results?.map((row, index) => ({
      ...row,
      isBlurred: !userIsSubscribed && index >= 10,
    }));
    setContacts(modifiedContacts || []);
    setMaxPage(Math.ceil(contactsList.count / rowsPerPage));
  }, [contactsList, user]);

  // Fetch Contacts from API
  useEffect(() => {
    dispatch(getCrmContacts(`page=${currentPage}&page_size=${rowsPerPage}&sort=${sortState.order === "asc" ? sortState.prop : "-" + sortState.prop}`));
  }, [dispatch])

  // Fetch Contacts from API
  useEffect(() => {
    dispatch(getCrmContacts(`page=${currentPage}&page_size=${rowsPerPage}&sort=${sortState.order === "asc" ? sortState.prop : "-" + sortState.prop}`));
  }, [lastDate])

  useEffect(() => {
    const pageRecord = "Contact Management";
    const type = "Contact Management";

    dispatch(createPageView(pageRecord, path, type));
  }, [dispatch, path]);

  useEffect(() => {
    dispatch(fetchPageFavorite("Contact Management"));
  }, [dispatch]);

  const onSortChange = useCallback(
    (nextSort) => {
      setSortState(nextSort);
      localStorage.setItem("crmTableSort", JSON.stringify(nextSort));
      dispatch(
        getCrmContacts(
          `page=${currentPage}&page_size=${rowsPerPage}&sort=${nextSort.order === "asc" ? nextSort.prop : `-${nextSort.prop}`
          }`
        )
      );
    },
    [rowsPerPage, currentPage]
  );

  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removePageFavorite({ page_name: "Contact Management" }));
    } else {
      dispatch(
        addPageFavorite({ page_name: "Contact Management", page_url: path })
      );
    }
  };

  // Handle Editing
  const handleEdit = (id, field, value) => {
    const updatedData = contacts.map((contact) =>
      contact.id === id ? { ...contact, [field]: value } : contact
    );
    setContacts(updatedData);
  };

  // Save Changes to Backend
  const saveChanges = (contact) => {
    dispatch(updateCrmContact(contact?.id, contact));
  };

  const handleOpenEmailModal = (e, row) => {
    setEmailModalShow(true);
    setContact(row);
  }

  const convertHtml = (html) => {
    return html
      ?.replaceAll("&nbsp;", " ")
      ?.replaceAll("<div><div>", "\n")
      ?.replaceAll("<div>", "")
      ?.replaceAll("</div><br>", "\n")
      ?.replaceAll("</div>", "\n")
      ?.replaceAll("<br>", "\n")
      ?.replaceAll("<br/>", "\n")
      ?.replaceAll("<br />", "\n")
      ?.replaceAll("&#39;", "'")
      ?.replaceAll("<p>", "")
      ?.replaceAll("</p>", "");
  };

  const handleSendEmail = async (appName, email_body) => {
    const id = contact?.contact_id || `${contact.id}`;
    const recipient = contact?.email;
    const recipientName = contact.name;
    const greeting = `Hi ${recipientName.split(" ")[0]},`;

    const subject = `${profile?.company_name} — we’re available if you need support`;

    const message = convertHtml(email_body);
    const body = `${greeting}\n\n${message}\n\n${profile?.proposal_point_contact_name
      }\n${profile?.proposal_point_contact_phone}\n${profile?.proposal_point_contact_email
      }\n\n${profile?.company_name && profile?.company_name + "\n"}${profile?.company_street && profile?.company_street + "\n"
      }${profile?.company_city ? profile?.company_city + ", " : ""}${profile?.company_state
      } ${profile?.company_zip}`;
    const simple_body = `${greeting}\n\n${message}\n\n`;
    // Mailto link for default mail client
    const defaultMailLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Gmail link
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipient}&su=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Outlook link
    const outlookLink = `https://outlook.office.com/owa/?path=/mail/action/compose&to=${recipient}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(simple_body)}`;

    // Yahoo Mail link
    const yahooLink = `https://compose.mail.yahoo.com/?to=${recipient}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(simple_body)}`;

    switch (appName) {
      case "gmail":
        window.open(gmailLink, "_blank");
        break;
      case "yahoo":
        window.open(yahooLink, "_blank");
        break;
      case "outlook":
        window.open(outlookLink, "_blank");
        break;
      case "default":
        window.open(defaultMailLink, "_blank");
        break;
      default:
        window.open(defaultMailLink, "_blank");
        break;
    }
    const today = new Date().toISOString();
    let saved = await dispatch(updateContactLastDateByName(today.slice(0, 16), '', id, email_body));
    saved && dispatch(getCrmContacts(`page=${currentPage}&page_size=${rowsPerPage}`));
  };

  const onRowsPerPageChange = useCallback((rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    localStorage.setItem("rowsPerPage", rowsPerPage.toString());
    setCurrentPage(1);
    dispatch(getCrmContacts(`page=${1}&page_size=${rowsPerPage}&sort=${sortState.order === "asc" ? sortState.prop : "-" + sortState.prop}`));
  }, []);

  const onPaginationChange = useCallback((nextPage) => {
    setCurrentPage(nextPage);
    dispatch(getCrmContacts(`page=${nextPage}&page_size=${rowsPerPage}&sort=${sortState.order === "asc" ? sortState.prop : "-" + sortState.prop}`));
  }, [rowsPerPage]);

  const toLocalISOString = (string_date, created_at = false) => {
    const date = new Date(string_date);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (minutes == 0 && hours > 0 && created_at) {
      date.setDate(date.getDate() + 1)
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${month}-${day}-${year}`;
  };

  const CONTACT_HEADERS = [
    {
      title: "Name",
      prop: "name",
      isSortable: user?.subscriptions?.length > 0,
      cell: (row) => !row?.isBlurred ? row.name : <div className="row-blurred">{row.name}</div>,
    },
    {
      title: "Organization",
      prop: "organization",
      isSortable: user?.subscriptions?.length > 0,
      cell: (row) =>
        editingRow === row?.id ? (
          <Form.Control
            type="text"
            value={row.organization || ""}
            onChange={(e) => handleEdit(row?.id, "organization", e.target.value)}
          />
        ) : !row?.isBlurred ? (
          row.organization
        ) : (
          <div className="row-blurred">{row.organization}</div>
        ),
    },
    {
      title: "Title",
      prop: "title",
      isSortable: user?.subscriptions?.length > 0,
      cell: (row) =>
        editingRow === row?.id ? (
          <Form.Control
            type="text"
            value={row.title || ""}
            onChange={(e) => handleEdit(row?.id, "title", e.target.value)}
          />
        ) : !row?.isBlurred ? (
          row.title
        ) : (
          <div className="row-blurred">{row.title}</div>
        ),
    },
    {
      title: "Email",
      prop: "email",
      isSortable: user?.subscriptions?.length > 0,
      cell: (row) =>
        editingRow === row?.id ? (
          <Form.Control
            type="email"
            value={row.email}
            onChange={(e) => handleEdit(row?.id, "email", e.target.value)}
          />
        ) : !row?.isBlurred ? (
          row.email
        ) : (
          <div className="row-blurred">{row.email}</div>
        ),
    },
    {
      title: "Phone",
      prop: "phone",
      isSortable: user?.subscriptions?.length > 0,
      cell: (row) =>
        editingRow === row?.id ? (
          <Form.Control
            type="text"
            value={row.phone}
            onChange={(e) => handleEdit(row?.id, "phone", e.target.value)}
          />
        ) : !row?.isBlurred ? (
          row.phone
        ) : (
          <div className="row-blurred">{row.phone}</div>
        ),
    },
    {
      title: "Last Contacted",
      prop: "last_contact_date",
      isSortable: user?.subscriptions?.length > 0,
      cell: (row) => {
        return (
          <>
            {row?.isBlurred ? <div className="row-blurred">{row.email}</div> : row.last_contact_date ? toLocalISOString(row.last_contact_date) : "-"}
          </>
        );
      }
    },
    {
      title: "Created Date",
      prop: "created_at",
      isSortable: user?.subscriptions?.length > 0,
      cell: (row) => {
        return (
          <>
            {row?.isBlurred ? <div className="row-blurred">{row.email}</div> : row.created_at ? toLocalISOString(row.created_at, true) : "-"}
          </>
        );
      }
    },
    crmActivity && {
      title: "Number of Activities",
      prop: "combined_activities",
      isSortable: user?.subscriptions?.length > 0,
      cell: (row) =>
        editingRow === row?.id ? (
          <Form.Control
            type="number"
            value={row.combined_activities || 0}
            onChange={(e) => handleEdit(row?.id, "combined_activities", e.target.value)}
          />
        ) : !row?.isBlurred ? (
          row.combined_activities
        ) : (
          <div className="row-blurred">{row.combined_activities}</div>
        ),
    },
    crmActivity && {
      title: "Responses",
      prop: "responses",
      isSortable: user?.subscriptions?.length > 0,
      cell: (row) =>
        editingRow === row?.id ? (
          <Form.Control
            type="number"
            value={row.responses || 0}
            onChange={(e) => handleEdit(row?.id, "responses", e.target.value)}
          />
        ) : !row?.isBlurred ? (
          row.responses
        ) : (
          <div className="row-blurred">{row.responses}</div>
        ),
    },
    crmActivity && {
      title: "Response Rate",
      prop: "response_rate",
      cell: (row) => {
        if (!row.combined_activities) return '0%';
        return row?.isBlurred ? <div className="row-blurred">0%</div> : `${parseInt((row.responses / row.combined_activities) * 100)}%`;
      }
    },
    {
      title: "Actions",
      prop: "actions",
      cell: (row) => (
        <div className="d-flex gap-2 align-items-center">
          {editingRow === row?.id ? (
            <>
              <button
                className="fs-5 btn btn-secondary ms-2"
                onClick={() => setEditingRow(null)}
              >
                <FontAwesomeIcon icon={faX} />
              </button>
              <button
                className="fs-5 btn btn-success"
                onClick={() => {
                  saveChanges(row);
                  setEditingRow(null);
                }}
              >
                <FontAwesomeIcon icon={faSave} />
              </button>
            </>
          ) : !row?.isBlurred ? (
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setEditingRow(row?.id)}>
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(deleteCrmContact(row?.id))}>
                  <FontAwesomeIcon icon={faTrash} className="me-2" /> Delete
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleOpenEmailModal(e, row)}>
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" /> Send Email
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle variant="light" id="dropdown-basic"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/pricing');
                }}>
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>
            </Dropdown>
          )}
        </div>
      ),
    },
  ].filter(Boolean);


  return (
    <Container fluid className="mt-5 container-crm">
      {loading ? (
        <div className="spinner-overlay">
          <div className="spinner-container">
            <Spinner />
          </div>
        </div>
      ) : user == null ? (
        <>
          <h3 className="pt-4 text-danger">
            Login to view the contacts!
          </h3>
        </>
      ) : error != null && error.messages != null ? (
        <>
          <div className="d-flex justify-content-center align-items-center">
            {error.messages.map((messageObj, index) => (
              <div key={index}>
                <p className="pt-4 text-center text-danger">
                  <b>{messageObj.message}</b>
                </p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-end">
            <Button variant="primary" className="mb-3" onClick={() => setShowModal(true)}>
              + Add New Contact
            </Button>
          </div>
          <DatatableWrapper
            body={contacts}
            headers={CONTACT_HEADERS}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [10, 20, 50, 100, 200, 500],
              }
            }}>
            <div className="d-flex flex-row align-items-center justify-content-between mb-3 flex-wrap">
              {/* <h2 className="fw-bolder">Contact Management</h2> */}
              <Row>
                <Col sm={11} lg={11} xl={11}>
                  <h1 className="heading-name pb-2">Contact Management</h1>
                </Col>
                {user && (
                  <Col sm={1}>
                    <FontAwesomeIcon
                      size={"2x"}
                      icon={isFavorite ? solidStar : regularStar}
                      style={{
                        color: isFavorite ? "gold" : "transparent",
                        stroke: isFavorite ? "" : "gold",
                        strokeWidth: isFavorite ? "0" : "20",
                        cursor: "pointer",
                      }}
                      onClick={handleFavoriteToggle}
                      className="fw-bolder"
                    />
                  </Col>
                )}
              </Row>
              <PaginationOptions
                controlledProps={{
                  rowsPerPage,
                  onRowsPerPageChange,
                }}
                style={{ width: "100%" }}
              ></PaginationOptions>
              <Pagination
                controlledProps={{
                  currentPage,
                  maxPage,
                  onPaginationChange,
                }}
              />
            </div>
            <div className="d-flex flex-row align-items-end justify-content-between mb-4">
              <h2 className="fs-3">Total Contacts {contactsList?.count}</h2>
              <Button
                onClick={() => {
                  setCrmActivity((prevShow) => !prevShow);
                  localStorage.setItem(`crm_activity_${user?.id}`, !crmActivity);
                }}
                style={{ width: 'max-content' }}
                variant="primary"
                className="px-4"
              >
                {crmActivity ? "Hide Insight" : "Show Insight"}
              </Button>
            </div>
            <Table className="crm-table">
              <TableHeader
                controlledProps={{
                  sortState,
                  onSortChange,
                }} />
              <TableBody />
            </Table>
          </DatatableWrapper>
        </>
      )}
      < AddContactModal showModal={showModal} setShowModal={setShowModal} />
      <EmailModal
        app="crm"
        company_name={profile?.company_name}
        closeOnOutsideClick={true}
        show={emailModalShow}
        onHide={() => {
          setEmailModalShow(false);
          setContact("");
        }}
        onChange={handleSendEmail}
      />
    </Container>
  );
};

export default ContactManagement;
