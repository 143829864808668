import { delete$, get$, post$, put$ } from "./default";
import { api } from "../constants/api";

export const saveDealAPI = (data) => {
  return post$(api.deal.createDeal, data);
}

export const getDealAPI = (dealId) => {
  return get$(api.deal.getDeal.replace(":dealId", dealId));
};

export const getDealsAPI = (query) => {
  return get$(api.deal.getDeals, query);
};

export const deleteDealAPI = (dealId) => {
  return delete$(api.deal.deleteDeal.replace(":dealId", dealId));
};

export const updateStatusAPI = (dealId, data) => {
  return post$(api.deal.updateStatus.replace(":dealId", dealId), data);
};

export const addDealAPI = (dealId) => {
  return post$(api.deal.addDeal.replace(":dealId", dealId));
};

export const addStripeAccountAPI = () => {
  return post$(api.deal.addStripeAccount);
}

export const stripePayoutAPI = () => {
  return post$(api.deal.stripePayout);
}
