import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserDetail } from "../../store/actions/users/users.actions";
import { addStripeAccount } from "../../store/actions/deals/deals.action";
import Spinner from "../../components/spinner/Spinner";

const OnboardingReturn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user: profileUser } = userDetails;
  const { loading: onboardLoading } = useSelector((state) => state.dealActions);

  const handleContinue = () => {
    navigate("/profile");
  };

  const handleRetry = () => {
    dispatch(addStripeAccount());
  };

  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch]);

  return (
    <>
      {loading || onboardLoading ? (
        <div className="container text-center mt-5 d-flex align-items-center-justify-content-center" style={{ height: "90vh" }}>
          <Spinner />
        </div>
      ) : profileUser?.stripe_account_id &&
        profileUser?.stripe_account?.details_submitted ? (
        <div
          className="container text-center mt-5 d-flex align-items-center justify-content-center flex-column"
          style={{ height: "90vh" }}
        >
          <h2 className="fs-1 fw-bolder text-success">Onboarding Successful</h2>
          <p className="fs-4">
            Your Stripe account has been successfully set up. You can now
            request payouts.
          </p>
          <Button
            variant="success"
            className="mx-4 px-5 fs-4"
            style={{ maxWidth: "fit-content" }}
            onClick={handleContinue}
          >
            Request Payout
          </Button>
        </div>
      ) : (
        <div
          className="container text-center mt-5 d-flex align-items-center justify-content-center flex-column"
          style={{ height: "90vh" }}
        >
          <h2 className="fs-1 fw-bolder text-danger">Onboarding Incomplete</h2>
          <p className="fs-4 fw-semibold">
            Please complete the onboarding process to receive payouts.
          </p>
          <Button
            variant="primary"
            className="mx-4 px-5 fs-4"
            style={{ maxWidth: "fit-content" }}
            onClick={handleRetry}
          >
            Retry Onboarding
          </Button>
        </div>
      )}
    </>
  );
};

export default OnboardingReturn;
