import React, { useEffect } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../spinner/Spinner";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { deletePaintProposal, downloadPaintProposal, getPaintProposals } from "../../store/actions/paint_proposal/paint_proposal.action";

const RecentPaintProposal = ({ setModalShow, showFormTab }) => {
  let params = useParams();
  let [searchParams] = useSearchParams();
  const { id: editId } = params;
  const proposalList = useSelector((state) => state.proposalList);
  const { user } = useSelector((state) => state.auth);
  const { loading, proposals } = proposalList;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      dispatch(getPaintProposals());
    }
  }, [user, dispatch]);

  const handleSetModal = (e) => {
    e.preventDefault();
    setModalShow(true);
  };
  const handleDownload = (e, id, proposal) => {
    e.preventDefault();

    dispatch(downloadPaintProposal(id, `${proposal.project_name}_${Number(proposal.bid_amount)}_${proposal?.proposal_template?.name.includes("Paint") ? "Paint Proposal" : "Drywall Proposal"}`));
  };
  const handleDelete = (e, id) => {
    e.preventDefault();
    let response = window.confirm("Are you Sure you want to delete Proposal?");
    if (response) {
      dispatch(deletePaintProposal(id, editId));
    }
  };

  const updateQueryParams = (newParams) => {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    newParams.forEach((key) => {
      searchParams.delete(key);
    });

    navigate(`${currentUrl.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const getPageNumber = (url) => {
    if (!url) return null;

    const urlObj = new URL(url);
    return urlObj.searchParams.get("page");
  }

  useEffect(() => {
    let proposalId = searchParams.get("download_id");
    let name = searchParams.get("name");
    if (proposalId) {
      // let proposal = proposalList.proposals?.results.find(
      //   (proposal) => proposal.id == proposalId
      // );
      if (proposalId) dispatch(downloadPaintProposal(proposalId, name));
      else navigate("/my_proposal?show_tab=recent");
      updateQueryParams(["download_id", "name"]);
    }
  }, [searchParams]);

  const recentProposal = () => {
    return proposals.results.map((proposal, index) => {
      return (
        <ListGroup.Item
          key={proposal.id}
          as="li"
          className="d-flex align-items-start  "
        >
          <div className="text-start w-100 ps-2">
            <div className="fw-bold d-flex justify-content-between align-items-center">
              <div>
                {index + 1}. {""}
                <Link
                  className="me-5 d-inline-block text-decoration-none"
                  to={`/paint_proposal/edit/${proposal.id}`}
                  onClick={() => showFormTab("home")}
                >
                  {proposal.project_name}_$
                  {Number(proposal.bid_amount)}_Proposal{" "}
                </Link>
              </div>
              <div className="d-flex flex-column">
                <Link
                  className="me-5 d-inline-block text-decoration-none"
                  to={`/paint_proposal/edit/${proposal.id}`}
                  onClick={() => showFormTab("home")}
                >
                  Edit
                </Link>
                <Button
                  style={{ color: "#fff" }}
                  className="d-inline-block btn-block btn-danger me-3 my-3"
                  onClick={(e) => handleDelete(e, proposal.id)}
                >
                  Delete
                </Button>

                <Button
                  className="d-inline-block btn-block "
                  onClick={(e) => handleDownload(e, proposal.id, proposal)}
                >
                  Download
                </Button>
              </div>
            </div>
            <div className="text-start">
              <span className="fw-bolder">create date:</span>{" "}
              {new Date(proposal.modified).toUTCString().slice(0, 16)}
            </div>
          </div>
        </ListGroup.Item>
      );
    });
  };
  return (
    <>
      {loading && !proposals?.next ? (
        <Spinner />
      ) : (
        <ListGroup as="ul">
          {user ? (
            <>
              {proposals?.results?.length ? (
                recentProposal()
              ) : (
                <h4>Nothing to show here, create a new proposal!</h4>
              )}
              {loading && proposals?.next ? (
                <Spinner />
              ) : proposals?.next && (
                <ListGroup.Item>
                  <Button
                    variant="primary"
                    onClick={() => dispatch(getPaintProposals(getPageNumber(proposals.next)))}
                  >
                    Load More {(proposals.count - proposals.results.length) > 20 ? 20 : proposals.count - proposals.results.length} proposals
                  </Button>
                </ListGroup.Item>
              )}
            </>
          ) : (
            <div>
              <Button variant="primary" type="submit" onClick={handleSetModal}>
                Login{" "}
              </Button>
              <h4>to see Recent Proposals</h4>
            </div>
          )}
        </ListGroup>
      )}
    </>
  );
};

export default RecentPaintProposal;
