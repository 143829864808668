import axios from "axios";
import { api } from "../../../utils/constants/api";
import {
  isEmpty,
  toastError,
  toastSuccess,
} from "../../../utils/helpers/helper";
import {
  addDealAPI,
  addStripeAccountAPI,
  deleteDealAPI,
  getDealAPI,
  getDealsAPI,
  saveDealAPI,
  stripePayoutAPI,
  updateStatusAPI,
} from "../../../utils/requests/deal";
import {
  ADD_DEAL_FAIL,
  ADD_DEAL_REQUEST,
  ADD_DEAL_SUCCESS,
  DEAL_CREATE_FAIL,
  DEAL_CREATE_REQUEST,
  DEAL_CREATE_SUCCESS,
  DEAL_DELETE_FAIL,
  DEAL_DELETE_REQUEST,
  DEAL_DELETE_SUCCESS,
  DEAL_FETCH_FAIL,
  DEAL_FETCH_REQUEST,
  DEAL_FETCH_SUCCESS,
  DEAL_LIST_FAIL,
  DEAL_LIST_REQUEST,
  DEAL_LIST_SUCCESS,
  DEAL_MARKETPLACE_SHARE_SUCCESS,
  DEAL_MARKETPLACE_UNSHARE_SUCCESS,
  DEAL_RESSET_VALUES,
  DEAL_SET_VALUES,
  DEAL_UPDATE_FAIL,
  DEAL_UPDATE_REQUEST,
  DEAL_UPDATE_SUCCESS,
  STRIPE_ACCOUNT_FAIL,
  STRIPE_ACCOUNT_REQUEST,
  STRIPE_ACCOUNT_SUCCESS,
} from "../../constants/dealConstants";
import { getUserDetail } from "../users/users.actions";

export const saveDeal =
  (formData, create_new = false) =>
  async (dispatch, getState) => {
    try {
      let { access } = getState();
      if (isEmpty(access)) {
        access = JSON.parse(localStorage.getItem("token"));
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      };

      dispatch({ type: DEAL_CREATE_REQUEST });

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/` + api.deal.createDeal,
        formData,
        config
      );
      if (data.error) {
        toastError(data.error);
      } else {
        toastSuccess("Deal saved successfully");
        dispatch({
          type: DEAL_CREATE_SUCCESS,
          payload: data,
        });
        if (create_new) dispatch({ type: DEAL_RESSET_VALUES });
        else document.location.href = "/myganarpro/favorites";
      }
    } catch (e) {
      let errors = e.response?.data?.errors;
      toastError(errors);
      dispatch({
        type: DEAL_CREATE_FAIL,
        payload: e,
      });
    }
  };

export const updateDeal =
  (formData, dealId, create_new = false) =>
  async (dispatch, getState) => {
    try {
      let { access } = getState();
      if (isEmpty(access)) {
        access = JSON.parse(localStorage.getItem("token"));
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      };

      dispatch({ type: DEAL_UPDATE_REQUEST });

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/` + api.deal.updateDeal.replace(':dealId', dealId),
        formData,
        config
      );
      if (data.errors) {
        toastError(data.errors);
      } else {
        toastSuccess("Deal Update successfully");
        dispatch({
          type: DEAL_UPDATE_SUCCESS,
          payload: data,
        });
        document.location.href = `/deal/${data.data.url_slug}`;
      }
    } catch (e) {
      let errors = e.response?.data?.errors;
      toastError(errors);
      dispatch({
        type: DEAL_UPDATE_FAIL,
        payload: e,
      });
    }
  };

export const getDeal = (dealId) => {
  return async (dispatch) => {
    dispatch({ type: DEAL_FETCH_REQUEST });
    return getDealAPI(dealId)
      .then((response) => {
        dispatch({ type: DEAL_FETCH_SUCCESS, payload: response });
        dispatch({ type: DEAL_SET_VALUES, ...response });
      })
      .catch((error) => {
        dispatch({
          type: DEAL_FETCH_FAIL,
          error: error,
        });
        toastError("Unable to fetch deal details");
      });
  };
};

export const getDeals = (query) => {
  return async (dispatch) => {
    dispatch({ type: DEAL_LIST_REQUEST });
    return getDealsAPI(query)
      .then((response) => {
        dispatch({ type: DEAL_LIST_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({
          type: DEAL_LIST_FAIL,
          error: error,
        });
        toastError("Unable to fetch deals");
      });
  };
};

export const deleteDeal = (dealId) => {
  return async (dispatch) => {
    dispatch({ type: DEAL_DELETE_REQUEST });
    return deleteDealAPI(dealId)
      .then((response) => {
        dispatch({ type: DEAL_DELETE_SUCCESS });
        toastSuccess("Deal deleted successfully");
        document.location.href = "/myganarpro/favorites";
      })
      .catch((error) => {
        dispatch({
          type: DEAL_DELETE_FAIL,
          error: error,
        });
        toastError("Unable to fetch deal details");
      });
  };
};

export const addDeal = (dealId) => {
  return async (dispatch) => {
    dispatch({ type: ADD_DEAL_REQUEST });
    return addDealAPI(dealId)
      .then((response) => {
        dispatch({ type: ADD_DEAL_SUCCESS });
        toastSuccess("Deal added successfully");
        if (response?.url_slug)
          document.location.href = `/deal/${response.url_slug}`;
        else
          document.location.href = "/myganarpro/favorites";
      })
      .catch((error) => {
        dispatch({
          type: ADD_DEAL_FAIL,
          error: error,
        });
        toastError("Unable to Add deal");
      });
  };
};

export const shareStatusUpate = (dealId, status) => {
  return async (dispatch) => {
    return updateStatusAPI(dealId, status)
      .then((response) => {
        if (status) {
          toastSuccess(response);
          dispatch({ type: DEAL_MARKETPLACE_SHARE_SUCCESS, data: { status } });
        } else {
          toastSuccess("Deal removed from marketplace");
          dispatch({
            type: DEAL_MARKETPLACE_UNSHARE_SUCCESS,
            data: { status },
          });
        }
      })
      .catch((error) => {
        error?.data?.message ?
          toastError(error?.data?.message) :
          toastError("Unable to update deal status");
      });
  };
};

export const addStripeAccount = () => {
  return async (dispatch) => {
    dispatch({ type: STRIPE_ACCOUNT_REQUEST })
    return addStripeAccountAPI()
      .then((response) => {
        if (response.status == 'success') {
          window.location.href = response.onboarding_url
        } else {
          toastError("Unable to link stripe account");
          dispatch({ type: STRIPE_ACCOUNT_FAIL });
        }
      })
      .catch((error) => {
        toastError("Unable to link stripe account");
        dispatch({ type: STRIPE_ACCOUNT_FAIL });
      });
  };
};

export const stripePayout = () => {
  return async (dispatch) => {
    dispatch({ type: STRIPE_ACCOUNT_REQUEST })
    return stripePayoutAPI()
      .then((response) => {
        if (response.status == 'success') {
          toastSuccess("Payout created successfully\nTransaction ID: " + response?.transfer_id);
          dispatch({ type: STRIPE_ACCOUNT_SUCCESS });
          dispatch(getUserDetail());
        } else if (response?.insufficient_funds) {
          toastError(response?.message);
          dispatch({ type: STRIPE_ACCOUNT_FAIL });
        } else {
          toastError("Unable to create payout");
          dispatch({ type: STRIPE_ACCOUNT_FAIL });
        }
      })
      .catch((error) => {
        if (error.data?.insufficient_funds) toastError(error.data?.message)
        else toastError("Unable to create payout");
        dispatch({ type: STRIPE_ACCOUNT_FAIL });
      });
  };
};
