import React, { use, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import styled from "styled-components";
import LoginModal from "../../components/LoginModal";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useIsMobile from "../../utils/helpers/use-is-mobile";
import { useNavigate } from "react-router";
import {
  addPageFavorite,
  createPageView,
  fetchPageFavorite,
  removePageFavorite,
} from "../../store/actions/users/users.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { StyledInput } from "../../components/StyledInputWithLoader";
import { StyledForm } from "../../components/calculator/CalculatorForm.styled";
import StateDropdown from "../../components/StateDropdown";
import { getProjectTypePricingList } from "../../store/actions/mortgage_calculator/mortgage_calculator.actions";
import { AiOutlineDelete } from "react-icons/ai";
import {
  DEAL_RESSET_VALUES,
  DEAL_SET_VALUES,
} from "../../store/constants/dealConstants";
import {
  getDeal,
  saveDeal,
  updateDeal,
} from "../../store/actions/deals/deals.action";
import { toast } from "react-toastify";
import Spinner from "../../components/spinner/Spinner";
import { State } from "country-state-city";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";

const StyledSectionTwo = styled.div`
  background-color: rgb(246 248 250);
`;
const StyledRow = styled(Row)`
  padding-top: 4rem;
`;

const StyledPreviewText = styled.h3`
  color: #000;
  font-weight: 700;
`;

const StyledTabs = styled(Tabs)`
  background-color: #fff;
  h2 {
    color: #000;
  }
  button {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .nav-item button#noanim-tab-example-tab-new {
    color: #fff;
    background-color: #276fb4;
  }
`;

const StyledFloatingLabel = styled(FloatingLabel)`
  label {
    max-height: fit-content;
  }
`;

const Deal = () => {
  const { search } = window.location;
  const path = global.window.location.pathname.slice(1);
  let params = useParams();
  const { id } = params;
  let [searchParams] = useSearchParams();
  let state = State.getStatesOfCountry("US");

  const isMobile = useIsMobile();
  const [modalShow, setModalShow] = useState(false);
  const [planDrawings, setPlanDrawings] = useState("");
  const [contacts, setContacts] = useState([
    { id: 1, first_name: "", last_name: "", email: "", phone: "" },
  ]);

  const { user } = useSelector((state) => state.auth);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { user: profile, loading: profileLoading } = useSelector(
    (state) => state.userDetails
  );
  const { projectTypePricing } = useSelector(
    (state) => state.projectTypePricingList
  );
  const values = useSelector((state) => state.dealValues);
  const { loading, error, fetch_loading } = useSelector(
    (state) => state.dealActions
  );
  const { isFavorite, loading: fetchLoader } = useSelector(
    (state) => state.fetchPageFavorite
  );
  const { favorites } = useSelector((state) => state.ListFavoritePages);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const today = new Date();
  const minDate = new Date(today);
  minDate.setDate(today.getDate() - 13);
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 14);

  const minDateString = minDate.toISOString().split("T")[0];
  const maxDateString = maxDate.toISOString().split("T")[0];

  const handleSetModal = (e) => {
    e.preventDefault();
    setModalShow(true);
  };

  const addContact = () => {
    const newContact = {
      id: contacts.length + 1,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    };
    setContacts([...contacts, newContact]);
    dispatch({
      type: DEAL_SET_VALUES,
      payload: { deal_contacts: [...contacts, newContact] },
    });
  };

  const deleteContact = (id) => {
    const updatedContacts = contacts.filter((contact) => contact.id !== id);
    setContacts(updatedContacts);
    dispatch({
      type: DEAL_SET_VALUES,
      payload: { deal_contacts: [...updatedContacts] },
    });
  };

  const handleInputChange = (id, field, value) => {
    const updatedContacts = contacts.map((contact) =>
      contact.id === id ? { ...contact, [field]: value } : contact
    );
    setContacts(updatedContacts);
    dispatch({
      type: DEAL_SET_VALUES,
      payload: { deal_contacts: [...updatedContacts] },
    });
  };

  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removePageFavorite({ page_name: "New Deal" }));
    } else {
      dispatch(addPageFavorite({ page_name: "New Deal", page_url: path }));
    }
  };

  const handleCheckBox = (evt) => {
    dispatch({
      type: DEAL_SET_VALUES,
      payload: { [evt.target.name]: evt.target.checked },
    });
  };

  const handleChange = (evt, regions) => {
    const { name, value: newValue } = evt.target;
    if (name === "state") {
      let details = regions?.find((item) => item.name === newValue);
      dispatch({
        type: DEAL_SET_VALUES,
        payload: { [name]: newValue, state_short: details.slug },
      });
    } else dispatch({ type: DEAL_SET_VALUES, payload: { [name]: newValue } });
  };

  const handleSave = (evt) => {
    if (!validateFrom()) return;
    let formData = new FormData();
    for (const key in values) {
      if (values[key] !== undefined && key !== "deal_contacts") {
        formData.append(
          key,
          values[key] == "null" || values[key] == null ? "" : values[key]
        );
      }
    }
    if (values?.plan_drawings_file)
      formData.append("plan_drawings", values["plan_drawings_file"]);

    const contactsWithoutId = contacts.map(({ id, ...rest }) => rest);
    formData.append("contacts", JSON.stringify(contactsWithoutId));
    if (values.id) {
      dispatch(updateDeal(formData, values.id, false));
    } else {
      dispatch(saveDeal(formData, false));
    }
  };

  const handleSaveNext = (evt) => {
    if (!validateFrom()) return;
    let formData = new FormData();
    for (const key in values) {
      if (values[key] !== undefined) {
        formData.append(key, values[key]);
      }
    }
    if (values?.plan_drawings_file)
      formData.append("plan_drawings", values["plan_drawings_file"]);

    const contactsWithoutId = contacts.map(({ id, ...rest }) => rest);
    formData.append("contacts", JSON.stringify(contactsWithoutId));
    if (values.id) {
      dispatch(updateDeal(formData, values.id, true));
    } else {
      dispatch(saveDeal(formData, true));
    }
  };

  const handleCancel = () => {
    navigate("/create_deal");
    dispatch({
      type: DEAL_RESSET_VALUES,
    });
    const newContact = {
      id: 1,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    };
    setContacts([newContact]);
    dispatch({
      type: DEAL_SET_VALUES,
      payload: { deal_contacts: newContact },
    });
  };

  const validateFrom = () => {
    if (!values.name) {
      toast.error("Deal name is required");
      return false;
    }
    if (!values.account_name) {
      toast.error("Organization name is required");
      return false;
    }
    if (!values.city) {
      toast.error("City is required");
      return false;
    }
    if (!values.state) {
      toast.error("State is required");
      return false;
    }
    return true;
  };

  const handleImageUpload = (e) => {
    const file_current = e.target.files[0];
    if (file_current?.size > 10240000) {
      toast.error("File size cannot exceed more than 10MB");
    } else if (file_current) {
      const reader = new FileReader();
      reader.onload = (e) => {
        dispatch({
          type: DEAL_SET_VALUES,
          payload: {
            plan_drawings: e.target.value,
            plan_drawings_file: file_current,
          },
        });
      };
      if (file_current) {
        reader.readAsDataURL(file_current);
      }
    }
  };

  useEffect(() => {
    if (projectTypePricing.length === 0) {
      dispatch(getProjectTypePricingList());
    }
  }, [dispatch, projectTypePricing.length]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    dispatch(fetchPageFavorite("New Deal"));
    if (id) dispatch(getDeal(id));

    dispatch({
      type: DEAL_RESSET_VALUES,
    });
    const newContact = {
      id: 1,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    };
    setContacts([newContact]);
  }, [dispatch]);

  useEffect(() => {
    if (values?.deal_contacts?.length) {
      const dealContacts = values?.deal_contacts.map((contact, index) => {
        return {
          id: index + 1,
          first_name: contact.first_name,
          last_name: contact.last_name,
          email: contact.email,
          phone: contact.phone,
        };
      });
      setContacts([...dealContacts]);
    } else {
      const newContact = {
        id: 1,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
      };
      setContacts([newContact]);
    }
  }, [values?.deal_contacts]);

  useEffect(() => {
    const pageRecord = "Create Deal";
    const type = "Create Deal";

    dispatch(createPageView(pageRecord, path, type));
  }, [dispatch, path]);

  return (
    <div className="bg-white">
      <Container className="mb-5">
        {fetch_loading ? (
          <div className="m-auto large-screen-pag full-loader">
            <Spinner />
          </div>
        ) : (
          <StyledForm>
            {user && (
              <Row className="justify-content-end">
                <Col className="d-flex justify-content-end align-items-center mt-1">
                  <h1>{id ? "Edit" : "New"} Deal</h1>
                </Col>
                <Col className="d-flex justify-content-start">
                  <FontAwesomeIcon
                    size={"2x"}
                    icon={isFavorite ? solidStar : regularStar}
                    style={{
                      color: isFavorite ? "gold" : "transparent",
                      stroke: isFavorite ? "" : "gold",
                      strokeWidth: isFavorite ? "0" : "20",
                      cursor: "pointer",
                    }}
                    onClick={handleFavoriteToggle}
                    className="fw-bolder align-self-center my-4"
                  />
                </Col>
              </Row>
            )}
            <hr />
            <h3 className="text-start my-4">Deals Information</h3>
            <Row className="pt-3 text-start">
              <Col xs={12} md={5}>
                <Form.Group controlId="name">
                  <FloatingLabel
                    controlId="name"
                    label="Deal Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleChange}
                      value={values?.name}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={12} md={2}></Col>
              <Col xs={12} md={5}>
                <Form.Group controlId="account_name">
                  <FloatingLabel
                    controlId="account_name"
                    label="Organization Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="account_name"
                      onChange={handleChange}
                      value={values?.account_name}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row className="text-start mt-3 align-items-stretch">
              <Col>
                <Form.Group controlId="description">
                  <StyledFloatingLabel
                    controlId="description"
                    label="Description"
                  >
                    <Form.Control
                      as="textarea"
                      name="description"
                      placeholder="Enter description"
                      onChange={handleChange}
                      value={values?.description}
                      style={{ minHeight: "100px" }}
                      rows={5}
                    />
                  </StyledFloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <div
              className="text-start px-2 fs-4 mt-4"
              style={{ backgroundColor: "lightgray" }}
            >
              Location
            </div>
            <Row className="pt-5 mt-1">
              <Col xs={12} md={5}>
                <Form.Group controlId="address">
                  <FloatingLabel
                    controlId="address"
                    label="Street"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="address"
                      onChange={handleChange}
                      value={values?.address}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={12} md={2}></Col>
              <Col xs={12} md={5}>
                <Form.Group controlId="city">
                  <FloatingLabel controlId="city" label="City" className="mb-3">
                    <Form.Control
                      type="text"
                      name="city"
                      onChange={handleChange}
                      value={values?.city}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col xs={12} md={5}>
                <StateDropdown
                  hideLabel
                  floatLabel
                  name="state"
                  handleChange={handleChange}
                  selected={values?.state}
                />
              </Col>
              <Col xs={12} md={2}></Col>
              <Col xs={12} md={5}>
                <Form.Group controlId="zip_code">
                  <FloatingLabel
                    controlId="zip_code"
                    label="Zip Code"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      name="zip_code"
                      onChange={handleChange}
                      value={values?.zip_code}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <div
              className="text-start px-2 fs-4 mt-4"
              style={{ backgroundColor: "lightgray" }}
            >
              Project Details
            </div>
            <Row className="pt-5 text-start">
              <Col xs={12} md={5}>
                <Form.Group controlId="sf_size">
                  <FloatingLabel
                    controlId="sf_size"
                    label="Square Foot Size"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      name="sf_size"
                      onChange={handleChange}
                      value={values?.sf_size}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={12} md={2}></Col>
              <Col xs={12} md={5}>
                <Form.Group controlId="units">
                  <FloatingLabel
                    controlId="units"
                    label="Number of living units"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      name="units"
                      onChange={handleChange}
                      value={values?.units}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row className="pt-3 text-start">
              <Col xs={12} md={5}>
                <Form.Group controlId="plan_drawings">
                  <FloatingLabel
                    controlId="plan_drawings"
                    label="Plans/drawings"
                    className="mb-3"
                  >
                    <Form.Control
                      type="file"
                      name="plan_drawings"
                      onChange={handleImageUpload}
                      disabled={values?.plan_drawings_url}
                      value={
                        values?.plan_drawings === "null" ||
                        typeof values?.plan_drawings == "string"
                          ? ""
                          : values?.plan_drawings
                      }
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={12} md={2} className="text-center">
                OR
              </Col>
              <Col xs={12} md={5}>
                <Form.Group controlId="plan_drawings_url">
                  <FloatingLabel
                    controlId="plan_drawings_url"
                    label="Plans/drawings URL"
                    className="mb-3"
                  >
                    <Form.Control
                      type="url"
                      name="plan_drawings_url"
                      onChange={handleChange}
                      value={values?.plan_drawings_url || ""}
                      disabled={values?.plan_drawings_file}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row className="pt-3 text-start">
              <Col xs={12} md={5}>
                <Form.Group controlId="project_type">
                  <FloatingLabel
                    controlId="project_type"
                    label="Project Building Type"
                    className="mb-3"
                  >
                    <Form.Select
                      name="project_type"
                      onChange={handleChange}
                      value={values?.project_type || "Choose..."}
                    >
                      <option>Choose...</option>

                      {projectTypePricing
                        .filter((project) => {
                          const excludedTypes = [
                            "any type general cleaning",
                            "carpet cleaning",
                            "flooring service",
                            "trailer",
                            "pressure wash",
                            "window clean",
                          ];
                          return !excludedTypes.includes(
                            project.project_type.toLowerCase()
                          );
                        })
                        .map((item) => {
                          return (
                            <>
                              <option
                                key={item.project_type}
                                value={item.project_type}
                              >
                                {item.project_type}
                              </option>

                              {item.project_type.toLowerCase() ===
                                "industrial" && (
                                <>
                                  <option
                                    key="Distribution Center"
                                    value="Distribution Center"
                                  >
                                    Distribution Center
                                  </option>
                                  <option
                                    key="Storage Facility"
                                    value="Storage Facility"
                                  >
                                    Storage Facility
                                  </option>
                                  <option key="Warehouse" value="Warehouse">
                                    Warehouse
                                  </option>
                                </>
                              )}
                            </>
                          );
                        })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={12} md={2}></Col>
              <Col xs={12} md={5}>
                <Row className="mt-3">
                  <Col>
                    <Form.Group
                      as={Col}
                      className="mb-3 checkbox d-flex"
                      controlId="davisCheck"
                    >
                      <Form.Check
                        type="checkbox"
                        name="davis_bacon_prevailing_wage_detail"
                        label="Prevailing Wage (Davis-Bacon)"
                        checked={values?.davis_bacon_prevailing_wage_detail}
                        onChange={handleCheckBox}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      as={Col}
                      className="mb-3 checkbox d-flex"
                      controlId="unionCheck"
                    >
                      <Form.Check
                        type="checkbox"
                        name="laborer_union"
                        label="Union Signatory Status"
                        checked={values?.laborer_union}
                        onChange={handleCheckBox}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div
              className="text-start px-2 fs-4 mt-4"
              style={{ backgroundColor: "lightgray" }}
            >
              Important Dates
            </div>
            <Row className="pt-3 mt-4 text-start">
              <Col xs={12} md={5}>
                <Form.Group controlId="bid_due_date">
                  <FloatingLabel
                    controlId="bid_due_date"
                    label="Preconstruction bid due date"
                    className="mb-3"
                  >
                    <Form.Control
                      type="date"
                      name="bid_due_date"
                      onChange={handleChange}
                      value={values?.bid_due_date}
                    />
                    {values?.bid_due_date && (
                      <button
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: DEAL_SET_VALUES,
                            payload: { bid_due_date: "" },
                          })
                        }
                        style={{
                          position: "absolute",
                          right: "30px",
                          top: "60%",
                          transform: "translateY(-50%)",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "20px",
                          color: "red",
                        }}
                      >
                        ×
                      </button>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row className="pt-3 text-start">
              <Col xs={12} md={5}>
                <Form.Group controlId="est_break_ground_date">
                  <FloatingLabel
                    controlId="est_break_ground_date"
                    label="Break ground date"
                    className="mb-3"
                  >
                    <Form.Control
                      type="date"
                      name="est_break_ground_date"
                      onChange={handleChange}
                      value={values?.est_break_ground_date}
                    />
                    {values?.est_break_ground_date && (
                      <button
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: DEAL_SET_VALUES,
                            payload: { est_break_ground_date: "" },
                          })
                        }
                        style={{
                          position: "absolute",
                          right: "30px",
                          top: "60%",
                          transform: "translateY(-50%)",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "20px",
                          color: "red",
                        }}
                      >
                        ×
                      </button>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={12} md={2}></Col>
              <Col xs={12} md={5}>
                <Form.Group controlId="project_completion">
                  <FloatingLabel
                    controlId="project_completion"
                    label="Completion date"
                    className="mb-3"
                  >
                    <Form.Control
                      type="date"
                      name="project_completion"
                      onChange={handleChange}
                      value={values?.project_completion}
                    />
                    {values?.project_completion && (
                      <button
                        type="button"
                        onClick={() =>
                          dispatch({
                            type: DEAL_SET_VALUES,
                            payload: { project_completion: "" },
                          })
                        }
                        style={{
                          position: "absolute",
                          right: "30px",
                          top: "60%",
                          transform: "translateY(-50%)",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "20px",
                          color: "red",
                        }}
                      >
                        ×
                      </button>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <hr className="mt-5" />
            <h3 className="text-start">Contact Roles</h3>
            {contacts.map((contact) => (
              <Row className="pt-4 align-items-center" key={contact.id}>
                <Col xs={6} md={2}>
                  <Form.Group controlId={`contact_first_name${contact.id}`}>
                    <FloatingLabel
                      controlId={`contact_first_name${contact.id}`}
                      label="First Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name={`contact_first_name${contact.id}`}
                        value={contact.first_name}
                        onChange={(e) =>
                          handleInputChange(
                            contact.id,
                            "first_name",
                            e.target.value
                          )
                        }
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={6} md={2}>
                  <Form.Group controlId={`contact_last_name${contact.id}`}>
                    <FloatingLabel
                      controlId={`contact_last_name${contact.id}`}
                      label="Last Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name={`contact_last_name${contact.id}`}
                        value={contact.last_name}
                        onChange={(e) =>
                          handleInputChange(
                            contact.id,
                            "last_name",
                            e.target.value
                          )
                        }
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={6} md={3}>
                  <Form.Group controlId={`contact_email${contact.id}`}>
                    <FloatingLabel
                      controlId={`contact_email${contact.id}`}
                      label="Email"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name={`contact_email${contact.id}`}
                        value={contact.email}
                        onChange={(e) =>
                          handleInputChange(contact.id, "email", e.target.value)
                        }
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={6} md={2}>
                  <Form.Group controlId={`contact_phone${contact.id}`}>
                    <FloatingLabel
                      controlId={`contact_phone${contact.id}`}
                      label="Phone"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name={`contact_phone${contact.id}`}
                        value={contact.phone}
                        onChange={(e) =>
                          handleInputChange(contact.id, "phone", e.target.value)
                        }
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={6} md={2}>
                  <Form.Group controlId={`contact_title${contact.id}`}>
                    <FloatingLabel
                      controlId={`contact_title${contact.id}`}
                      label="Title"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name={`contact_title${contact.id}`}
                        value={contact.title}
                        onChange={(e) =>
                          handleInputChange(contact.id, "title", e.target.value)
                        }
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={6} md={1} className="text-center">
                  <Button
                    variant="outline-danger"
                    onClick={() => deleteContact(contact.id)}
                    className="d-flex align-items-center fs-4 justify-content-center mb-3"
                  >
                    <AiOutlineDelete />
                  </Button>
                </Col>
              </Row>
            ))}

            <Row className="pt-4">
              <Col>
                {contacts.length < 4 && ( // Hide button if maximum rows (4) are reached
                  <Button variant="primary" onClick={addContact}>
                    Add Contact
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <div className="col d-flex justify-content-end mt-5 gap-2 justify-content-center">
                <Button variant="outline-primary fs-4" onClick={handleCancel}>
                  Cancel
                </Button>
                {!id && (
                  <Button
                    variant="outline-primary fs-4"
                    onClick={handleSaveNext}
                    disabled={loading}
                  >
                    {!loading ? (
                      <h4>Save & Next</h4>
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="ms-2">Loading...</span>
                      </>
                    )}
                  </Button>
                )}
                <Button
                  variant="primary fs-4"
                  onClick={handleSave}
                  disabled={loading}
                >
                  {!loading ? (
                    <h4>{id ? "Update" : "Save"}</h4>
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="ms-2">Loading...</span>
                    </>
                  )}
                </Button>
              </div>
            </Row>
          </StyledForm>
        )}
      </Container>
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default Deal;
