import React from "react";
import { Button } from "react-bootstrap";
import { addStripeAccount } from "../../store/actions/deals/deals.action";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Spinner from "../../components/spinner/Spinner";

const Reauth = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.dealActions);

  const handleRetry = () => {
    dispatch(addStripeAccount()); // Redirect to the payout page
  };

  return (
    <>
      {loading ? (
        <div className="container text-center mt-5" style={{ height: "90vh" }}>
          <Spinner />
        </div>
      ) : (
        <div className="container text-center mt-5 d-flex align-items-center justify-content-center flex-column" style={{ height: "90vh" }}>
          <h2 className="fs-1 fw-bolder">Onboarding Incomplete</h2>
          <p className="fs-4">Please complete the onboarding process to receive payouts.</p>
          <Button variant="primary" className="mx-4 px-5 fs-4" style={{ maxWidth: 'fit-content' }} onClick={handleRetry}>
            Retry Onboarding
          </Button>
        </div>
      )}
    </>
  );
};

export default Reauth;
