import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import blackCircleIcon from "../../assets/img/blackCircle.jpg";
import blueCircleIcon from "../../assets/img/blueCircle.jpg";
import greenCircleIcon from "../../assets/img/greenCircle.jpg";
import orangeCircle from "../../assets/img/orangeCircle.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPageFavorites,
} from "../../store/actions/users/users.actions";

let cardData = [
  {
    id: "cleaning",
    title: "Ganarpro All Pro Cleaning",
    imgSrc: blackCircleIcon
  },
  {
    id: "business",
    title: "Ganarpro Business",
    imgSrc: blueCircleIcon
  },
  {
    id: "basic_cleaning",
    title: "Ganarpro Basic cleaning",
    imgSrc: greenCircleIcon
  },
  {
    id: "paint",
    title: "Ganarpro Painting",
    imgSrc: orangeCircle
  }
  // Add more cards as needed
];

const CloudApps = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const changeAppView = (view) => {
    localStorage.setItem(`ganarpro_app${user?.id}`, view);
    document.location.href = "/";
  };

  useEffect(() => {
    dispatch(fetchPageFavorites());
  }, [dispatch]);

  const handleClick = (e, card) => {
    if (card.id !== localStorage.getItem(`ganarpro_app${user?.id}`))
      changeAppView(card.id)
  }

  return (
    <Container className="mt-5 pt-5">
      <Row className="my-3">
        <h2 className="text-start fw-bolder">Ganarpro services</h2>
      </Row>
      <Row className="justify-content-center">
        {cardData.length > 0 &&
          cardData.map((card, index) => {
            return (
              <Col md={3} className="gy-3 my-md-5 my-xs-5 col-7">
                <Link
                  disabled={card.id === localStorage.getItem(`ganarpro_app${user?.id}`)}
                  className="text-decoration-none text-dark"
                  onClick={(e) => handleClick(e, card)}
                >
                  <Card className={`h-100 ${card.id === localStorage.getItem(`ganarpro_app${user?.id}`) && 'bg bg-light'}`}>
                    <Card.Img
                      variant="top"
                      style={{ mixBlendMode: "multiply", opacity: card.id === localStorage.getItem(`ganarpro_app${user?.id}`) ? "0.5" : "1"}}
                      src={card.imgSrc}
                    />
                    <Card.Body className={`d-flex flex-column ${card.id === localStorage.getItem(`ganarpro_app${user?.id}`) ? 'text-muted' : ''}`}>
                      <Card.Title className="fw-bolder">
                        {card.title}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};

export default CloudApps;
