export const DEAL_FETCH_REQUEST = "DEAL_FETCH_REQUEST";
export const DEAL_FETCH_SUCCESS = "DEAL_FETCH_SUCCESS";
export const DEAL_FETCH_FAIL = "DEAL_FETCH_FAIL";

export const DEAL_LIST_REQUEST = "DEAL_LIST_REQUEST";
export const DEAL_LIST_SUCCESS = "DEAL_LIST_SUCCESS";
export const DEAL_LIST_FAIL = "DEAL_LIST_FAIL";

export const DEAL_CREATE_REQUEST = "DEAL_CREATE_REQUEST";
export const DEAL_CREATE_SUCCESS = "DEAL_CREATE_SUCCESS";
export const DEAL_CREATE_FAIL = "DEAL_CREATE_FAIL";

export const DEAL_UPDATE_REQUEST = "DEAL_UPDATE_REQUEST";
export const DEAL_UPDATE_SUCCESS = "DEAL_UPDATE_SUCCESS";
export const DEAL_UPDATE_FAIL = "DEAL_UPDATE_FAIL";

export const DEAL_DELETE_REQUEST = "DEAL_DELETE_REQUEST";
export const DEAL_DELETE_SUCCESS = "DEAL_DELETE_SUCCESS";
export const DEAL_DELETE_FAIL = "DEAL_DELETE_FAIL";

export const DEAL_SET_VALUES = "DEAL_SET_VALUES";
export const DEAL_RESSET_VALUES = "DEAL_RESSET_VALUES";

export const DEAL_MARKETPLACE_SHARE_SUCCESS = "DEAL_MARKETPLACE_SHARE_SUCCESS";
export const DEAL_MARKETPLACE_UNSHARE_SUCCESS = "DEAL_MARKETPLACE_UNSHARE_SUCCESS";

export const ADD_DEAL_REQUEST = "ADD_DEAL_REQUEST";
export const ADD_DEAL_SUCCESS = "ADD_DEAL_SUCCESS";
export const ADD_DEAL_FAIL = "ADD_DEAL_FAIL";

export const STRIPE_ACCOUNT_REQUEST = "STRIPE_ACCOUNT_REQUEST";
export const STRIPE_ACCOUNT_SUCCESS = "STRIPE_ACCOUNT_SUCCESS";
export const STRIPE_ACCOUNT_FAIL = "STRIPE_ACCOUNT_FAIL";
