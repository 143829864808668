import React, { useState, useEffect } from "react";
import { Col, FloatingLabel, Form } from "react-bootstrap";
import AsyncSelect from "react-select/async"; // Assuming you are using react-select for async options
import { fetchRegions } from "../../store/actions/gc_qualify/gcQualify.actions"; // Importing the fetchRegions function

const StateDropdown = ({ handleChange, name, selected, hideLabel, floatLabel }) => {
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    const loadRegions = async () => {
      try {
        const data = await fetchRegions(); // Assuming fetchRegions fetches the data in the expected format
        setRegions(data); // Store the fetched regions in the state
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    };
    loadRegions();
  }, []);

  return (
    <Form.Group as={Col} controlId="formGridState">
      {!hideLabel && <Form.Label>State</Form.Label>}
      {floatLabel ? (
        <FloatingLabel controlId="floatingSelect" label="State">
          <Form.Select onChange={(e) => handleChange(e, regions)} name={name} value={selected} style={{ paddingBottom: "0.3rem" }}>
            <option value="">Choose</option>
            {regions.map((item) => (
              <option key={item.slug} value={item.name}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
      ) : (
        <Form.Select onChange={handleChange} name={name} value={selected}>
          <option value="">Choose</option>
          {regions.map((item) => (
            <option key={item.slug} value={item.name}>
              {item.name}
            </option>
          ))}
        </Form.Select>
      )}
    </Form.Group>
  );
};

export default StateDropdown;
