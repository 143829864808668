import React, { useState, useEffect, useCallback } from "react";
import { Table, Modal, Button, Form, Row, Col } from "react-bootstrap";
import { DatatableWrapper, Pagination, PaginationOptions, TableBody, TableHeader } from "react-bs-datatable";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { saveCrmContacts } from "../../store/actions/crm/crm.action";

const AddContactModal = ({ saveContact, showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.crmActions);
  const [checkCreateLoading, setCheckCreateLoading] = useState(false);
  const [errors, setErrors] = useState({ firstName: false, email: false });
  const [newContact, setNewContact] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    title: "",
    email: "",
    phone: ""
  });

  const validateForm = () => {
    const newErrors = {
      firstName: !newContact.firstName.trim(),
      email: !newContact.email.trim(),
    };
    setErrors(newErrors);
    return !newErrors.firstName && !newErrors.email;
  };

  // Handle New Contact Input
  const handleNewContactChange = (e) => {
    setNewContact({ ...newContact, [e.target.name]: e.target.value });
  };

  // Add New Contact
  const handleAddContact = () => {
    if (!validateForm()) {
      return;
    } else {
      // saveContact(newContact)
      setCheckCreateLoading(true);
      dispatch(saveCrmContacts(newContact))
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (checkCreateLoading && !loading) {
      setCheckCreateLoading(false);
      setNewContact({
        firstName: "",
        lastName: "",
        organization: "",
        title: "",
        email: "",
        phone: ""
      });
    }
  }, [loading])

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={newContact.firstName}
                onChange={handleNewContactChange}
                isInvalid={errors.firstName}
                required
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={newContact.lastName}
                onChange={handleNewContactChange}
              />
            </Form.Group>
          </Row>
          <Form.Group className="mt-3">
            <Form.Label>Organization</Form.Label>
            <Form.Control
              type="text"
              name="organization"
              value={newContact.organization}
              onChange={handleNewContactChange}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={newContact.title}
              onChange={handleNewContactChange}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={newContact.email}
              onChange={handleNewContactChange}
              isInvalid={errors.email}
              required
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={newContact.phone}
              onChange={handleNewContactChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleAddContact}>
          Add Contact
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddContactModal;
