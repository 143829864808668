import {
  ADD_PAGE_FAVORITE,
  CREATE_PAGE_VIEW_FAILURE,
  CREATE_PAGE_VIEW_START,
  CREATE_PAGE_VIEW_SUCCESS,
  FETCH_PAGE_FAVORITE,
  FETCH_PAGE_FAVORITE_FAILURE,
  FETCH_PAGE_FAVORITE_START,
  FETCH_PAGE_FAVORITE_SUCCESS,
  FETCH_PAGE_FAVORITES,
  FETCH_PAGE_FAVORITES_FAILURE,
  FETCH_PAGE_FAVORITES_START,
  FETCH_PAGE_FAVORITES_SUCCESS,
  FETCH_PAGE_VIEW_FAILURE,
  FETCH_PAGE_VIEW_START,
  FETCH_PAGE_VIEW_SUCCESS,
  MANAGE_USER_BILLING_FAIL,
  MANAGE_USER_BILLING_REQUEST,
  MANAGE_USER_BILLING_SUCCESS,
  EMAIL_BODY_REQUEST,
  EMAIL_BODY_SUCCESS,
  REMOVE_PAGE_FAVORITE,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_SET_FREE_MODE_FAIL,
  USER_SET_FREE_MODE_REQUEST,
  USER_SET_FREE_MODE_SQFT_FAIL,
  USER_SET_FREE_MODE_SQFT_REQUEST,
  USER_SET_FREE_MODE_SQFT_SUCCESS,
  USER_SET_FREE_MODE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCCESS,
} from "../../constants/userConstants";
import {
  DOMAIN_VERIFICATION_FAIL,
  DOMAIN_VERIFICATION_REQUEST,
  DOMAIN_VERIFICATION_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  PASSWORD_RESET_CONFIRM_START,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_START,
  PASSWORD_RESET_SUCCESS,
  PROJECT_SITE_ROUTE_SUCCESS,
} from "../../actionTypes";
import {
  PLAN_ROOM_REQUEST,
  PLAN_ROOM_UPDATE_FAIL,
  PLAN_ROOM_UPDATE_SUCCESS,
} from "../../constants/gcQualifyConstants";
import {
  ONBOARDING_FAILURE,
  ONBOARDING_SUCCESS,
  SUBMIT_ONBOARDING_STEP,
} from "../../constants/onboardingConstants";

export const userDetailsReducer = (
  state = { profile: null, user: null },
  action
) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {}, loading: false };
    case EMAIL_BODY_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          project_email_body: action.payload.type == "project" ? action.payload.body : state.project_email_body,
          proposal_email_body: action.payload.type == "proposal" ? action.payload.body : state.proposal_email_body,
          crm_email_body: action.payload.type == "crm" ? action.payload.body : state.crm_email_body,
        }
      };
    case PROJECT_SITE_ROUTE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          used_srr_project:
            state.user.used_srr_project < state.user.free_srr_projects && state.user.free_srr_projects !== -1
              ? state.user.used_srr_project + 1
              : state.user.free_srr_projects === -1
                ? state.user.used_srr_project
                : state.user.free_srr_projects
        }
      };
    default:
      return state;
  }
};
export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const passwordResetReducer = (state = { showError: false }, action) => {
  switch (action.type) {
    case PASSWORD_RESET_START:
      return { loading: true, success: null, showError: false };
    case PASSWORD_RESET_SUCCESS:
      return {
        loading: false,
        success: true,
        showError: false,
      };
    case PASSWORD_RESET_FAIL:
      return {
        loading: false,
        success: null,
        error: action.payload,
        showError: true,
      };

    default:
      return state;
  }
};
export const passwordResetConfirmReducer = (
  state = { showError: false },
  action
) => {
  switch (action.type) {
    case PASSWORD_RESET_CONFIRM_START:
      return { loading: true, success: null, showError: false };
    case PASSWORD_RESET_CONFIRM_SUCCESS:
      return {
        loading: false,
        success: true,
        showError: false,
      };
    case PASSWORD_RESET_CONFIRM_FAIL:
      return {
        loading: false,
        success: null,
        error: action.payload,
        showError: true,
      };

    default:
      return state;
  }
};

export const userSetFreeModeCount = (
  state = { free_mode_count: 0 },
  action
) => {
  switch (action.type) {
    case USER_SET_FREE_MODE_REQUEST:
      return { loading: true, ...state };
    case USER_SET_FREE_MODE_SUCCESS:
      return { loading: false, free_mode_count: action.payload };
    case USER_SET_FREE_MODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userSetFreeModeSqFtCount = (
  state = { free_mode_sqft_count: 0 },
  action
) => {
  switch (action.type) {
    case USER_SET_FREE_MODE_SQFT_REQUEST:
      return { loading: true, ...state };
    case USER_SET_FREE_MODE_SQFT_SUCCESS:
      return { loading: false, free_mode_sqft_count: action.payload };
    case USER_SET_FREE_MODE_SQFT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const manageUserBillingReducer = (state = {}, action) => {
  switch (action.type) {
    case MANAGE_USER_BILLING_REQUEST:
      return { loading: true, disabled: true };
    case MANAGE_USER_BILLING_SUCCESS:
      return { loading: false, disabled: false };
    case MANAGE_USER_BILLING_FAIL:
      return { loading: false, disabled: false };
    default:
      return state;
  }
};

export const sendDomainVerification = (state = {}, action) => {
  switch (action.type) {
    case DOMAIN_VERIFICATION_REQUEST:
      return { loading: true };
    case DOMAIN_VERIFICATION_SUCCESS:
      return {
        loading: false,
      };
    case DOMAIN_VERIFICATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  data: {},
  error: null,
  onboarding: null,
};

export const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_ONBOARDING_STEP:
      return { ...state, loading: true, onboarding: null };
    case ONBOARDING_SUCCESS:
      return {
        ...state,
        loading: false,
        onboarding: { ...state.data, ...action.payload },
      };
    case ONBOARDING_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createPageViewReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PAGE_VIEW_START:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PAGE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {},
      };
    case CREATE_PAGE_VIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const listPageViewReducer = (state = { pageList: [] }, action) => {
  switch (action.type) {
    case FETCH_PAGE_VIEW_START:
      return {
        ...state,
        loading: true,
        pageList: [],
      };
    case FETCH_PAGE_VIEW_SUCCESS:
      return {
        loading: false,
        pageList: [...action.payload],
      };
    case FETCH_PAGE_VIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        pageList: [],
      };
    default:
      return state;
  }
};

export const PageFavoritesReducer = (state = { favorites: [] }, action) => {
  switch (action.type) {
    case FETCH_PAGE_FAVORITES_START:
      return { ...state, favorites: [], loading: false };
    case FETCH_PAGE_FAVORITES_SUCCESS:
      return { ...state, favorites: action.payload, loading: true };
    case FETCH_PAGE_FAVORITES_FAILURE:
      return { ...state, favorites: [], loading: false };
    case ADD_PAGE_FAVORITE:
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
        loading: false,
      };
    case REMOVE_PAGE_FAVORITE:
      return {
        ...state,
        favorites: state.favorites.filter(
          (fav) => fav.page_url !== action.payload
        ),
        loading: false,
      };
    default:
      return state;
  }
};
export const fetchFavoriteReducer = (state = { isFavorite: false }, action) => {
  switch (action.type) {
    case FETCH_PAGE_FAVORITE_START:
      return { ...state, isFavorite: false, loading: true };
    case FETCH_PAGE_FAVORITE_SUCCESS:
      return {
        ...state,
        isFavorite: action.payload.is_favorite,
        loading: false,
      };
    case FETCH_PAGE_FAVORITE_FAILURE:
      return {
        ...state,
        loading: false,
        isFavorite: false,
      };
    default:
      return state;
  }
};
export const emailBodyReducer = (state = { loading: false, error: false }, action) => {
  switch (action.type) {
    case EMAIL_BODY_REQUEST:
      return { ...state, loading: true };
    case EMAIL_BODY_SUCCESS:
      return {
        ...state,
        body: action.payload.body,
        loading: false,
      };
    case FETCH_PAGE_FAVORITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
