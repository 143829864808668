import Modal from "react-bootstrap/Modal";
import LoginForm from "../forms/LoginForm";
import styled from "styled-components";
import SubscriptionModal from "../subscriptionModal";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Col } from "react-bootstrap";
import OneTimePricingCard from "../oneTimePricingCard";

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
  }
`;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const site_route_router = {
  title: "Ganarpro Site Route Router",
  features: "Lifetime access of the site route router",
  price: "25.00",
  purchased: true,
  key: "site_router",
  price_id: process.env.REACT_APP_SITE_ROUTE,
};

const deals_credit = {
  title: `<h2 class="text-center">Ganarpro Deal Marketplace Platform</h2>`,
  features: `<div>
    <h3 class="mt-1 mb-4">Top up</h3>
    <h5 class="mt-4">Price</h5>
    <h5 class="mb-5">$20 USD = 20 credits</h5>
    <h4>[Pricing Notice]</h4>
    <ol>
      <li><b>Credit System</b> - Top up in <b>$20 increments</b> and use credits to add opportunities to your pipeline. You can top up multiple times as needed. </li>
      <li><b>Earn from your Deals</b> - Receive <b>$1 per purchase</b> when others buy a deal you uploaded</li>
      <li><b>Access New Opportunities</b> - Purchase deals for <b>$2 each</b> to add them to your pipeline. </li>
    </ol>
    <a class="mt-3" href="https://learn.ganarpro.com/deal-marketplace-pricing-and-monetization/">[View Details]</a>
  </div>`,
  price: "20.00",
  purchased: true,
  key: "deals_credit",
  price_id: process.env.REACT_APP_SITE_ROUTE,
};

const lifetime_offer = {
  title: "With the lifetime access plan, you gain unlimited access to all current and future apps developed on app.ganarpro.com.<br/> <br/>We are committed to continuously expanding and enhancing our suite of tools to support the efficient management of construction business operations. Pay once and never again!",
  features: `<div>Available Tools:
  <ul>
    <li>Calculator: Estimate prices, costs, and profits for construction cleaning.</li>
    <li>Capability Statement: Create a concise summary of your company’s strengths and offerings.</li>
    <li>Contact Outreach: Access curated lists of industry-specific contacts.</li>
    <li>Planroom: Discover new bidding opportunities.</li>
    <li>Prequalify: Manage vendor and subcontractor applications effectively.</li>
    <li>Projects: Access comprehensive construction project data for prospecting and research.</li>
    <li>Proposal: Generate professional proposals for cleaning and painting services.</li>
    <li>QualKey: Centralize and manage all your business information.</li>
    <li>Site Visit Sheet: Streamline data collection during site visits.</li>
    <li>Site Route Router: Plan and optimize your visits to construction sites.</li>
  </ul>
  </div>`,
  price: "299.00",
  purchased: true,
  key: "lifetime_offer",
  price_id: process.env.REACT_APP_LIFETIME,
};

const PricingModal = (props) => {
  const { user: profile } = useSelector((state) => state.userDetails);
  const { app } = props;

  return (
    <StyledModal
      {...props}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={props.closeButton}>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Elements key={"1"} stripe={stripePromise}>
          <Col md={12} xs={12} className="mb-5 mb-lg-0 col-md-12 col-lg-12">
            <OneTimePricingCard
              product={app == "site_router" ? site_route_router : app == "deals_credit" ? deals_credit : lifetime_offer}
              profile={profile}
            />
          </Col>
        </Elements>
      </Modal.Body>
    </StyledModal>
  );
};
PricingModal.defaultProps = {
  closeButton: true,
};
export default PricingModal;
