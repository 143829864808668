import {
  ADD_DEAL_FAIL,
  ADD_DEAL_REQUEST,
  ADD_DEAL_SUCCESS,
  DEAL_CREATE_FAIL,
  DEAL_CREATE_REQUEST,
  DEAL_CREATE_SUCCESS,
  DEAL_DELETE_FAIL,
  DEAL_DELETE_REQUEST,
  DEAL_DELETE_SUCCESS,
  DEAL_FETCH_FAIL,
  DEAL_FETCH_REQUEST,
  DEAL_FETCH_SUCCESS,
  DEAL_LIST_FAIL,
  DEAL_LIST_REQUEST,
  DEAL_LIST_SUCCESS,
  DEAL_RESSET_VALUES,
  DEAL_SET_VALUES,
  DEAL_UPDATE_FAIL,
  DEAL_UPDATE_REQUEST,
  DEAL_UPDATE_SUCCESS,
  STRIPE_ACCOUNT_FAIL,
  STRIPE_ACCOUNT_REQUEST,
  STRIPE_ACCOUNT_SUCCESS,
} from "../../constants/dealConstants";

const initialValues = {
  name: "",
  account_name: "",
  description: "",
  address: "",
  project_type: "",
  city: "",
  state: "",
  state_short: "",
  zip_code: "",
  sf_size: "",
  units: "",
  plan_drawings_file: "",
  plan_drawings_url: "",
  bid_due_date: "",
  project_completion: "",
  est_break_ground_date: "",
  deal_contacts: [],
  davis_bacon_prevailing_wage_detail: false,
  laborer_union: false
};

const initialDealValues = { 
  deal: null, 
  loading: false, 
  error: null, 
  fetch_loading: false, 
  deals: {
    previous: null,
    next: null,
    count: 0,
    results: [],
  } 
}

export const DealReducer = (state = initialValues, action) => {
  switch (action.type) {
    case DEAL_SET_VALUES:
      return { ...state, ...action.payload };

    case DEAL_RESSET_VALUES:
      return initialValues;

    case DEAL_FETCH_SUCCESS:
      return { ...state, ...action.payload, plan_drawings_url: action.payload.plan_drawings };
    default:
      return state;
  }
};

export const DealActionReducer = (
  state = initialDealValues,
  action
) => {
  switch (action.type) {
    case STRIPE_ACCOUNT_REQUEST:
    case DEAL_CREATE_REQUEST:
    case DEAL_UPDATE_REQUEST:
    case DEAL_DELETE_REQUEST:
    case ADD_DEAL_REQUEST:
      return { ...state, loading: true };

    case STRIPE_ACCOUNT_SUCCESS:
    case DEAL_CREATE_SUCCESS:
    case DEAL_UPDATE_SUCCESS:
    case DEAL_DELETE_SUCCESS:
    case ADD_DEAL_SUCCESS:
      return { ...state, loading: false, error: false, deal: action.payload };

    case STRIPE_ACCOUNT_FAIL:
    case DEAL_CREATE_FAIL:
    case DEAL_UPDATE_FAIL:
    case DEAL_DELETE_FAIL:
    case ADD_DEAL_FAIL:
      return { ...state, loading: false, error: action.payload, deal: null };

    case DEAL_FETCH_REQUEST:
      return { ...state, fetch_loading: true };
    case DEAL_FETCH_SUCCESS:
    case DEAL_FETCH_FAIL:
      return { ...state, fetch_loading: false };

    case DEAL_LIST_REQUEST:
      return { ...state, loading: true };
    case DEAL_LIST_SUCCESS:
      return { 
        ...state, 
        loading: false,
        deals: {
          ...action.payload,
          results: action.payload?.previous ? [...state.deals.results, ...action.payload.results] : action.payload.results
        }
      };
    case DEAL_LIST_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
