import React, { useEffect, useState } from "react";
import Spinner from "../../../components/spinner/Spinner";
import ProjectDataCard from "../../projectBoardList/components/ProjectDataCard";
import { Form, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isSubscriptionActive } from "../../../utils/helpers/helper";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  archiveProject,
  unFavouriteProject,
  updateProjectSequence,
} from "../../../store/actions/projects/projects.action";
import LoginModal from "../../../components/LoginModal";
import SubscriptionModal from "../../../components/subscriptionModal";
import { authUser } from "../../../store/selectors/users/user.selector";
import styled from "styled-components";
import {
  createPageView,
  getUserDetail,
} from "../../../store/actions/users/users.actions";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";

const StyledSection = styled.section`
  background-color: rgb(249, 249, 251);
  min-height: 751px;
`;

const ProjectFavorite = () => {
  const [taskStatus, setTaskStatus] = useState({
    leadQualified: {
      name: "Lead Qualified",
      items: [],
    },
    contactMade: {
      name: "Contact Made",
      items: [],
    },
    proposalSent: {
      name: "Proposal Sent",
      items: [],
    },
    negotiationsStarted: {
      name: "Negotiations Started",
      items: [],
    },
  });
  const {
    favouriteProjects,
    loading: favLoading,
  } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const { user: userProfile, loading } = useSelector(
    (state) => state.userDetails
  );
  const authenticatedUser = useSelector(authUser());
  const price_id = process.env.REACT_APP_PROJECT_APP;
  const [columns, setColumns] = useState(taskStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleProjectDetailCLick = (e, link, created_by_users) => {
    e.preventDefault();
    if (!user) {
      setLoginModalShow(true);
    } else if (!isSubscriptionActive(user, price_id, user, free_mode_count) && !created_by_users) {
      setPaymentModalShow(true);
    } else if (created_by_users) {
      window.open(link, "_self");
    } else {
      window.open(link, "_self");
    }
  };

  const handleArchive = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(archiveProject(id));
  };

  const handleFavourite = (e, type, id) => {
    e.stopPropagation();
    e.preventDefault();
    if (!user) {
      setLoginModalShow(true);
    } else if (!isSubscriptionActive(user, price_id, user, free_mode_count)) {
      setPaymentModalShow(true);
    } else {
      dispatch(unFavouriteProject(id));
      dispatch(getUserDetail());
    }
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    let columnn = {};

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      columnn = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      setColumns(columnn);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      columnn = {
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      };
      setColumns(columnn);
    }
    let new_columns = {
      leadQualified: {
        ...columnn.leadQualified,
        items: columnn.leadQualified.items.map((item) => item.id),
      },
      contactMade: {
        ...columnn.contactMade,
        items: columnn.contactMade.items.map((item) => item.id),
      },
      proposalSent: {
        ...columnn.proposalSent,
        items: columnn.proposalSent.items.map((item) => item.id),
      },
      negotiationsStarted: {
        ...columnn.negotiationsStarted,
        items: columnn.negotiationsStarted.items.map((item) => item.id),
      },
    };

    dispatch(updateProjectSequence(new_columns));
  };

  const handleHeadingChange = (e) => {
    const { name, value } = e.target;
    setColumns((prevColumns) => ({
      ...prevColumns,
      [name]: {
        ...prevColumns[name],
        name: value,
      },
    }));
  };

  const handleBlurChange = (e) => {
    let new_columns = {
      leadQualified: {
        ...columns.leadQualified,
        items: columns.leadQualified.items.map((item) => item.id),
      },
      contactMade: {
        ...columns.contactMade,
        items: columns.contactMade.items.map((item) => item.id),
      },
      proposalSent: {
        ...columns.proposalSent,
        items: columns.proposalSent.items.map((item) => item.id),
      },
      negotiationsStarted: {
        ...columns.negotiationsStarted,
        items: columns.negotiationsStarted.items.map((item) => item.id),
      },
    };

    dispatch(updateProjectSequence(new_columns));
  };

  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch]);

  useEffect(() => {
    const projectFavorites = userProfile?.project_favorites;

    if (!projectFavorites) return;

    const projectMap = projectFavorites.reduce((acc, project) => {
      acc[project.id] = project;
      return acc;
    }, {});

    const updateColumnItems = (columnKey) => ({
      name:
        userProfile?.project_favorites_sequence?.[columnKey]?.name ||
        taskStatus?.[columnKey].name,
      items:
        userProfile?.project_favorites_sequence?.[columnKey]?.items
          ?.filter((item) => projectMap[item])
          .map((item) => projectMap[item]) || taskStatus?.[columnKey].items,
    });

    let updatedTaskStatus = Object.keys(columns).reduce(
      (acc, columnKey) => ({
        ...acc,
        [columnKey]: updateColumnItems(columnKey),
      }),
      {}
    );

    const modifiedProjects = projectFavorites.filter(
      (project) =>
        !Object.values(updatedTaskStatus).some((category) =>
          category.items?.some((hash) => hash.id === project.id)
        )
    );

    if (modifiedProjects.length > 0) {
      updatedTaskStatus = {
        ...updatedTaskStatus,
        leadQualified: {
          ...updatedTaskStatus.leadQualified,
          items: updatedTaskStatus.leadQualified.items.length
            ? [...updatedTaskStatus?.leadQualified?.items, ...modifiedProjects]
            : modifiedProjects,
        },
      };
    }

    setTaskStatus(updatedTaskStatus);
    setColumns(updatedTaskStatus);
  }, [userProfile?.project_favorites, userProfile?.project_favorites_sequence]);

  useEffect(() => {
    if (!favLoading) {
      navigate("/myganarpro/favorites");
    }
  }, [favouriteProjects, favLoading, navigate]);

  const path = window.location.pathname.slice(1);

  useEffect(() => {
    const pageRecord = "Pipeline";
    const type = "Pipeline";

    dispatch(createPageView(pageRecord, path, type));
  }, [dispatch, path]);

  return (
    <div>
      <div>
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12 my-4">
              <Nav
                variant="underline"
                defaultActiveKey="/home"
                className="mx-5"
              >
                <Nav.Item>
                  <Nav.Link as={Link} to="/myganarpro/favorites">
                    Pipeline
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/myganarpro/hiddenProjects">
                    Hidden Projects
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/myganarpro/recentlyViewed">
                    Recently Viewed
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/myganarpro/savedSearches">
                    Saved Searches
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <StyledSection>
        <div className="container py-5">
          <div className="row my-4">
            <div className="col-md-6 text-start">
              <div className="d-flex align-items-end gap-2">
                <div className="d-flex flex-column">
                  <h2>Track deals in one place</h2>
                  <h2>Create a custom pipeline, and never lose track of a sale again</h2>
                </div>
                <h2 className="fw-bolder fa-3xl">
                  (
                  {userProfile?.project_favorites &&
                    userProfile?.project_favorites?.length}
                  )
                </h2>
              </div>
            </div>
            <div className="col-md-6 text-start d-flex align-items-center justify-content-end">
              <Link to='/create_deal' className="btn btn-primary fs-4">Create Deal</Link>
            </div>
          </div>
          <div className="row">
            {loading ? (
              <div className="m-auto large-screen-pag">
                <Spinner />
              </div>
            ) : (
              <div className="main-row col-sm-12">
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  {Object.entries(columns).map(([columnId, column], index) => {
                    return (
                      <div className="column" key={columnId}>
                        <div style={{ padding: 8 }}>
                          <h2 className="heading">
                            <Form.Control
                              type="text"
                              name={columnId}
                              value={column.name}
                              onChange={handleHeadingChange}
                              onBlur={handleBlurChange}
                            />
                          </h2>
                          <Droppable
                            droppableId={columnId.toString()}
                            key={columnId}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  {...provided.droppableProps}
                                  className="column-items-list"
                                  ref={provided.innerRef}
                                  style={{
                                    background: snapshot.isDraggingOver
                                      ? "lightblue"
                                      : "#e3e3e3",
                                  }}
                                >
                                  {column.items &&
                                    column.items.map((item, index) => {
                                      return (
                                        <>
                                          {item && (
                                            <Draggable
                                              key={item.id}
                                              draggableId={item.id.toString()}
                                              index={index}
                                            >
                                              {(provided, snapshot) => {
                                                return (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                      margin: "0 0 8px 0",
                                                      minHeight: "50px",
                                                      backgroundColor:
                                                        "transparent",
                                                      ...provided.draggableProps
                                                        .style,
                                                    }}
                                                  >
                                                    <ProjectDataCard
                                                      key={item.id}
                                                      handleProjectDetailCLick={
                                                        handleProjectDetailCLick
                                                      }
                                                      handleArchive={
                                                        handleArchive
                                                      }
                                                      handleFavourite={
                                                        handleFavourite
                                                      }
                                                      {...item}
                                                      isFavourite={true}
                                                      authenticatedUser={
                                                        authenticatedUser
                                                      }
                                                      setLoginModalShow={
                                                        setLoginModalShow
                                                      }
                                                      setPaymentModalShow={
                                                        setPaymentModalShow
                                                      }
                                                    />
                                                  </div>
                                                );
                                              }}
                                            </Draggable>
                                          )}
                                        </>
                                      );
                                    })}
                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        </div>
                      </div>
                    );
                  })}
                </DragDropContext>
              </div>
            )}
          </div>

          <LoginModal
            show={loginModalShow}
            onHide={() => setLoginModalShow(false)}
          />
          <SubscriptionModal
            show={paymentModalShow}
            onHide={() => setPaymentModalShow(false)}
          />
        </div>
      </StyledSection>
    </div>
  );
};

export default ProjectFavorite;
