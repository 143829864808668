import {
  PROJECT_TYPE_DETAIL_FAIL,
  PROJECT_TYPE_DETAIL_REQUEST,
  PROJECT_TYPE_DETAIL_SUCCESS,
  PROJECT_TYPE_LIST_FAIL,
  PROJECT_TYPE_LIST_REQUEST,
  PROJECT_TYPE_LIST_SUCCESS,
  PROPOSAL_CREATE_FAIL,
  PROPOSAL_CREATE_REQUEST,
  PROPOSAL_CREATE_SUCCESS,
  PROPOSAL_DELETE_SUCCESS,
  PROPOSAL_DETAIL_FAIL,
  PROPOSAL_DETAIL_REQUEST,
  PROPOSAL_DETAIL_SUCCESS,
  PROPOSAL_DOWNLOAD_FAIL,
  PROPOSAL_DOWNLOAD_REQUEST,
  PROPOSAL_DOWNLOAD_SUCCESS,
  PROPOSAL_LIST_FAIL,
  PROPOSAL_LIST_REQUEST,
  PROPOSAL_LIST_SUCCESS,
  PROPOSAL_RESSET_VALUES,
  PROPOSAL_SET_VALUES,
  PROPOSAL_SET_SECTIONS,
  PROPOSAL_RESSET_SECTIONS,
  PROPOSAL_UPDATE_FAIL,
  PROPOSAL_UPDATE_REQUEST,
  PROPOSAL_UPDATE_SUCCESS,
  SUBSCRIPTION_CREATE_FAIL,
  SUBSCRIPTION_CREATE_REQUEST,
  SUBSCRIPTION_CREATE_SUCCESS,
  PROPOSAL_SET_SELECTED_SECTIONS,
  CONTACT_DETAIL_REQUEST,
  CONTACT_DETAIL_SUCCESS,
  CONTACT_DETAIL_FAIL,
  UPLOAD_PROPOSAL_DOCUMENT_REQUEST,
  UPLOAD_PROPOSAL_DOCUMENT_SUCCESS,
  UPLOAD_PROPOSAL_DOCUMENT_FAILURE,
  PROPOSAL_DOCUMENT_REQUEST,
  PROPOSAL_DOCUMENT_SUCCESS,
  PROPOSAL_DOCUMENT_FAIL,
  PROPOSAL_TEMPLATE_LIST_REQUEST,
  PROPOSAL_TEMPLATE_LIST_SUCCESS,
  PROPOSAL_TEMPLATE_LIST_FAIL,
  USER_PROJECT_TYPE_LIST_FAIL,
  USER_PROJECT_TYPE_LIST_SUCCESS,
  USER_PROJECT_TYPE_LIST_REQUEST,
} from "../../constants/proposalConstants";

export const proposalCreateReducer = (
  state = { showError: false, loading: false },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CREATE_REQUEST:
      return { loading: true, showError: false };
    case PROPOSAL_CREATE_SUCCESS:
      return { loading: false, proposal: action.payload, showError: false };
    case PROPOSAL_CREATE_FAIL:
      return { loading: false, error: action.payload, showError: true };

    default:
      return state;
  }
};
const initialValues = {
  scope_name: "Construction Cleanup Proposal",
  project_name: "",
  project_address: "",
  project_street: "",
  project_city: "",
  project_state: "",
  project_state_short: "",
  project_zip: "",
  phases: "final",
  bid_amount: "",
  rough_clean_amount: "",
  final_clean_amount: "",
  fluff_clean_amount: "",
  exterior_clean_amount: "",
  pressure_washing_amount: "",
  project_contact_1_name: "",
  project_contact_1_email: "",
  project_contact_1_phone: "",
  project_contact_2_name: "",
  project_contact_2_email: "",
  project_contact_2_phone: "",
  project_contact_3_name: "",
  project_contact_3_email: "",
  project_contact_3_phone: "",
  project_contact_4_name: "",
  project_contact_4_email: "",
  project_contact_4_phone: "",
  customer_company_name: "",
  customer_address: "",
  customer_street: "",
  customer_state: "",
  customer_state_short: "",
  customer_city: "",
  customer_zip: "",
  company_street: "",
  company_city: "",
  company_state: "",
  company_state_short: "",
  company_zip: "",
  company_contact_name: "",
  company_contact_email: "",
  company_contact_phone: "",
  company_name: "",
  user_template: "",
  cleaning: {},
  project_type: "Any Type General Cleaning",
  project_details: "",
  user_window_cleaning: "",
  default_window_cleaning: `<p><strong>Exterior Window Cleaning</strong></p>
    <ul>
      <li>Clean the exterior of windows, typically using water-fed poles, or squeegees.</li>
      <li>Ensure all edges, corners, and seals are properly cleaned without streaks.</li>
      <li>Includes cost for high-rise or hard-to-access areas and equipment.</li>
    </ul>`,
  border_color: ''
};

const initialSections = {
  scope_name: true,
  company_name: true,
  contact_name: true,
  today_date: true,
  contact_phone_email: true,
  project_address: true,
  window_cleaning: false,
  pressure_washing: false,
  client_name: true,
  client_address: true,
  contact1: false,
  contact2: false,
  contact3: false,
  contact4: false,
  sign: true,
  disclosure: true,
  footer: true,
  logo: true,
  single_bid_amount: false,
  union_requirement: false,
  davis_bacon: false,
};

export const proposalValuesReducer = (state = initialValues, action) => {
  switch (action.type) {
    case PROPOSAL_SET_VALUES:
      // if (action.payload.project_state_short !== undefined) {
      //   action.payload.project_state = State.getStateByCodeAndCountry(
      //     action.payload.project_state_short,
      //     "US"
      //   ).name;
      // } else if (action.payload.customer_state_short !== undefined) {
      //   action.payload.customer_state = State.getStateByCodeAndCountry(
      //     action.payload.customer_state_short,
      //     "US"
      //   ).name;
      // } else if (action.payload.company_state_short !== undefined) {
      //   action.payload.company_state = State.getStateByCodeAndCountry(
      //     action.payload.company_state_short,
      //     "US"
      //   ).name;
      // }
      return { ...state, ...action.payload };
    case PROPOSAL_RESSET_VALUES:
      // if (action.payload.project_state_short !== undefined) {
      //   action.payload.project_state = State.getStateByCodeAndCountry(
      //     action.payload.project_state_short,
      //     "US"
      //   ).name;
      // } else if (action.payload.customer_state_short !== undefined) {
      //   action.payload.customer_state = State.getStateByCodeAndCountry(
      //     action.payload.customer_state_short,
      //     "US"
      //   ).name;
      // } else if (action.payload.company_state_short !== undefined) {
      //   action.payload.company_state = State.getStateByCodeAndCountry(
      //     action.payload.company_state_short,
      //     "US"
      //   ).name;
      // }
      return initialValues;

    default:
      return state;
  }
};

export const proposalSectionsReducer = (state = initialSections, action) => {
  switch (action.type) {
    case PROPOSAL_SET_SECTIONS:
      return { ...state, ...action.payload };
    case PROPOSAL_RESSET_SECTIONS:
      return initialSections;
    case PROPOSAL_SET_SELECTED_SECTIONS: {
      const selectedSections = action.payload;
      return Object.keys(state).reduce(
        (acc, key) => {
          if (selectedSections.includes(key)) {
            acc[key] = true;
          } else {
            acc[key] = false;
          }

          return acc;
        },
        { ...state }
      );
    }
    default:
      return state;
  }
};

const initialProposals = {
  proposals: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  loading: false,
  error: null,
}
export const proposalListReducer = (state = initialProposals, action) => {
  switch (action.type) {
    case PROPOSAL_LIST_REQUEST:
      return { ...state, loading: true };
    case PROPOSAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        proposals: {
          ...action.payload,
          results: action.payload?.previous ? [...state.proposals.results, ...action.payload.results] : action.payload.results
        }
      };
    case PROPOSAL_DELETE_SUCCESS:
      let proposals = state.proposals?.results.filter((proposal) => {
        return proposal.id !== action.payload;
      });
      return { 
        ...state, 
        loading: false,
        proposals: {
         ...state.proposals,
          results: proposals
        }
      };
    case PROPOSAL_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const proposalDetailReducer = (state = { proposal: null }, action) => {
  switch (action.type) {
    case PROPOSAL_DETAIL_REQUEST:
      return { loading: true };
    case PROPOSAL_DETAIL_SUCCESS:
      return {
        loading: false,
        proposal: action.payload,
      };
    case PROPOSAL_DETAIL_FAIL:
      return { loading: false, proposal: null, error: action.payload };
    default:
      return state;
  }
};

export const proposalUpdateReducer = (state = { proposal: null }, action) => {
  switch (action.type) {
    case PROPOSAL_UPDATE_REQUEST:
      return { loading: true, ...state };
    case PROPOSAL_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal: action.payload,
      };
    case PROPOSAL_UPDATE_FAIL:
      return { loading: false, proposal: null, error: action.payload };
    default:
      return state;
  }
};

export const proposalDownloadReducer = (state = { proposals: {} }, action) => {
  switch (action.type) {
    case PROPOSAL_DOWNLOAD_REQUEST:
      return { loading: true, proposals: {} };
    case PROPOSAL_DOWNLOAD_SUCCESS:
      return {
        loading: false,
      };
    case PROPOSAL_DOWNLOAD_FAIL:
      return { loading: false, proposals: {}, error: action.payload };
    default:
      return state;
  }
};
export const proposalSubscriptionReducer = (
  state = { subscription: {} },
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_CREATE_REQUEST:
      return { loading: true, subscription: {} };
    case SUBSCRIPTION_CREATE_SUCCESS:
      return {
        loading: false,
        subscription: action.payload,
      };
    case SUBSCRIPTION_CREATE_FAIL:
      return { loading: false, subscription: {}, error: action.payload };
    default:
      return state;
  }
};

const initialProjectTypes = {
  projectTypes: [],
  projectTypeDetail: "",
};

export const projectTypeReducer = (state = initialProjectTypes, action) => {
  switch (action.type) {
    case PROJECT_TYPE_LIST_REQUEST:
      return { loading: true, projectTypes: [], projectTypeDetail: null };
    case PROJECT_TYPE_LIST_SUCCESS:
      return {
        loading: false,
        projectTypes: action.payload,
        projectTypeDetail: null,
      };
    case PROJECT_TYPE_DETAIL_REQUEST:
      const projectTypes = state.projectTypes.filter((projectType) => {
        return Number(projectType.id) === Number(action.payload);
      });
      return { ...state, projectTypeDetail: projectTypes[0], loading: false };
    case PROJECT_TYPE_LIST_FAIL:
      return {
        loading: false,
        projectTypes: [],
        error: action.payload,
        projectTypeDetail: null,
      };
    default:
      return state;
  }
};

const initialUserProjectTypes = {
  projectTypes: []
};

export const userProjectTypeReducer = (state = initialUserProjectTypes, action) => {
  switch (action.type) {
    case USER_PROJECT_TYPE_LIST_REQUEST:
      return { loading: true, projectTypes: [] };
    case USER_PROJECT_TYPE_LIST_SUCCESS:
      return {
        loading: false,
        projectTypes: action.payload
      };
    case USER_PROJECT_TYPE_LIST_FAIL:
      return {
        loading: false,
        projectTypes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};


const initialProposalTemplates = {
  proposalTemplates: [],
};

export const proposalTemplatesReducer = (state = initialProposalTemplates, action) => {
  switch (action.type) {
    case PROPOSAL_TEMPLATE_LIST_REQUEST:
      return { loading: true, proposalTemplates: [] };
    case PROPOSAL_TEMPLATE_LIST_SUCCESS:
      return {
        loading: false,
        proposalTemplates: action.payload
      };
    case PROPOSAL_TEMPLATE_LIST_FAIL:
      return {
        loading: false,
        proposalTemplates: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export const projectTypeDetailReducer = (
  state = initialProjectTypes,
  action
) => {
  switch (action.type) {
    case PROJECT_TYPE_DETAIL_REQUEST:
      return { loading: true, projectTypeDetail: null };
    case PROJECT_TYPE_DETAIL_SUCCESS:
      return {
        loading: false,
        projectTypeDetail: action.payload,
      };
    case PROJECT_TYPE_DETAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
        projectTypeDetail: null,
      };
    default:
      return state;
  }
};

export const contactRolesDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case CONTACT_DETAIL_REQUEST:
      return { loading: true, roles: null };
    case CONTACT_DETAIL_SUCCESS:
      return { loading: false, roles: action.payload };
    case CONTACT_DETAIL_FAIL:
      return { loading: false, error: action.payload, roles: null };
    default:
      return state;
  }
};

const proposalDocumentInitialState = {
  loading: false,
  documents: null,
  error: null,
  uploadSuccess: false,
};

const UPLOAD_RESET = "UPLOAD_RESET";
export const proposalDocumentUploadReducer = (
  state = proposalDocumentInitialState,
  action
) => {
  switch (action.type) {
    case UPLOAD_PROPOSAL_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_PROPOSAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.payload,
        error: null,
        uploadSuccess: true,
      };
    case UPLOAD_PROPOSAL_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_RESET: // You reset the uploadSuccess after fetching new file details
      return { ...state, uploadSuccess: false };
    default:
      return state;
  }
};

export const fetchProposalDocumentReducer = (
  state = { fileDetail: null },
  action
) => {
  switch (action.type) {
    case PROPOSAL_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROPOSAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        fileDetail: action.payload,
        uploadSuccess: true,
      };
    case PROPOSAL_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const deleteProposalDocumentReducer = (
  state = proposalDocumentInitialState,
  action
) => {
  switch (action.type) {
    case UPLOAD_PROPOSAL_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_PROPOSAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.payload,
        error: null,
      };
    case UPLOAD_PROPOSAL_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
