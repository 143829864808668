import {
  Col,
  Row,
  Form,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../Message";
import styled from "styled-components";
import Spinner from "../spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowsRotate,
  faArrowUp,
  faDownload,
  faEdit,
  faEllipsisV,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { BID_AMOUNT_SET_VALUES } from "../../store/constants/mortgageConstant";
import dayjs from "dayjs";
import useIsMobile from "../../utils/helpers/use-is-mobile";
import { toast } from "react-toastify";
const StyledSpinner = styled(Spinner)`
  color: red;
  border: 1px red;
  .lds-dual-ring {
    text-align: center;
    color: red;
  }
`;

const StyledArrowContainer = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    right: 180px;
  }
`;
const StyledPreviewText = styled.h1`
  color: #000;
  font-weight: 700;
  font-size: 1.8rem;
`;

const StyledPreviewTextH6 = styled.h6`
  color: #000;
  font-weight: 700;
  font-size: 1rem;
`;

const CalculatorBidAmountResult = ({
  bidAmount,
  handleIncreaseBidAmount,
  handleDecreaseBidAmount,
  handleBidAmountChange,
  bidAmountHandler,
  handleSquareFootPriceChange,
  handleEditPricePerDay,
}) => {
  const values = useSelector((state) => state.bidAmountValues);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const costValues = useSelector((state) => state.costProfitValues);
  const {
    percentage,
    loading: stateLaborLoader,
    one_day_work,
    error: stateLaborError,
  } = useSelector((state) => state.stateLaborPricing);
  const {
    phase,
    loading: bidAmountLaborLoader,
    error: bidAmountLaborError,
  } = useSelector((state) => state.bidAmountPricing);
  const { loading } = useSelector((state) => state.cleanUpEstimateDetail);
  const { user: profile } = useSelector((state) => state.userDetails);

  let { state, phase: phaseKey, project_type, square_foot } = values;
  // Create a unique key based on the combination of state, phase, and projectType
  const sqtPriceKey = `${state}_${phaseKey}_${project_type}`;

  // Initialize sqtFootPrice from local storage or set to bidAmount
  const [sqtFootPrice, setSqtFootPrice] = useState(() => {
    const storedPrice = localStorage.getItem(sqtPriceKey);
    return storedPrice !== null
      ? parseFloat(storedPrice).toFixed(2)
      : (bidAmount / square_foot).toFixed(2); // Assuming sqtFoot is 1
  });

  // Initialize originalPrice to bidAmount as this is the calculated value before any user input
  const [originalPrice, setOriginalPrice] = useState(bidAmount / square_foot); // Assuming sqtFoot is 1
  const [isEdit, setIsEdit] = useState(false);

  let phaseLength = phase.split("_").length;

  let rough = 0;
  let final = 0;
  let fluff = 0;
  switch (phase) {
    case "rough":
      rough = bidAmount;
      break;
    case "final":
      final = bidAmount;
      break;
    case "fluff":
      fluff = bidAmount;
      break;
    case "rough_final":
      rough = bidAmount * 0.4;
      final = 0.6 * bidAmount;
      break;
    case "rough_fluff":
      rough = bidAmount * 0.6;
      fluff = bidAmount * 0.4;
      break;
    case "final_fluff":
      final = bidAmount * 0.65;
      fluff = 0.35 * bidAmount;
      break;
    case "rough_final_fluff":
      rough = bidAmount * 0.3;
      final = bidAmount * 0.5;
      fluff = bidAmount * 0.2;
      break;
    default:
      rough = 0;
      final = 0;
      fluff = 0;
  }
  let sign = percentage < 0 ? -1 : 1;
  const calculatePricePerUnit = () => {
    let percnt = 0;
    if (values.union_checked) percnt += parseFloat(values?.union_percentage);
    if (values.davis_checked) percnt += parseFloat(values?.davis_percentage);

    let pricePerUnit = parseFloat(
      (
        Math.abs(percentage) * values.totalSumOfPhases +
        sign * values.totalSumOfPhases
      ).toFixed(0)
    );

    pricePerUnit = pricePerUnit < 0 ? pricePerUnit * -1 : pricePerUnit;
    pricePerUnit = parseFloat(pricePerUnit);
    pricePerUnit = pricePerUnit + (pricePerUnit * percnt) / 100;
    return pricePerUnit.toLocaleString();
  };

  let defaultPricePerUnit = calculatePricePerUnit();
  const [pricePerUnit, setPricePerUnit] = useState(defaultPricePerUnit);
  const [pricePerDay, setPricePerDay] = useState(0);

  function localStringToNumber(s) {
    return Number(String(s).replace(/[^0-9.,-]+/g, ""));
  }

  const convertBidAmountToCurrency = (value) => {
    var options = {
      maximumFractionDigits: 2,
      currency: "USD",
      currencyDisplay: "symbol",
    };
    return localStringToNumber(value).toLocaleString(undefined, options);
  };

  const convertToPercentage = (value) => {
    const percentage =
      (parseFloat(value).toFixed(2) / parseFloat(bidAmount).toFixed(2)) * 100;
    return Math.round(percentage);
  };

  let btn = document.getElementById("btn");
  let capture = document.getElementById("capture");

  const handleSquareFootChange = (e) => {
    const updatedPrice = parseFloat(e.target.value).toFixed(2);
    setSqtFootPrice(parseFloat(updatedPrice)); // Make sure it's stored as a float for any further calculations
    localStorage.setItem(sqtPriceKey, updatedPrice); // Store as string for display purposes
    handleSquareFootPriceChange(updatedPrice);
  };

  const resetToDefault = () => {
    let new_value = originalPrice;
    if (values.union_checked)
      new_value = new_value + (new_value * values?.union_percentage) / 100;
    else if (values.davis_checked)
      new_value = new_value + (new_value * values?.davis_percentage) / 100;
    const formattedOriginalPrice = parseFloat(new_value).toFixed(2);
    setSqtFootPrice(parseFloat(formattedOriginalPrice));
    localStorage.removeItem(sqtPriceKey);
    localStorage.removeItem(
      `${profile?.id}_pricePerUnit_${values?.state}_${phase}`
    );
    if (!values?.use_unit_pricing) {
      setPricePerUnit(defaultPricePerUnit);
      toast.success("Reset successfully");
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: {
          pricePerUnit: defaultPricePerUnit,
          use_price_unit_field: false,
        },
      });
    }
  };

  const handlePricePerUnit = (e) => {
    const updatedPricePerUnit = e.target.value;
    setPricePerUnit(updatedPricePerUnit);
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: {
        pricePerUnit: updatedPricePerUnit,
        use_price_unit_field: true,
      },
    });

    localStorage.setItem(
      `${profile?.id}_pricePerUnit_${values?.state}_${phase}`,
      updatedPricePerUnit
    );
  };

  const handlePricePerDay = (e) => {
    const updatedPricePerDay = e.target.value;
    handleEditPricePerDay(updatedPricePerDay, true);
    setPricePerDay(updatedPricePerDay);
    dispatch({
      type: BID_AMOUNT_SET_VALUES,
      payload: {
        pricePerUnit: updatedPricePerDay,
      },
    });
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  useEffect(() => {
    const storedPrice = localStorage.getItem(sqtPriceKey);
    let bid_amount = bidAmount;
    if (values?.sqft_and_units && values?.living_unit) {
      if (values?.use_price_unit_field)
        bid_amount =
          bid_amount -
          parseFloat(values?.living_unit) * parseFloat(pricePerUnit);
      else
        bid_amount =
          bid_amount -
          parseFloat(values?.living_unit) * parseFloat(calculatePricePerUnit());
    }
    const calculatedPrice = (bid_amount / square_foot).toFixed(2);
    let new_value = parseFloat(calculatedPrice);
    let new_price = parseFloat(pricePerUnit);
    if (storedPrice !== null) new_value = parseFloat(storedPrice);

    let percentage = 0;

    if (values.union_checked)
      percentage += parseFloat(values?.union_percentage);
    if (values.davis_checked)
      percentage += parseFloat(values?.davis_percentage);

    new_value = new_value + (new_value * percentage) / 100;

    setSqtFootPrice(new_value.toFixed(2));
  }, [
    values?.davis_checked,
    values?.union_checked,
    values?.davis_percentage,
    values?.union_percentage,
  ]);

  useEffect(() => {
    if (values?.use_unit_pricing) {
      const total =
        parseInt(values?.price_per_studio || 0) *
          parseInt(values?.no_of_unit_studio || 0) +
        parseInt(values?.price_per1_bed || 0) *
          parseInt(values?.no_of_unit1_bed || 0) +
        parseInt(values?.price_per2_bed || 0) *
          parseInt(values?.no_of_unit2_bed || 0) +
        parseInt(values?.price_per3_bed || 0) *
          parseInt(values?.no_of_unit3_bed || 0);

      const units =
        parseInt(values?.no_of_unit_studio || 0) +
        parseInt(values?.no_of_unit1_bed || 0) +
        parseInt(values?.no_of_unit2_bed || 0) +
        parseInt(values?.no_of_unit3_bed || 0);

      let percentage = 0;
      if (values.union_checked)
        percentage += parseFloat(values?.union_percentage);
      if (values.davis_checked)
        percentage += parseFloat(values?.davis_percentage);

      const new_price = total / units + ((total / units) * percentage) / 100;

      setPricePerUnit(new_price.toFixed(0));
    }
  }, [
    values?.davis_checked,
    values?.union_checked,
    values?.no_of_unit_studio,
    values?.no_of_unit1_bed,
    values?.no_of_unit2_bed,
    values?.no_of_unit3_bed,
    values?.use_unit_pricing,
    values?.price_per_studio,
    values?.price_per1_bed,
    values?.price_per2_bed,
    values?.price_per3_bed,
  ]);

  // This effect ensures that when selections change, the corresponding stored value is used
  useEffect(() => {
    const storedPrice = localStorage.getItem(sqtPriceKey);
    let bid_amount = bidAmount;
    if (values?.sqft_and_units && values?.living_unit) {
      if (values?.use_price_unit_field)
        bid_amount =
          bid_amount -
          parseFloat(values?.living_unit) * parseFloat(pricePerUnit);
      else
        bid_amount =
          bid_amount -
          parseFloat(values?.living_unit) * parseFloat(calculatePricePerUnit());
    }
    // If a stored price exists, use it, otherwise calculate a new one
    const calculatedPrice = (bid_amount / square_foot).toFixed(2);
    let new_value = parseFloat(calculatedPrice);
    if (storedPrice !== null) {
      new_value = parseFloat(storedPrice);
      setSqtFootPrice(parseFloat(storedPrice));
      // handleSquareFootPriceChange(parseFloat(storedPrice));
    } else {
      setSqtFootPrice(parseFloat(new_value).toFixed(2));
      setOriginalPrice(calculatedPrice);
      // handleSquareFootPriceChange(calculatedPrice);
    }

    let percentage = 0;

    if (values.union_checked)
      percentage += parseFloat(values?.union_percentage);
    if (values.davis_checked)
      percentage += parseFloat(values?.davis_percentage);

    new_value = new_value + (new_value * percentage) / 100;
    setSqtFootPrice(new_value.toFixed(2));
  }, [square_foot, sqtPriceKey]);

  useEffect(() => {
    const storedPrice = localStorage.getItem(sqtPriceKey);
    let bid_amount = bidAmount;
    if (values?.sqft_and_units && values?.living_unit) {
      if (values?.use_price_unit_field)
        bid_amount =
          bid_amount -
          parseFloat(values?.living_unit) * parseFloat(pricePerUnit);
      else
        bid_amount =
          bid_amount -
          parseFloat(values?.living_unit) * parseFloat(calculatePricePerUnit());
    }
    const calculatedPrice = (bid_amount / square_foot).toFixed(2);
    let new_value = parseFloat(calculatedPrice);

    if (
      storedPrice !== null &&
      !values?.davis_checked &&
      !values?.union_checked
    ) {
      new_value = parseFloat(storedPrice);
      setSqtFootPrice(parseFloat(storedPrice));
      // handleSquareFootPriceChange(parseFloat(storedPrice));
    } else {
      setSqtFootPrice(parseFloat(new_value).toFixed(2));
      setOriginalPrice(calculatedPrice);
      // handleSquareFootPriceChange(calculatedPrice);
    }
  }, [bidAmount]);

  useEffect(() => {
    setPricePerDay(one_day_work);
    // Retrieve the price per unit from local storage
    if (values?.state || values?.use_unit_pricing || phase) {
      if (!values?.use_unit_pricing) setPricePerUnit(defaultPricePerUnit);
    }

    const pricePerUnitStorage = localStorage.getItem(
      `${profile?.id}_pricePerUnit_${values?.state}_${phase}`
    );
    if (pricePerUnitStorage && !values?.use_unit_pricing) {
      let percentage = 0;
      let new_price = parseFloat(pricePerUnitStorage);

      if (values.union_checked)
        percentage += parseFloat(values?.union_percentage);
      if (values.davis_checked)
        percentage += parseFloat(values?.davis_percentage);

      new_price = new_price + (new_price * percentage) / 100;

      setPricePerUnit(parseInt(new_price));
      dispatch({
        type: BID_AMOUNT_SET_VALUES,
        payload: {
          pricePerUnit: pricePerUnitStorage,
          use_price_unit_field: true,
        },
      });
    }
  }, [
    defaultPricePerUnit,
    one_day_work,
    phase,
    values?.state,
    values?.use_unit_pricing,
    profile,
  ]);

  const printDocument = () => {
    const input = document.getElementById("capture");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      // const pdf = new jsPDF();
      const imgWidth = 208; // this is 210mm (A4 size) at 72dpi
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const pdf = new jsPDF("p", "mm", "a4");

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(
        `${values.project_name ? values.project_name : "Estimate name"}.pdf`
      );
    });
  };

  const calculateBidAmount = (bid_amount) => {
    let bid_amount_changed = parseInt(bid_amount);
    if (values?.no_stories_check) {
      bid_amount_changed = values?.window_panes
        ? bid_amount_changed + values?.window_panes * values?.price_per_window
        : bid_amount_changed + values?.no_of_stories_result;
    }
    if (values?.pressure_wash) {
      bid_amount_changed =
        bid_amount_changed +
        values?.pressure_wash * values?.pressure_wash_price;
    }
    if (values.add_flooring) {
      bid_amount_changed +=
        values?.price_per_coat * values?.no_of_coats * values?.sq_ft_floor_work;
    }
    return bid_amount_changed;
  };

  return (
    <>
      <Row className="d-flex justify-content-center">
        {bidAmountLaborLoader || stateLaborLoader || loading ? (
          <div className="text-center">
            <StyledSpinner />
          </div>
        ) : stateLaborError || bidAmountLaborError ? (
          <Message variant="danger">Not Found</Message>
        ) : (
          <Col lg={12}>
            <div className="mb-0" id="capture">
              <Row className="space-between">
                <Col lg={6} className="col-6 align-self-md-start">
                  <h3 className="text-start m-3 fw-bolder">
                    {values?.project_name ? values?.project_name : "Result"}
                  </h3>
                </Col>
                <Col lg={6} className="col-6 text-end">
                  <StyledPreviewText className=" m-3">
                    {profile?.company_name}
                  </StyledPreviewText>
                  <StyledPreviewTextH6 className=" m-3">
                    {profile?.proposal_point_contact_phone}
                  </StyledPreviewTextH6>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={6}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip className="tooltip">
                        Price to customer includes all interior cleaning plus
                        interior window cleaning
                      </Tooltip>
                    }
                  >
                    <div className="mb-5">
                      <h3 className="font-weight-bolder">Bid amount</h3>
                      {isEdit ? (
                        <div className="col-lg-12  text-26">
                          <InputGroup className="mb-3  text-26" size="lg">
                            <Button
                              variant="outline-secondary"
                              className="text-26"
                              onClick={handleDecreaseBidAmount}
                            >
                              -
                            </Button>
                            <Form.Control
                              className="text-center font-weight-bolder text-20"
                              aria-label="Bid Amount"
                              type="number"
                              value={calculateBidAmount(bidAmount).toFixed()}
                              min="1"
                              max="10000"
                              onChange={bidAmountHandler}
                            />
                            <Button
                              variant="outline-secondary"
                              className="text-26"
                              onClick={handleIncreaseBidAmount}
                            >
                              +
                            </Button>
                          </InputGroup>
                        </div>
                      ) : (
                        <h1>{calculateBidAmount(bidAmount).toFixed()}</h1>
                      )}
                    </div>
                  </OverlayTrigger>
                </Col>
              </Row>

              {values?.use_living_unit_pricing && !values?.sqft_and_units ? (
                <div className="my-4">
                  <h3 className="font-weight-bolder">Price per Unit</h3>
                  {isEdit ? (
                    <Row className="justify-content-center">
                      <Col md={4}>
                        <InputGroup
                          className="mb-3 "
                          style={{ width: "100px" }}
                        >
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            style={{ height: "50px" }}
                            className="text-20"
                            aria-label="Amount (to the nearest dollar)"
                            onChange={handlePricePerUnit}
                            type="number"
                            min="0"
                            max="10000"
                            step="1"
                            value={pricePerUnit}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  ) : (
                    <h3 className="font-weight-bolder fa-2x">
                      <span className="fa-1x small">$</span>
                      {pricePerUnit}
                      {/* {2000} */}
                    </h3>
                  )}
                </div>
              ) : costValues?.use_number_of_days ? (
                <div className="my-4">
                  <h3 className="font-weight-bolder">Price per Day</h3>
                  {isEdit ? (
                    <Row className="justify-content-center">
                      <Col md={4}>
                        <InputGroup
                          className="mb-3 "
                          style={{ width: "100px" }}
                        >
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            style={{ height: "50px" }}
                            className="text-20"
                            aria-label="Amount (to the nearest dollar)"
                            onChange={handlePricePerDay}
                            type="number"
                            min="0"
                            max="10000"
                            step="1"
                            value={pricePerDay}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  ) : (
                    <h3 className="font-weight-bolder fa-2x">
                      <span className="fa-1x small">$</span>
                      {pricePerDay}
                    </h3>
                  )}
                </div>
              ) : (sqtFootPrice !== 0 || sqtFootPrice !== "" || sqtFootPrice) &&
                !values?.sqft_and_units ? (
                <div className="my-4">
                  <Row className="justify-content-center">
                    <Col md={5}>
                      <h3 className="font-weight-bolder">Sq. ft Price</h3>
                    </Col>
                  </Row>

                  {isEdit ? (
                    <Row className="justify-content-center">
                      <Col md={5} className="d-flex justify-content-center">
                        <InputGroup className="mb-3" style={{ width: "100px" }}>
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            style={{ height: "50px" }}
                            className="text-20"
                            aria-label="Amount (to the nearest dollar)"
                            onChange={handleSquareFootChange}
                            type="number"
                            min="0"
                            max="10000"
                            step=".01"
                            value={Number(sqtFootPrice).toFixed(2)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  ) : (
                    <h3 className="font-weight-bolder fa-2x">
                      <span className="fa-1x small">$</span>

                      {Number(sqtFootPrice) > 0
                        ? Number(sqtFootPrice).toFixed(2)
                        : 0}
                    </h3>
                  )}
                </div>
              ) : values?.sqft_and_units ? (
                <>
                  <div className="my-4">
                    <Row className="justify-content-center">
                      <Col md={5}>
                        <h3 className="font-weight-bolder">Sq. ft Price</h3>
                      </Col>
                    </Row>

                    {isEdit ? (
                      <Row className="justify-content-center">
                        <Col md={5} className="d-flex justify-content-center">
                          <InputGroup
                            className="mb-3"
                            style={{ width: "100px" }}
                          >
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                              style={{ height: "50px" }}
                              className="text-20"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={handleSquareFootChange}
                              type="number"
                              min="0"
                              max="10000"
                              step=".01"
                              value={Number(sqtFootPrice).toFixed(2)}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    ) : (
                      <h3 className="font-weight-bolder fa-2x">
                        <span className="fa-1x small">$</span>

                        {Number(sqtFootPrice) > 0
                          ? Number(sqtFootPrice).toFixed(2)
                          : 0}
                      </h3>
                    )}
                  </div>
                  <div className="my-4">
                    <h3 className="font-weight-bolder">Price per Unit</h3>
                    {isEdit ? (
                      <Row className="justify-content-center">
                        <Col md={3}>
                          <InputGroup
                            className="mb-3 "
                            style={{ width: "100px" }}
                          >
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                              style={{ height: "50px" }}
                              className="text-20"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={handlePricePerUnit}
                              type="number"
                              min="0"
                              max="10000"
                              step="1"
                              value={pricePerUnit}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    ) : (
                      <h3 className="font-weight-bolder fa-2x">
                        <span className="fa-1x small">$</span>
                        {pricePerUnit}
                        {/* {2000} */}
                      </h3>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-center flex-column align-items-center">
                <OverlayTrigger
                  placement={isMobile ? "bottom" : "right"}
                  overlay={
                    <Tooltip className="tooltip">
                      <div style={{ textAlign: "center", minWidth: "200px" }}>
                        <strong>Phase Weight</strong>
                        <br />
                        <table style={{ width: "100%" }}>
                          {rough !== 0 && phaseLength > 1 && (
                            <tr>
                              <td
                                className="ps-2"
                                style={{ minWidth: "50%", textAlign: "start" }}
                              >
                                Rough Cleaning
                              </td>
                              <td
                                style={{ minWidth: "50%", textAlign: "start" }}
                              >
                                {convertToPercentage(rough)}%
                              </td>
                            </tr>
                          )}
                          {final !== 0 && phaseLength >= 1 && (
                            <tr>
                              <td
                                className="ps-2"
                                style={{ minWidth: "50%", textAlign: "start" }}
                              >
                                Final Cleaning
                              </td>
                              <td
                                style={{ minWidth: "50%", textAlign: "start" }}
                              >
                                {convertToPercentage(final)}%
                              </td>
                            </tr>
                          )}
                          {fluff !== 0 && phaseLength > 1 && (
                            <tr>
                              <td
                                className="ps-2"
                                style={{ minWidth: "50%", textAlign: "start" }}
                              >
                                Fluff Cleaning
                              </td>
                              <td
                                style={{ minWidth: "50%", textAlign: "start" }}
                              >
                                {convertToPercentage(fluff)}%
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </Tooltip>
                  }
                >
                  <div>
                    {rough !== 0 && phaseLength > 1 && (
                      <div className="my-4" style={{ width: "max-content" }}>
                        <h3 className="font-weight-bolder">Rough cleaning</h3>
                        <h3 className="font-weight-bolder fa-2x">
                          <span className="fa-1x small">$</span>
                          {convertBidAmountToCurrency(
                            parseFloat(rough).toFixed(0).toLocaleString()
                          )}
                        </h3>
                      </div>
                    )}
                    {final !== 0 && phaseLength >= 1 && (
                      <div className="my-4" style={{ width: "max-content" }}>
                        <h3 className="font-weight-bolder">Final Cleaning</h3>
                        <h3 className="font-weight-bolder fa-2x">
                          <span className="fa-1x small">$</span>
                          {convertBidAmountToCurrency(
                            parseFloat(final).toFixed(0).toLocaleString()
                          )}
                          {/* <span className="small ms-5">{convertToPercentage(final)}%</span> */}
                        </h3>
                      </div>
                    )}

                    {fluff !== 0 && phaseLength > 1 && (
                      <div className="my-4" style={{ width: "max-content" }}>
                        <h3 className="font-weight-bolder">Fluff Cleaning</h3>
                        <h3 className="font-weight-bolder fa-2x">
                          <span className="fa-1x small">$</span>
                          {convertBidAmountToCurrency(
                            parseFloat(fluff).toFixed(0).toLocaleString()
                          )}
                          {/* <span className="small ms-5">{convertToPercentage(fluff)}%</span> */}
                        </h3>
                      </div>
                    )}
                  </div>
                </OverlayTrigger>
              </div>

              {values?.no_stories_check && (
                <div className="my-4">
                  <h3 className="font-weight-bolder">
                    Exterior window cleaning
                  </h3>
                  <h3 className="font-weight-bolder fa-2x">
                    <span className="fa-1x small">$</span>
                    {values?.window_panes
                      ? values?.window_panes * values?.price_per_window
                      : convertBidAmountToCurrency(
                          parseInt(values?.no_of_stories_result)
                        )}
                  </h3>
                </div>
              )}

              {values.pressure_wash > 0 ? (
                <div className="my-4">
                  <h3 className="font-weight-bolder">Pressure wash price</h3>
                  <h3 className="font-weight-bolder fa-2x">
                    <span className="fa-1x small">$</span>
                    {convertBidAmountToCurrency(
                      parseFloat(
                        values?.pressure_wash * values?.pressure_wash_price
                      )
                        .toFixed(0)
                        .toLocaleString()
                    )}
                  </h3>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        )}
      </Row>
      <Row className="my-5">
        <Col className="col-md-12">
          <p className="text-start mb-0 mx-5">
            {values.modified
              ? dayjs(values.modified).format("MMMM D, YYYY")
              : dayjs().format("MMMM D, YYYY")}
          </p>
        </Col>
      </Row>
      <Row className="justify-content-end align-self-end text-end">
        <Dropdown>
          <Dropdown.Toggle as="span" className="cursor-pointer">
            <FontAwesomeIcon icon={faEllipsisV} size="xl" />
          </Dropdown.Toggle>

          <Dropdown.Menu align="end">
            {!isEdit ? (
              <Dropdown.Item onClick={handleEdit}>
                <FontAwesomeIcon icon={faEdit} className="me-2" />
                Customize
              </Dropdown.Item>
            ) : (
              <Dropdown.Item onClick={() => setIsEdit(false)}>
                <FontAwesomeIcon icon={faEye} className="me-2" />
                View
              </Dropdown.Item>
            )}

            <Dropdown.Item onClick={printDocument}>
              <FontAwesomeIcon icon={faDownload} className="me-2" />
              Download
            </Dropdown.Item>

            <Dropdown.Item onClick={resetToDefault}>
              <FontAwesomeIcon icon={faArrowsRotate} className="me-2" />
              Reset
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Row>
    </>
  );
};

export default CalculatorBidAmountResult;
