import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import homeIcon from "../../../assets/img/sidebar/svg/home.svg";
import dashboardIcon from "../../../assets/img/sidebar/svg/dashboard.svg";
import projectIcon from "../../../assets/img/sidebar/svg/projects.svg";
import calculatorIcon from "../../../assets/img/sidebar/svg/calculator.svg";
import proposalIcon from "../../../assets/img/sidebar/svg/proposal.svg";
import prequalifyIcon from "../../../assets/img/sidebar/svg/prequalify.svg";
import my_companyIcon from "../../../assets/img/sidebar/svg/my_company.jpg";
import dealsIcon from "../../../assets/img/sidebar/svg/deals.svg";
import createDealsIcon from "../../../assets/img/sidebar/create_deals.png";
import crmIcon from "../../../assets/img/sidebar/crm_2.png";
import Logo from "../../../assets/img/sidebar/Logo.png";
import ContactOutreachIcon from "../../../assets/img/contactOutreachIcon.png";
import SiteVisitSheetIcon from "../../../assets/img/site_visit.jpg";
import forgot_passwordIcon from "../../../assets/img/sidebar/svg/forgot_password.svg";
import doItForMeIcon from "../../../assets/img/sidebar/do-it-for-me.png";
import { getManageUserBilling } from "../../../store/actions/users/users.actions";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/actions/authentication.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faHandshake,
  faMoneyBill,
  faSignIn,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import DropDownMenu from "./DropDownMenu";

const mainList = [
  {
    name: "Home",
    to: "/",
    icon: homeIcon,
    divider: false,
    deals_menu: false,
    hasSubCategory: false,
  },
  {
    name: "Dashboard",
    to: "/dashboard",
    icon: dashboardIcon,
    divider: true,
    deals_menu: false,
    hasSubCategory: false,
  },
  {
    name: "Deal Marketplace",
    to: "/deal/marketplace",
    icon: homeIcon,
    divider: true,
    hasSubCategory: false,
    deals_menu: true,
  },
];

const sideBarList = [
  {
    name: "Projects",
    divider: false,
    hasSubCategory: true,
    categories: [
      { name: "Overview", to: "/projects_overview" },
      { name: "Project Research", to: "/project_research" },
      { name: "Project Board", to: "/project_board" },
      { name: "Site Route Router", to: "/site_router" },
    ],
    // "icon": "../../../assets/img/sidebar/projects.png",
    icon: projectIcon,
  },
  {
    name: "Cleanup Calculator",
    to: "/cleanup_calculator_overview",
    icon: calculatorIcon,
    divider: false,
    hasSubCategory: true,
    categories: [
      { name: "Overview", to: "/cleanup_calculator_overview" },
      { name: "Rates", to: "/cleanup_rates" },
      { name: "Calculator", to: "/cleanup_calculator" },
    ],
  },
  {
    name: "Cleanup Proposal",
    to: "/proposal",
    icon: proposalIcon,
    divider: false,
    hasSubCategory: true,
    categories: [
      { name: "Overview", to: "/proposal" },
      { name: "Proposal generator", to: "/my_proposal" },
      { name: "Templates", to: "/proposal_templates" },
    ],
  },
  {
    name: "Paint Proposal",
    to: "/paint_proposal",
    icon: proposalIcon,
    divider: false,
    hasSubCategory: false,
  },
  {
    name: "Planify",
    to: "/planify",
    icon: prequalifyIcon,
    divider: false,
    hasSubCategory: true,
    categories: [
      { name: "Overview", to: "/planify" },
      { name: "Prequalify", to: "/pre_qualify" },
      { name: "Planroom", to: "/plan_room" },
    ],
  },
  {
    name: "QualKey",
    to: "/qualkey",
    icon: my_companyIcon,
    divider: false,
    hasSubCategory: false,
  },
  {
    name: "Capability Statement",
    to: "/capability_statement",
    icon: Logo,
    divider: false,
  },
  {
    name: "Contact Outreach",
    to: "/contact_outreach",
    icon: ContactOutreachIcon,
    divider: false,
  },
  {
    name: "Site Visit Sheet",
    to: "/site_visit",
    icon: SiteVisitSheetIcon,
    divider: false,
  }
];

const bottomList = [
  {
    name: " Do-It-For-Me",
    to: "https://www.ganarpro.com/do-it-for-me-sales-growth/",
    icon: doItForMeIcon,
    divider: false,
    dividerTop: true,
    website: true,
    hasSubCategory: false,
  },
  {
    name: "Password Reset",
    to: "/password_reset",
    icon: forgot_passwordIcon,
    divider: false,
    hasSubCategory: false,
  }
];

const shortcuts = [
  {
    name: "Estimates",
    to: "/cleanup_calculator?show_tab=recent",
    icon: calculatorIcon,
    divider: false,
    deals_menu: false,
    hasSubCategory: false,
  },
  {
    name: "Proposals",
    to: "/my_proposal?show_tab=recent",
    icon: proposalIcon,
    divider: false,
    deals_menu: false,
    hasSubCategory: false,
  },
  {
    name: "Proposals",
    to: "/paint_proposal?show_tab=recent",
    icon: proposalIcon,
    divider: false,
    deals_menu: false,
    hasSubCategory: false,
  },
  {
    name: "Create Deal",
    to: "/create_deal",
    icon: createDealsIcon,
    divider: false,
    deals_menu: true,
    hasSubCategory: false,
  },
  {
    name: "Pipeline",
    to: "/myganarpro/favorites",
    icon: dealsIcon,
    divider: false,
    hasSubCategory: false,
  },
  {
    name: "Contacts",
    to: "/crm",
    icon: crmIcon,
    divider: false,
    hasSubCategory: false,
    style: { width: "40px", height: "40px", marginInline: "10px" }
  },
  {
    name: "Planify",
    to: "/planify",
    icon: prequalifyIcon,
    divider: false,
    deals_menu: true,
    hasSubCategory: false,
  },
];

function SidebarV3({
  show,
  toggleSideBarHandler,
  closeSidebarHandler,
  ...props
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const [showAll, setShowAll] = useState(false);
  // Check if the current pathname is '/onboarding'
  const isOnboarding = location.pathname.includes("/onboarding");
  const { loading: billingLoader, disabled } = useSelector(
    (state) => state.manageUserBilling
  );
  const { user: profile, loading } = useSelector((state) => state.userDetails);
  const paint_app = localStorage.getItem(`isSwitchButton_${user?.id}`)
    ? JSON.parse(localStorage.getItem(`isSwitchButton_${user?.id}`))
    : !profile?.is_cleaning_company;
  const ganarpro_app = localStorage.getItem(`ganarpro_app${user?.id}`);

  const navigate = useNavigate();

  const onLogoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
  };
  const handleManageBilling = (e) => {
    e.preventDefault();
    dispatch(getManageUserBilling(user?.customer));
  };
  const closeSidebar = (e, link) => {
    e.preventDefault();
    toggleSideBarHandler(false);
    link.startsWith("https://")
      ? (window.location.href = link)
      : navigate(link);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const getFilteredSideBarList = () => {
    if (ganarpro_app === "cleaning") {
      return sideBarList.filter((app) => app.to !== "/paint_proposal");
    } else if (ganarpro_app === "paint") {
      return sideBarList.filter(
        (app) =>
          app.to !== "/cleanup_calculator_overview" && app.to !== "/proposal"
      );
    } else if (ganarpro_app === "business") {
      return sideBarList.filter(
        (app) =>
          app.to !== "/cleanup_calculator_overview" &&
          app.to !== "/proposal" &&
          app.to !== "/paint_proposal"
      );
    } else {
      return sideBarList.filter((app) => app.to !== "/paint_proposal");
    }
  };

  const getFilteredShortcutList = () => {
    if (ganarpro_app === "cleaning") {
      return shortcuts.filter((app) => !app.to.includes("/paint_proposal")).filter((item) => {
        const isDealsMenuMatch = item.deals_menu == window.location.href.indexOf("/deal/marketplace") > 1;
        const isDealsMenuMissing = !item.hasOwnProperty("deals_menu");
        return isDealsMenuMatch || isDealsMenuMissing;
      });

    } else if (ganarpro_app === "paint") {
      return shortcuts.filter((app) => !app.to.includes("/my_proposal") && !app.to.includes("/cleanup_calculator")).filter((item) => {
        const isDealsMenuMatch = item.deals_menu == false;
        const isDealsMenuMissing = !item.hasOwnProperty("deals_menu");
        return isDealsMenuMatch || isDealsMenuMissing;
      });

    } else if (ganarpro_app === "business") {
      return shortcuts.filter((app) => app.to == "/myganarpro/favorites" || app.to == "/crm").filter((item) => {
        const isDealsMenuMatch = item.deals_menu == false;
        const isDealsMenuMissing = !item.hasOwnProperty("deals_menu");
        return isDealsMenuMatch || isDealsMenuMissing;
      });

    } else if (ganarpro_app === "basic_cleaning") {
      return shortcuts.filter((app) => !app.to.includes("/paint_proposal")).filter((item) => {
        const isDealsMenuMatch = item.deals_menu == false;
        const isDealsMenuMissing = !item.hasOwnProperty("deals_menu");
        return isDealsMenuMatch || isDealsMenuMissing;
      });

    } else if (ganarpro_app === "deal") {
      return shortcuts.filter((item) => {
        const isDealsMenuMatch = item.deals_menu == true;
        const isDealsMenuMissing = !item.hasOwnProperty("deals_menu");
        return isDealsMenuMatch || isDealsMenuMissing;
      });
    } else {
      return shortcuts.filter((app) => !app.to.includes("/paint_proposal")).filter((item) => {
        const isDealsMenuMatch = item.deals_menu == false;
        const isDealsMenuMissing = !item.hasOwnProperty("deals_menu");
        return isDealsMenuMatch || isDealsMenuMissing;
      });
    }
  };

  const visibleList = showAll ? getFilteredSideBarList() : getFilteredSideBarList().slice(0, 3);

  return (
    <>
      {!isOnboarding && (
        <Button
          variant="none"
          onClick={toggleSideBarHandler}
          className="p-0 fs-1"
        >
          <span className="sidebar-open-v2">&#9776;</span>
        </Button>
      )}
      <Offcanvas show={show} onHide={closeSidebarHandler} {...props}>
        <Offcanvas.Header>
          {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          <Offcanvas.Title onClick={toggleSideBarHandler} role="button">
            <span className="sidebar-open-v2">&#9776;</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {mainList.filter(item => item.deals_menu == (ganarpro_app == 'deal')).map((list, index) => (
            <DropDownMenu list={list} key={index} closeSidebar={closeSidebar}/>
          ))}
          {getFilteredShortcutList().map((list, index) => (
            <DropDownMenu list={list} key={index} closeSidebar={closeSidebar} heading={index == 0 ? "Shortcuts" : null} style={list?.style}/>
          ))}
          {ganarpro_app != 'deal' &&
            visibleList.map((list, index) => (
              <DropDownMenu
                list={list}
                key={index}
                closeSidebar={closeSidebar}
                heading={index === 0 ? "Products" : null}
              />
            ))
          }

          {ganarpro_app != 'deal' && sideBarList.length > 3 && (
            <button onClick={toggleShowAll} className="toggle-more-btn mx-4 mx-lg-5 px-lg-2 fs-4 py-2">
              {showAll ? "...Less" : "...More"}
            </button>
          )}

          {bottomList.map((list, index) => (
            <DropDownMenu list={list} key={index} closeSidebar={closeSidebar}/>
          ))}

          {user && user?.subscriptions?.length > 0 && (
            <div>
              <NavLink
                to="/"
                className="sidebar-links my-3 "
                onClick={handleManageBilling}
                disabled={disabled}
              >
                {/*<img src={my_companyIcon} style={{ width: 60 }} alt="home" />*/}
                <FontAwesomeIcon
                  icon={faCreditCard}
                  size="xl"
                  style={{ width: 60 }}
                  className="cursor-pointer ms-lg-4 "
                />
                <span className="">
                  Billing
                  {billingLoader && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="visually-hidden">Loading...</span>
                </span>
              </NavLink>
              <div>
                <NavLink to="/pricing" className="sidebar-links my-3 ">
                  <FontAwesomeIcon
                    icon={faMoneyBill}
                    size="xl"
                    style={{ width: 60 }}
                    className="cursor-pointer ms-lg-4 "
                  />
                  <span className="">Pricing</span>
                </NavLink>
              </div>
            </div>
          )}
          {user && (
            <>
              <div>
                <NavLink to="/referral/invite" className="sidebar-links my-3">
                  <FontAwesomeIcon
                    icon={faHandshake}
                    size="xl"
                    style={{ width: 60 }}
                    className="cursor-pointer ms-lg-4 "
                  />
                  <span>Referral</span>
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/"
                  className="sidebar-links my-3"
                  onClick={onLogoutHandler}
                >
                  <FontAwesomeIcon
                    icon={faSignOut}
                    size="xl"
                    style={{ width: 60 }}
                    className="cursor-pointer ms-lg-4 "
                  />
                  <span>Logout</span>
                </NavLink>
              </div>
            </>
          )}
          {!user && (
            <div>
              <NavLink to="/login" className="sidebar-links my-3 ">
                <FontAwesomeIcon
                  icon={faSignIn}
                  size="xl"
                  style={{ width: 60 }}
                  className="cursor-pointer ms-lg-4 "
                />
                <span className="">Login</span>
              </NavLink>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

// function Example() {
//   return (
//     <>
//   {options.map((props, idx) => (
//     <OffCanvasExample key={idx} {...props} />
//   ))}
//     </>
//   );
// }

// render(<Example />);

export default SidebarV3;
