export const priceMap = {
  [process.env.REACT_APP_ALL_PRO]: "cleaning",
  [process.env.REACT_APP_ALL_APPS]: "cleaning",
  [process.env.REACT_APP_BASIC_CLEANING]: "basic_cleaning",
  [process.env.REACT_APP_BUSINESS]: "business",
  [process.env.REACT_APP_CALC_APP]: "business",
  [process.env.REACT_APP_DOCUMENT_STARTER]: "business",
  [process.env.REACT_APP_PREQUAL_APP]: "basic_cleaning",
  [process.env.REACT_APP_PROJECT_APP]: "business",
  [process.env.REACT_APP_PROPOSAL_APP]: "business",
  [process.env.REACT_APP_GANARPRO_PAINTING]: "paint",
  [process.env.REACT_APP_LIFETIME]: "cleaning",
};