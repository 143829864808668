// import dotenv from "dotenv";
// import jwt from "jsonwebtoken";
import {
  isEmpty,
  toastError,
  toastSuccess,
} from "../../../utils/helpers/helper";
import {
  allProjects,
  allProjectsTable,
  singleProject,
  projectBylocations,
  archiveProjectAPI,
  unArchiveProjectAPI,
  favouriteProjectAPI,
  unFavouriteProjectAPI,
  saveSearchProjectAPI,
  deleteSearchProjectAPI,
  getEmailTemplateAPI,
  saveSequenceAPI,
  addSiteRouteAPI,
  removeSiteRouteAPI,
  updateSiteRouteNotesAPI,
  removeAllSiteRouteAPI,
  updateContactLastDateAPI,
  updateProjectNotesAPI,
  projectVisitAPI,
  deleteContactActivityAPI,
  addContactActivityAPI,
} from "../../../utils/requests/projects";
import {
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_TABLE_SUCCESS,
  FETCH_PROJECTS_FAIL,
  FETCH_SINGLE_PROJECT_SUCCESS,
  ARCHIVE_PROJECT_SUCCESS,
  UNARCHIVE_PROJECT_SUCCESS,
  FAVOURITE_PROJECT_SUCCESS,
  UNFAVOURITE_PROJECT_SUCCESS,
  UPDATE_FAVOURITE_PROJECT_SUCCESS,
  PROJECT_ACTION_FAILED,
  PROJECT_SAVE_SEARCH_SUCCESS,
  PROJECT_SAVE_SEARCH_START,
  PROJECT_SAVE_SEARCH_FAILED,
  PROJECT_DELETE_SEARCH_SUCCESS,
  PROJECT_DELETE_SEARCH_START,
  PROJECT_DELETE_SEARCH_FAILED,
  GET_EMAIL_TEMPLATE_START,
  GET_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_TEMPLATE_FAILED,
  PROJECT_SITE_ROUTE_SUCCESS,
  PROJECT_REMOVE_SITE_ROUTE_SUCCESS,
  SITE_ROUTE_NOTES_REQUEST,
  SITE_ROUTE_NOTES_SUCCESS,
  SITE_ROUTE_NOTES_FAILED,
  PROJECT_REMOVE_ALL_SITE_ROUTE_SUCCESS,
  UPDATE_LAST_CONTACT_REQUEST,
  UPDATE_LAST_CONTACT_SUCCESS,
  UPDATE_LAST_CONTACT_FAILED,
  UPDATE_PROJECT_NOTES_FAILED,
  UPDATE_PROJECT_NOTES_SUCCESS,
  UPDATE_PROJECT_NOTES_REQUEST,
  UPDATE_PROJECT_LAST_CONTACT_SUCCESS,
  UPDATE_PROJECT_LAST_CONTACT_REQUEST,
  UPDATE_PROJECT_LAST_CONTACT_FAILED,
  REMOVE_ACTIVITY_REQUEST,
  REMOVE_ACTIVITY_SUCCESS,
  REMOVE_ACTIVITY_FAILED,
  ADD_ACTIVITY_REQUEST,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_FAILED,
  UPDATE_ACCOUNT_LAST_CONTACT,
} from "../../actionTypes";
import {
  USER_PROJECTS_SEQUENCE_FAIL,
  USER_PROJECTS_SEQUENCE_REQUEST,
  USER_PROJECTS_SEQUENCE_SUCCESS,
} from "../../constants/proposalConstants";
import {
  UPDATE_USER_PROJECT_BOARD_DATA_FAILURE,
  UPDATE_USER_PROJECT_BOARD_DATA_REQUEST,
  UPDATE_USER_PROJECT_BOARD_DATA_SUCCESS,
} from "../../constants/userProjectBoardData";
import axios from "axios";

export const fetchProjectsStart = () => {
  return {
    type: FETCH_PROJECTS_START,
  };
};

export const fetchProjectsSuccess = (data) => {
  return {
    type: FETCH_PROJECTS_SUCCESS,
    data,
  };
};

export const fetchProjectsTableSuccess = (data) => {
  return {
    type: FETCH_PROJECTS_TABLE_SUCCESS,
    data,
  };
};

export const fetchProjectsFailed = (error) => {
  return {
    type: FETCH_PROJECTS_FAIL,
    error,
  };
};

export const fetchSingleProjectSuccess = (data) => {
  return {
    type: FETCH_SINGLE_PROJECT_SUCCESS,
    data,
  };
};

export const archiveProjectSuccess = (projectId, status) => {
  return {
    type: ARCHIVE_PROJECT_SUCCESS,
    data: { projectId, status: status },
  };
};

export const unArchiveProjectSuccess = (projectId, status) => {
  return {
    type: UNARCHIVE_PROJECT_SUCCESS,
    data: { projectId, status: status },
  };
};

export const favouriteProjectSuccess = (projectId) => {
  return {
    type: FAVOURITE_PROJECT_SUCCESS,
    projectId,
  };
};

export const unFavouriteProjectSuccess = (projectId) => {
  return {
    type: UNFAVOURITE_PROJECT_SUCCESS,
    projectId,
  };
};

export const addProjectSiteRouteSuccess = (projectId) => {
  return {
    type: PROJECT_SITE_ROUTE_SUCCESS,
    projectId,
  };
};

export const removeProjectSiteRouteSuccess = (projectId) => {
  return {
    type: PROJECT_REMOVE_SITE_ROUTE_SUCCESS,
    projectId,
  };
};

export const removeAllProjectSiteRouteSuccess = () => {
  return {
    type: PROJECT_REMOVE_ALL_SITE_ROUTE_SUCCESS,
  };
};

export const siteRouteNotesRequest = () => {
  return {
    type: SITE_ROUTE_NOTES_REQUEST,
  };
};

export const siteRouteNotesSuccess = (notes) => {
  return {
    type: SITE_ROUTE_NOTES_SUCCESS,
    notes,
  };
};

export const siteRouteNotesFail = (error) => {
  return {
    type: SITE_ROUTE_NOTES_FAILED,
    error,
  };
};

export const contactLastDateRequest = () => {
  return {
    type: UPDATE_LAST_CONTACT_REQUEST,
  };
};

export const contactLastDateSuccess = (lastDate, activity) => {
  return {
    type: UPDATE_LAST_CONTACT_SUCCESS,
    lastDate,
    activity,
  };
};

export const contactLastDateFail = (error) => {
  return {
    type: UPDATE_LAST_CONTACT_FAILED,
    error,
  };
};

export const projectContactLastDateRequest = () => {
  return {
    type: UPDATE_PROJECT_LAST_CONTACT_REQUEST,
  };
};

export const projectContactLastDateSuccess = (lastDate) => {
  return {
    type: UPDATE_PROJECT_LAST_CONTACT_SUCCESS,
    lastDate,
  };
};

export const projectContactLastDateFail = (error) => {
  return {
    type: UPDATE_PROJECT_LAST_CONTACT_FAILED,
    error,
  };
};

export const addContactActivityRequest = () => {
  return {
    type: ADD_ACTIVITY_REQUEST
  };
};

export const addContactActivitySuccess = (lastDate, activity) => {
  return {
    type: ADD_ACTIVITY_SUCCESS,
    lastDate,
    activity
  };
};

export const addContactActivityFail = (error) => {
  return {
    type: ADD_ACTIVITY_FAILED,
    error,
  };
};

export const deleteContactActivityRequest = () => {
  return {
    type: REMOVE_ACTIVITY_REQUEST,
  };
};

export const deleteContactActivitySuccess = (activity_id) => {
  return {
    type: REMOVE_ACTIVITY_SUCCESS,
    activity_id,
  };
};

export const deleteContactActivityFail = (error) => {
  return {
    type: REMOVE_ACTIVITY_FAILED,
    error,
  };
};

export const projectNotesRequest = () => {
  return {
    type: UPDATE_PROJECT_NOTES_REQUEST,
  };
};

export const projectNotesSuccess = (notes) => {
  return {
    type: UPDATE_PROJECT_NOTES_SUCCESS,
    notes,
  };
};

export const projectNotesFail = (error) => {
  return {
    type: UPDATE_PROJECT_NOTES_FAILED,
    error,
  };
};

export const updateFavouriteProjectSuccess = (projects) => {
  return {
    type: UPDATE_FAVOURITE_PROJECT_SUCCESS,
    projects,
  };
};

export const projectSaveSearchStart = () => {
  return {
    type: PROJECT_SAVE_SEARCH_START,
  };
};

export const projectSaveSearchSuccess = (data) => {
  return {
    type: PROJECT_SAVE_SEARCH_SUCCESS,
    data,
  };
};

export const projectSaveSearchFailed = (error) => {
  return {
    type: PROJECT_SAVE_SEARCH_FAILED,
    error,
  };
};

export const projectDeleteSearchStart = () => {
  return {
    type: PROJECT_DELETE_SEARCH_START,
  };
};

export const projectDeleteSearchSuccess = (data) => {
  return {
    type: PROJECT_DELETE_SEARCH_SUCCESS,
    data,
  };
};

export const projectDeleteSearchFailed = (error) => {
  return {
    type: PROJECT_DELETE_SEARCH_FAILED,
    error,
  };
};

export const projectActionFailed = (error) => {
  return {
    type: PROJECT_ACTION_FAILED,
    error,
  };
};

export const getEmailTemplateStart = () => {
  return {
    type: GET_EMAIL_TEMPLATE_START,
  };
};

export const getEmailTemplateSuccess = (data) => {
  return {
    type: GET_EMAIL_TEMPLATE_SUCCESS,
    data,
  };
};

export const getEmailTemplateFailed = (error) => {
  return {
    type: GET_EMAIL_TEMPLATE_FAILED,
    error,
  };
};

export const fetchProjects = (queryString = null) => {
  return async (dispatch) => {
    dispatch(fetchProjectsStart());

    return allProjects(queryString)
      .then(async (response) => {
        dispatch(fetchProjectsSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchProjectsFailed(error.data));
      });
  };
};

export const fetchProjectsTables = (queryString = null) => {
  return async (dispatch) => {
    dispatch(fetchProjectsStart());

    return allProjectsTable(queryString)
      .then(async (response) => {
        dispatch(fetchProjectsTableSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchProjectsFailed(error.data));
      });
  };
};

export const fetchProjectsByLocation = (locations) => {
  return async (dispatch) => {
    dispatch(fetchProjectsStart());

    return projectBylocations(locations)
      .then(async (response) => {
        dispatch(fetchProjectsSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchProjectsFailed(error.data));
      });
  };
};

export const fetchSingleProject = (projectId) => {
  return async (dispatch) => {
    dispatch(fetchProjectsStart());

    return singleProject(projectId)
      .then(async (response) => {
        dispatch(fetchSingleProjectSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchProjectsFailed(""));
      });
  };
};
const CustomToastWithLink = (message, link, linkName = "view list") => (
  <div>
    <h4 className="fw-bolder">
      {message} <a href={link}>{linkName}</a>
    </h4>
  </div>
);

const CustomToastWithLinkNewTab = (message, link, linkName = "view list") => (
  <div>
    <h4 className="fw-bolder">
      {message}{" "}
      <a href={link} target="_blank" rel="noreferrer">
        {linkName}
      </a>
    </h4>
  </div>
);
export const archiveProject = (projectId, status) => {
  return async (dispatch) => {
    // dispatch(fetchProjectsStart());
    return archiveProjectAPI(projectId)
      .then((response) => {
        let message = "Added to hidden Project list";
        let link = "/myganarpro/hiddenProjects";
        dispatch(archiveProjectSuccess(projectId, status));
        toastSuccess(() => CustomToastWithLink(message, link));
      })
      .catch((error) => {
        dispatch(fetchProjectsFailed(""));
      });
  };
};

export const unArchiveProject = (projectId, status) => {
  return async (dispatch) => {
    dispatch(fetchProjectsStart());
    return unArchiveProjectAPI(projectId)
      .then((response) => {
        let message = "Project removed from hidden list";
        let link = "/myganarpro/hiddenProjects";
        dispatch(unArchiveProjectSuccess(projectId, status));
        toastSuccess(() => CustomToastWithLink(message, link));
      })
      .catch((error) => {
        dispatch(fetchProjectsFailed(""));
      });
  };
};

export const favouriteProject = (projectId, projectsLiked) => {
  return async (dispatch) => {
    if (projectId) {
      return favouriteProjectAPI(projectId)
        .then((response) => {
          let message = "Project added to Pipeline";
          let link = "/myganarpro/favorites";
          toastSuccess(() => CustomToastWithLink(message, link));
          dispatch(favouriteProjectSuccess(projectId));
        })
        .catch((error) => {
          dispatch(fetchProjectsFailed(""));
        });
    }
  };
};

export const unFavouriteProject = (projectId) => {
  return async (dispatch) => {
    dispatch(fetchProjectsStart());
    let message = " Project removed from Pipeline";
    let link = "/myganarpro/favorites";
    toastSuccess(() => CustomToastWithLink(message, link));
    const response = await unFavouriteProjectAPI(projectId);
    if (response.status) {
      dispatch(unFavouriteProjectSuccess(projectId));
      return;
    }
    return dispatch(fetchProjectsFailed(""));
  };
};

export const addProjectSiteRoute = (projectId) => {
  return async (dispatch) => {
    dispatch(fetchProjectsStart());
    return addSiteRouteAPI(projectId).then((response) => {
      toastSuccess("Project added to Site Route Router");
      dispatch(addProjectSiteRouteSuccess(projectId));
    });
  };
};

export const removeProjectSiteRoute = (projectId) => {
  return async (dispatch) => {
    dispatch(fetchProjectsStart());
    return removeSiteRouteAPI(projectId).then((response) => {
      toastSuccess("Project removed from Site Route Router");
      dispatch(removeProjectSiteRouteSuccess(projectId));
    });
  };
};

export const removeAllProjectSiteRoute = (projectId) => {
  return async (dispatch) => {
    dispatch(fetchProjectsStart());
    return removeSiteRouteAPI(projectId).then((response) => {
      toastSuccess("All Projects removed from Site Route Router");
      dispatch(removeAllProjectSiteRouteSuccess());
    });
  };
};

export const updateSiteRouteNotes = (notes) => {
  return async (dispatch) => {
    dispatch(siteRouteNotesRequest());
    return updateSiteRouteNotesAPI(notes)
      .then((response) => {
        toastSuccess("Notes updated successfully");
        dispatch(siteRouteNotesSuccess(notes));
      })
      .catch((error) => {
        dispatch(siteRouteNotesFail(error));
      });
  };
};

export const updateContactLastDate = (date, contact_id) => {
  return async (dispatch) => {
    dispatch(contactLastDateRequest());
    return updateContactLastDateAPI({ date: date, contact_id: contact_id })
      .then((response) => {
        toastSuccess("Date updated successfully");
        dispatch(contactLastDateSuccess(date, response));
      })
      .catch((error) => {
        toastError("Unable to update date");
        dispatch(contactLastDateFail(date));
      });
  };
};

export const updateProjectContactLastDateByName = (date, opportunity_name) => {
  return async (dispatch) => {
    dispatch(contactLastDateRequest());
    return updateContactLastDateAPI({
      date: date,
      opportunity_name: opportunity_name,
    })
      .then((response) => {
        dispatch(contactLastDateSuccess(date, response));
      })
      .catch((error) => {
        dispatch(contactLastDateFail(date));
      });
  };
};

export const updateProjectContactLastDate = (date, opportunity_id) => {
  return async (dispatch) => {
    dispatch(projectContactLastDateRequest());
    return updateContactLastDateAPI({
      date: date,
      opportunity_id: opportunity_id,
    })
      .then((response) => {
        toastSuccess("Date updated successfully");
        dispatch(projectContactLastDateSuccess(date));
      })
      .catch((error) => {
        toastError("Unable to update date");
        dispatch(projectContactLastDateFail(date));
      });
  };
};

export const updateContactLastDateByName = (
  date,
  contact_email = '',
  contact_id = '',
  email_body
) => {
  return async (dispatch) => {
    dispatch(contactLastDateRequest());
    return updateContactLastDateAPI({
      date: date,
      contact_email: contact_email,
      contact_id: contact_id,
      email_body: email_body,
    })
      .then((response) => {
        dispatch(contactLastDateSuccess(date, response));
      })
      .catch((error) => {
        dispatch(contactLastDateFail(date));
      });
  };
};

export const addContactActivity = (date, contact_id, email_body, contact_type) => {
  return async (dispatch) => {
    dispatch(addContactActivityRequest());
    return addContactActivityAPI({date: date, contact_id: contact_id, body: email_body, contact_type: contact_type})
      .then((response) => {
        dispatch(addContactActivitySuccess(date, response));
    }).catch((error) => {
      dispatch(addContactActivityFail(error))
    });
  }
}

export const deleteContactActivity = (activty_id) => {
  return async (dispatch) => {
    dispatch(deleteContactActivityRequest());
    return deleteContactActivityAPI(activty_id)
      .then((response) => {
        dispatch(deleteContactActivitySuccess(activty_id));
      })
      .catch((error) => {
        dispatch(deleteContactActivityFail(error));
      });
  };
};

export const updateProjectNotes = (notes, project_id) => {
  return async (dispatch) => {
    dispatch(projectNotesRequest());
    return updateProjectNotesAPI(notes, project_id)
      .then((response) => {
        toastSuccess("Notes updated successfully");
        dispatch(projectNotesSuccess(notes));
      })
      .catch((error) => {
        toastSuccess("Unable to update notes");
        dispatch(projectNotesFail(notes));
      });
  };
};

export const updateProjectSequence = (sequence) => {
  return async (dispatch) => {
    dispatch({ type: USER_PROJECTS_SEQUENCE_REQUEST });
    return saveSequenceAPI(sequence)
      .then((response) => {
        dispatch({ type: USER_PROJECTS_SEQUENCE_SUCCESS, payload: sequence });
      })
      .catch((error) => {
        dispatch({ type: USER_PROJECTS_SEQUENCE_FAIL });
      });
  };
};

export const updateLikedProjects = (projectsLiked) => {
  return async (dispatch) => {
    dispatch(updateFavouriteProjectSuccess(projectsLiked));
  };
};

export const saveSearch = (data) => {
  return async (dispatch) => {
    dispatch(projectSaveSearchStart());
    saveSearchProjectAPI(data)
      .then(() => {
        toastSuccess("Search saved successfully");
        dispatch(projectSaveSearchSuccess(data));
      })
      .catch(() => {
        dispatch(projectSaveSearchFailed(""));
      });
  };
};

export const deleteSearch = (id, userId) => {
  return async (dispatch) => {
    dispatch(projectDeleteSearchStart());
    deleteSearchProjectAPI(id, userId)
      .then(() => {
        toastSuccess("Search deleted successfully");
        dispatch(projectDeleteSearchSuccess(""));
      })
      .catch(() => {
        toastError("Unable to delete search");
        dispatch(projectDeleteSearchFailed(""));
      });
  };
};

export const getEmailTemplate = (templateId, query) => {
  return async (dispatch) => {
    dispatch(getEmailTemplateStart());
    getEmailTemplateAPI(templateId, query)
      .then((response) => {
        dispatch(getEmailTemplateSuccess(response));
      })
      .catch((error) => {
        let message = "Please update your account to send Emails";
        let link = "/prequel-masterkey";
        let linkName = "view Company basic info";
        toastError(() => CustomToastWithLinkNewTab(message, link, linkName));
        dispatch(getEmailTemplateFailed(error?.data?.error));
      });
  };
};

export const projectVisit = (projectId) => {
  return async (dispatch) => {
    projectVisitAPI(projectId)
      .then(() => {})
      .catch(() => {});
  };
};

export const updateUserProjectBoardData =
  (opportunityId, data) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_USER_PROJECT_BOARD_DATA_REQUEST });

      let { access } = getState().auth;
      if (!access) {
        access = JSON.parse(localStorage.getItem("token"));
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/projects/user-project-board-data/${opportunityId}`,
        data,
        config
      );

      dispatch({
        type: UPDATE_USER_PROJECT_BOARD_DATA_SUCCESS,
        payload: response.data,
      });
      toastSuccess("Project data updated successfully.");
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message || "Failed to update project data."
          : error.message;
      dispatch({
        type: UPDATE_USER_PROJECT_BOARD_DATA_FAILURE,
        payload: message,
      });
      toastError("Failed to update project data.");
    }
  };
