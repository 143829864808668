import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./DealBoard.css";
import Tabs from "../../components/tabs/Tabs";
import {
  fetchSingleProject,
  getEmailTemplate,
  projectVisit,
  updateProjectContactLastDate,
  updateUserProjectBoardData,
} from "../../store/actions/projects/projects.action";
import ArrowBack from "../../assets/icons/ArrowBack";
import { saveCallLog } from "../../utils/requests/callLogs";
import {
  getDefaultProjectType,
  toastError,
  toastSuccess,
} from "../../utils/helpers/helper";
import {
  createPageView,
  getUserDetail,
} from "../../store/actions/users/users.actions";
import LoginModal from "../../components/LoginModal";

import { getContactRolesAPI } from "../../utils/requests/callLogs";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import styled from "styled-components";
import dayjs from "dayjs";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";
import { FaInfoCircle } from "react-icons/fa";
import { DateTag } from "../../components/DateTagProjectTable/DateTag";
import { StyledInput } from "../../components/StyledInputWithLoader";
import moment from "moment";
import { tabsData } from "../projectBoard/data/tabsData";
import Description from "../projectBoard/components/Description";
import DecisionMakers from "../projectBoard/components/DecisionMakers";
import ImportantDates from "../projectBoard/components/ImportantDates";
import Company from "../projectBoard/components/Company";
import Comments from "../projectBoard/components/Comments";
import {
  deleteDeal,
  shareStatusUpate,
} from "../../store/actions/deals/deals.action";

const StyledSwitch = styled(Form.Check)`
  .custom-toggle .form-check-input {
    // Increase the width to accommodate the text "Available" / "Not Available"
    width: 100px; // Adjust this width based on your actual needs
    height: 2rem; // Also adjust the height if necessary
    font-weight: bold;
    font-size: 0.8em;
    &:checked {
      background-color: #0d6efd; // Bootstrap primary color or any color you like
      &:after {
        content: "Public";
        color: white; // Text color when switch is active
        padding-left: 32px; // Adjust the padding to position the text correctly
      }
    }
    &:not(:checked) {
      background-color: #959595; // A gray color for the off state
      &:after {
        content: "Private";
        color: white; // Text color when switch is inactive
        padding-left: 25px; // Adjust the padding to position the text correctly
      }
    }
    // This will move the toggle's indicator to the opposite side
    &:checked:before {
      right: -20px; // Adjust this value as needed
    }
    &:not(:checked):before {
      left: -20px; // Adjust this value as needed
    }
  }
`;

const tagsHelperText = {
  "Updated this week": "New information added in the last 7 days",
  "New this week": "Created within last 7 days",
  "Plans Drawings": "Architectural plans available for download",
  "Large area": "Between 150,001 and 500,000 SQFT",
  "Extra large area": "Between 500,001 and 3,800,000 SQFT",
  "Medium area": "Between 31,001 and 150,000 SQFT",
  "Extra small area": "Between 1 and 4,000 SQFT",
  "Small area": "Between 4,001 and 31,000 SQFT",
  "Pre construction":
    "Two weeks before the pre-construction bid due date and two weeks after",
  "Breaking ground now":
    "Sixty days before and sixty days after the estimated ground breaking date",
  "Completing soon": "Sixty days prior to and following the completion date",
  "Under construction":
    "From 61 days post-groundbreaking to 61 days before completion, including erecting, installing, and assembling structural, mechanical, and architectural components.",
};

function toLocalISOString(date_str) {
  const date = new Date(date_str);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export const DealBoard = () => {
  const [activeTab, setActiveTab] = useState(tabsData[0]);
  const { user } = useSelector((state) => state.auth);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [contactCount, setContactCount] = useState(0);
  const [contactRoles, setContactRoles] = useState([]);
  const [contactActivities, setContactActivities] = useState([]);
  const [updatedLastVisit, setUpdatedLastVisit] = useState(false);
  const { hasFeatureAccess: cleaning } = useFeatureAccess("Cleanup Proposal");
  const { hasFeatureAccess: paint } = useFeatureAccess("Paint Proposal");
  const { hasFeatureAccess: site } = useFeatureAccess("Site Visit Sheet");

  const { projectSlug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    singleProject,
    status,
    loadingProjectDate,
    last_delete_activity,
    last_create_activity,
  } = useSelector((state) => state.projects);
  const [last_contact_date, setLastContactDate] = useState("");
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const bodyHight = document.body.clientHeight;
  const [userProjectBoardData, setUserProjectBoardData] = useState({
    dealValue: "",
    lastContactDate: "",
  });

  useCallback(() => {
    handleSetActiveTab();
  }, []);

  useEffect(() => {
    if (isTooltipVisible && bodyHight <= window.innerHeight) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isTooltipVisible, bodyHight]);

  useEffect(() => {
    let didUpdate = false;

    singleProject?.last_contact_date && singleProject.last_contact_date != null
      ? setLastContactDate(toLocalISOString(singleProject?.last_contact_date))
      : setLastContactDate(null);

    if (singleProject?.id && !updatedLastVisit && !didUpdate) {
      setUpdatedLastVisit(true);
      setTimeout(function () {
        dispatch(projectVisit(singleProject?.id));
      }, 500);
      didUpdate = true;
    }

    return () => {
      didUpdate = true;
    };
  }, [dispatch, singleProject?.id, singleProject?.last_contact_date, updatedLastVisit]);

  useEffect(() => {
    if (last_delete_activity)
      setContactActivities(
        contactActivities.filter((cnt) => cnt.id !== last_delete_activity)
      );
  }, [last_delete_activity, contactActivities]);

  useEffect(() => {
    if (last_create_activity) {
      const updatedActivities = [last_create_activity, ...contactActivities];
      updatedActivities.sort(
        (a, b) => new Date(b.contact_date) - new Date(a.contact_date)
      );
      setContactActivities(updatedActivities);
    }
  }, [last_create_activity]);

  const handleUpdateProjectLastDate = (date) => {
    setLastContactDate(date);
  };

  const handleSetActiveTab = (tabName) => {
    setActiveTab(tabName);
  };

  const getContactRoles = useCallback(() => {
    if (singleProject) {
      getContactRolesAPI(singleProject?.id)
        .then((response) => {
          setContactRoles(response?.info);
          setContactCount(response?.info?.length);
          setContactActivities(response?.activities);
        })
        .catch(() => {});
    }
    return;
  }, [singleProject]);

  const path = window.location.pathname.slice(1);
  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    dispatch(getUserDetail());
  }, [dispatch, user, navigate]);

  useEffect(() => {
    const pageRecord = singleProject?.name;

    const type = "Deal";

    if (pageRecord) {
      dispatch(createPageView(pageRecord, path, type));
      setUpdatedLastVisit(false);
    }
  }, [dispatch, singleProject?.name, path]);

  useEffect(() => {
    getContactRoles();
    return function cleanup() {
      // setContactRoles([]);
      setContactCount(0);
    };
  }, [singleProject, getContactRoles]);

  // Format date for datetime-local input
  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16); // Return in YYYY-MM-DDTHH:mm format
  };

  useEffect(() => {
    dispatch(fetchSingleProject(projectSlug));

    setUserProjectBoardData({
      deal_value: singleProject?.deal_value || "",
      next_communication_date: formatDateForInput(
        singleProject?.next_communication_date
      ),
    });

  }, [dispatch, projectSlug, singleProject?.id, singleProject?.deal_value, singleProject?.next_communication_date]);

  const handleCallLogs = (type, contactId, closeModal) => {
    const data = {
      activity: type,
      contact_role_id: contactId,
    };
    saveCallLog(singleProject?.id, data)
      .then(() => {
        closeModal(false);
        toastSuccess("Call recorded successfully");
      })
      .catch(() => {});

    return;
  };

  const handleProjectStatusUpdate = (e) => {
    const bidDueDate = new Date(singleProject?.bid_due_date);
    const currentDate = new Date();
    bidDueDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const diffInMs = bidDueDate - currentDate;
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    if ((e.target.checked && contactRoles.length) && (diffInDays >= -14)) {
      dispatch(shareStatusUpate(singleProject?.id, e.target.checked));
    } else if (!(diffInDays >= -14)) {
      toastError("Bid due date must be last 14 days");
    } else {
      toastError("Deal must have a contact to share it publicly");
    }
  };

  const selectedTemplateHandler = (templateId, contact) => {
    const { company_id, name } = contact;
    const query = `?project_id=${singleProject?.id}&company_id=${company_id}&contact_name=${name}`;
    dispatch(getEmailTemplate(templateId, query));
  };

  const openCleaningEstimateWindow = () => {
    const width = 1200; // Width of the new window
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;

    const { sf_size, state, project_type, name, units, url_slug } =
      singleProject || {};
    const url = `/cleanup_calculator?opportunity_id=${
      singleProject?.id
    }&square_foot=${sf_size || ""}&state=${state || ""}&project_type=${
      getDefaultProjectType(project_type || "")
    }&project_name=${
      name.replaceAll("#", "%23").replaceAll("&", "%26") || ""
    }&living_unit=${
      units || ""
    }&url_slug=${url_slug}&davis=${singleProject?.tags.includes(
      "Davis"
    )}&union=${singleProject?.tags?.includes("Union requirements")}`;
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left},menubar=no,toolbar=no,location=no,status=no,scrollbars=yes`;

    window.open(url, "_blank", windowFeatures);
  };

  const openPaintingProposalWindow = () => {
    const width = 1200; // Width of the new window
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;

    const { state, name, url_slug } =
      singleProject || {};
    const url = `/paint_proposal/?project_name=${name
      .replaceAll("#", "%23")
      .replaceAll(
        "&",
        "%26"
      )}&state=${state}&url_slug=${url_slug}&davis=${singleProject?.tags.includes(
      "Davis"
    )}&union=${singleProject?.tags?.includes("Union requirements")}`;
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left},menubar=no,toolbar=no,location=no,status=no,scrollbars=yes`;

    window.open(url, "_blank", windowFeatures);
  };

  const openSiteVisitWindow = () => {
    const width = 1200; // Width of the new window
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;

    const {
      name,
      address,
      city,
      state,
      account_name,
      sf_size,
      units,
      site_contact_name,
      site_contact_phone
    } = singleProject;

    const project_address =
      (address || address != null ? address : "") +
      (city ? ", " + city : "") +
      (state ? ", " + state : "");
    const url = `/site_visit?project_name=${encodeURIComponent(
      name
    )}&project_address=${encodeURIComponent(
      project_address
    )}&company=${encodeURIComponent(
      account_name != null ? account_name : ""
    )}&superintendent=${encodeURIComponent(
      (site_contact_name != null ? site_contact_name : "") +
        (site_contact_phone
          ? ` (${site_contact_phone.replaceAll("(", "").replaceAll(")", "")})`
          : "") || ""
    )}&project_manager=${encodeURIComponent("")}&square_foot=${encodeURIComponent(sf_size || "")}&units=${
      units || ""
    }&disabled=true`;

    const windowFeatures = `width=${width},height=${height},top=${top},left=${left},menubar=no,toolbar=no,location=no,status=no,scrollbars=yes`;

    window.open(url, "_blank", windowFeatures);
  };

  const completionBefore = dayjs().isBefore(
    dayjs(singleProject?.project_completion)
  );
  const workProgressBefore = dayjs().isBefore(
    dayjs(singleProject?.est_break_ground_date)
  );
  const bidDueDateBefore = dayjs().isBefore(
    dayjs(singleProject?.bid_due_date)
  );

  const completionDuration = completionBefore
    ? `complete ${dayjs(singleProject?.project_completion).fromNow()}`
    : `completed ${dayjs(singleProject?.project_completion).fromNow()}`;

  const workProgressDuration = workProgressBefore
    ? `start ${dayjs(singleProject?.est_break_ground_date).fromNow()}`
    : `started ${dayjs(singleProject?.est_break_ground_date).fromNow()}`;

  const bidDueDateDuration = bidDueDateBefore
    ? `Bid due date ${dayjs(singleProject?.bid_due_date).fromNow()}`
    : `Bid due date ${dayjs(singleProject?.bid_due_date).fromNow()}`;

  // const ganarpro_app = localStorage.getItem(`ganarpro_app${user?.id}`);

  const handleDateChange = (e) => {
    const date = e.target.value;
    const today = date ? new Date(date).toISOString() : null;
    setLastContactDate(date ? toLocalISOString(today) : null);
  };

  const handleDateBlur = (e) => {
    dispatch(
      updateProjectContactLastDate(
        last_contact_date
          ? new Date(last_contact_date).toISOString().slice(0, 16)
          : null,
        singleProject?.id
      )
    );
  };

  const handleUserProjectBoardDataChange = (e) => {
    const { name, value } = e.target;
    setUserProjectBoardData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUserProjectBoardDataBlur = (e) => {
    const { name, value } = e.target;
    let data = {};
    if (user) {
      if (name === "deal_value" && value !== "") {
        data.deal_value = value ? parseFloat(value) : null;
      } else if (name === "next_communication_date" && value !== "") {
        data.next_communication_date = value || null;
      }
      if (value !== "") {
        dispatch(updateUserProjectBoardData(singleProject?.id, data));
      }
    }
  };

  const handleDelete = () => {
    dispatch(deleteDeal(singleProject?.id));
  };

  const convertDate = (date) => {
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.toLocaleString());
    const formattedDate = localDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
    return formattedDate
  }

  return (
    <div>
      <Row className="">
        <Col md={10}>
          <Row className="justify-content-around">
            <Col md={3} className="col-6">
              <p className=" mt-2">
                {singleProject?.bid_due_date ? (
                  <>
                    <br />
                    <span className="fw-bolder text-primary">
                      {bidDueDateDuration}
                    </span>{" "}
                  </>
                ) : (
                  ""
                )}
              </p>
            </Col>
            <Col md={3} className="col-6">
              <p className=" mt-2">
                {singleProject?.est_break_ground_date ? (
                  <>
                    <br />
                    <span className="fw-bolder text-primary">
                      {workProgressDuration}
                    </span>{" "}
                  </>
                ) : (
                  ""
                )}
              </p>
            </Col>
            <Col md={3} className="col-6">
              <p className="mt-2">
                {singleProject?.project_completion ? (
                  <>
                    <br />
                    <span className="fw-bolder text-primary">
                      {completionDuration}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="row project-board-row">
        <div className="d-none d-md-block col-sm-1"></div>
        <div className="col-xs-12 col-md-10 px-md-4 mb-md-5">
          <section className="mt-4 mx-3">
            <div className="row d-flex">
              <div className="d-flex col-sm-12 col-lg-7 justify-content-center justify-content-md-start mt-3">
                <Link
                  to=""
                  className="text-primary text-decoration-none align-self-center me-4"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBack
                    fill="#276FB4"
                    git
                    width="19"
                    height="16"
                    className="me-3"
                  />
                  <div className="d-none d-md-block">Back to Deals</div>
                </Link>
                <div className="d-sm-flex text-md-start d-md-grid col-xs-12 col-md-8 justify-content-sm-center justify-content-md-start mt-4 mb-3">
                  <h4 className="fw-bold heading-3">{singleProject?.name}</h4>
                  <h6 className="body-text">
                    by{" "}
                    {singleProject?.last_account_date ? (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip className="tooltip">
                            {singleProject?.last_account_date
                              ? "Contact made " +
                                moment
                                  .utc(singleProject?.last_account_date)
                                  .local()
                                  .fromNow()
                              : "Never made contact"}
                          </Tooltip>
                        }
                      >
                        <span
                          className="text-primary"
                          onMouseEnter={() => setIsTooltipVisible(true)}
                          onMouseLeave={() => setIsTooltipVisible(false)}
                        >
                          {singleProject?.account_name}
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <span
                        className="text-primary"
                        onMouseEnter={() => setIsTooltipVisible(true)}
                        onMouseLeave={() => setIsTooltipVisible(false)}
                      >
                        {singleProject?.account_name}
                      </span>
                    )}
                  </h6>
                  <h6 className="body-text">
                    Created by: {singleProject?.deal_owner?.deal_name ? singleProject?.deal_owner?.deal_name : singleProject?.deal_owner?.first_name + " " + singleProject?.deal_owner?.last_name}
                  </h6>
                </div>
              </div>
              <div className="d-flex flex-column col-sm-12 col-lg-5 gap-2 text-nowrap justify-content-center">
                {singleProject?.last_visit_date && (
                  <div className="d-flex align-items-center justify-content-center justify-content-md-end gap-2">
                    <div>Last Visit</div>
                    <StyledInput
                      type="datetime-local"
                      name="today_date"
                      disabled
                      style={{
                        width: "max-content",
                        padding: "8px",
                        backgroundColor: "white",
                        border: "none",
                      }}
                      value={toLocalISOString(singleProject?.last_visit_date)}
                    />
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-center justify-content-md-end gap-2">
                  <div>Last Contact</div>
                  <StyledInput
                    type="datetime-local"
                    name="today_date"
                    onChange={handleDateChange}
                    onBlur={handleDateBlur}
                    style={{ width: "max-content", padding: "8px" }}
                    value={last_contact_date}
                    loading={loadingProjectDate}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center justify-content-md-end gap-2">
                  <div>Next Follow-Up</div>
                  <StyledInput
                    type="datetime-local"
                    name="next_communication_date"
                    onChange={handleUserProjectBoardDataChange}
                    onBlur={handleUserProjectBoardDataBlur}
                    style={{ width: "max-content", padding: "8px" }}
                    value={userProjectBoardData?.next_communication_date}
                  />
                </div>
              </div>
              <Row className="my-4">
                <Col sm={12} md={6}>
                  <div className="d-flex flex-wrap mt-2">
                    {singleProject?.tags
                      .split(",")
                      .filter((tag) => tag.trim() !== "")
                      .map((tag) => (
                        <div
                          className="p-2 m-1"
                          style={{
                            background: "#70B2F0",
                            borderRadius: "10px",
                          }}
                        >
                          {tag === "Union requirements" ||
                          tag === "Davis Bacon" ? (
                            <div>{tag}</div>
                          ) : (
                            tag.trim() !== "" && (
                              <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                overlay={
                                  <Tooltip className="tooltip">
                                    {tagsHelperText[tag]}
                                  </Tooltip>
                                }
                              >
                                <div>{tag}</div>
                              </OverlayTrigger>
                            )
                          )}
                        </div>
                      ))}
                  </div>
                  <div
                    className="d-flex flex-row align-content-center justify-content-end"
                    style={{ gap: "5px" }}
                  >
                    {singleProject?.estimate_id && (
                      <DateTag
                        name="Estimate. "
                        dateTime={singleProject.estimate_date}
                        color="#249d5c"
                        onClick={() =>
                          navigate(
                            "/cleanup_calculator/edit/" +
                              singleProject.estimate_id
                          )
                        }
                      />
                    )}
                    {singleProject?.proposal_id && (
                      <DateTag
                        name="Proposal. "
                        dateTime={singleProject.proposal_date}
                        color="#58a5ec"
                        onClick={() =>
                          navigate(
                            "/my_proposal/edit/" + singleProject.proposal_id
                          )
                        }
                      />
                    )}
                  </div>
                </Col>
                <Col sm={12} md={6} className="mt-4">
                  <Row className="mb-4">
                    {singleProject?.deal_owner?.id === user?.id ?
                      <Col className="d-flex justify-content-end me-5">
                        <div className="d-flex">
                          <StyledSwitch>
                            <Form.Check
                              type="switch"
                              id="availability-switch"
                              className="custom-toggle ms-3 fw-bolder"
                              checked={status}
                              disabled={status}
                              onChange={(e) => handleProjectStatusUpdate(e)}
                            />
                          </StyledSwitch>
                        </div>
                      </Col>
                    : <p className="fs-4 text-end fw-bold">Add to pipeline {convertDate(singleProject?.created)}</p>
                    }
                  </Row>
                  <Row className="mb-2">
                    <Col className="d-flex justify-content-end gap-4">
                      <Link
                        className="btn btn-outline-primary fs-5"
                        to={`/deal/edit/${singleProject?.id}`}
                      >
                        Edit Deal
                      </Link>
                      <button
                        className="btn btn-outline-danger fs-5"
                        onClick={handleDelete}
                      >
                        Delete Deal
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </section>
          <Row className="justify-content-end mx-2">
            <Col className="col-md-9 text-end">
              <input
                type="number"
                name="deal_value"
                id="dealValue"
                value={userProjectBoardData.deal_value}
                onChange={handleUserProjectBoardDataChange}
                onBlur={handleUserProjectBoardDataBlur}
                placeholder="Enter deal value"
              />
              {user?.subscriptions?.length > 0 && site ? (
                <Button
                  className="p-3 m-2 btn btn-light"
                  style={{ backgroundColor: "#ebebeb" }}
                  onClick={openSiteVisitWindow}
                >
                  <span className="h4 rounded">Create Site Visit Sheet</span>
                </Button>
              ) : (
                ""
              )}
              {user?.subscriptions?.length > 0 && cleaning ? (
                <>
                  <Button
                    className="p-3 btn btn-light"
                    style={{ backgroundColor: "#ebebeb" }}
                    onClick={openCleaningEstimateWindow}
                  >
                    <span className="h4 rounded">Create Cleaning Proposal</span>
                  </Button>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <ol style={{ textAlign: "start" }}>
                          <li>Calculate a bid amount</li>
                          <li>From Calculator, create a Proposal</li>
                          <li>Download word document Proposal</li>
                          <li>
                            Send proposal directly from your email or through
                            Ganarpro
                          </li>
                        </ol>
                      </Tooltip>
                    }
                  >
                    <span className="info-span">
                      <FaInfoCircle />
                    </span>
                  </OverlayTrigger>
                </>
              ) : user?.subscriptions?.length > 0 && paint ? (
                <>
                  <Button
                    className="p-3 btn btn-light"
                    style={{ backgroundColor: "#ebebeb" }}
                    onClick={openPaintingProposalWindow}
                  >
                    <span className="h4">Create Painting Proposal</span>
                  </Button>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <section className="mt-3 mb-5">
            <div className="box-shadow-gray mx-2 px-5 py-5 mb-md-5 bg-white rounded">
              <div className="d-flex justify-content-between overflow-auto mb-5">
                {tabsData.map((tab, index) => (
                  <Tabs
                    key={index + 1}
                    setActiveTab={handleSetActiveTab}
                    name={tab}
                    title={tab}
                    activeTab={activeTab}
                    contactCount={contactCount}
                  />
                ))}
              </div>
              <div className="tab-content">
                {activeTab === tabsData[0] ? (
                  <div className="tab-content">
                    <Description {...singleProject} />
                  </div>
                ) : (
                  ""
                )}
                {activeTab === tabsData[1] ? (
                  <div className="tab-content">
                    <DecisionMakers
                      contacts={singleProject?.contact_roles}
                      callLogHandler={handleCallLogs}
                      emailTemplates={singleProject?.email_templates}
                      projectId={singleProject?.id}
                      handleSelectedTemplate={selectedTemplateHandler}
                      setContactCount={setContactCount}
                      contactRoles={contactRoles}
                      contactActivities={contactActivities}
                      getContactRoles={getContactRoles}
                      projectName={singleProject?.name}
                      account_phone={singleProject?.account_phone}
                      updateProjectLastDate={handleUpdateProjectLastDate}
                    />
                  </div>
                ) : (
                  ""
                )}
                {activeTab === tabsData[2] ? (
                  <div className="tab-content">
                    <ImportantDates {...singleProject} />
                  </div>
                ) : (
                  ""
                )}
                {activeTab === tabsData[3] ? (
                  <div className="tab-content">
                    <Company {...singleProject} />
                  </div>
                ) : (
                  ""
                )}
                {activeTab === tabsData[4] ? (
                  <div className="tab-content">
                    <Comments projectId={singleProject?.id} {...singleProject}/>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        closeButton={false}
      />
    </div>
  );
};
