import { CRM_CREATE_FAIL, CRM_CREATE_REQUEST, CRM_CREATE_SUCCESS, CRM_DELETE_FAIL, CRM_DELETE_REQUEST, CRM_DELETE_SUCCESS, CRM_LIST_FAIL, CRM_LIST_REQUEST, CRM_LIST_SUCCESS, CRM_UPDATE_FAIL, CRM_UPDATE_REQUEST, CRM_UPDATE_SUCCESS } from "../../constants/crmConstants";

const initialValues = {
  contacts: {
    previous: null,
    next: null,
    count: 0,
    results: [],
  },
  loading: false,
  error: null
}

export const CrmActionReducer = (
  state = initialValues,
  action
) => {
  switch (action.type) {
    case CRM_CREATE_REQUEST:
    case CRM_UPDATE_REQUEST:
    case CRM_DELETE_REQUEST:
      return { ...state, loading: true };

    case CRM_CREATE_SUCCESS:
      // case DEAL_UPDATE_SUCCESS:
      // case DEAL_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        contacts: {
          ...state.contacts,
          results: [...state.contacts.results, action.payload]
        }
      };

    case CRM_DELETE_SUCCESS:
      // case CRM_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        contacts: {
          ...state.contacts,
          results: state.contacts.results.filter(contact => contact.id !== action.payload)
        }
      };
    
    case CRM_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        contacts: {
          ...state.contacts,
          results: state.contacts.results.map(contact => contact.id === action.payload.id? action.payload : contact)
        }
      };

    case CRM_CREATE_FAIL:
    case CRM_UPDATE_FAIL:
    case CRM_DELETE_FAIL:
      return { ...state, loading: false, error: action.payload };

    // case DEAL_FETCH_REQUEST:
    //   return { ...state, loading: true };
    // case DEAL_FETCH_SUCCESS:
    // case DEAL_FETCH_FAIL:
    //   return { ...state, loading: false };

    case CRM_LIST_REQUEST:
      return { ...state, loading: true };
    case CRM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: action.payload
      };
    case CRM_LIST_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
