import React, { useState } from "react";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import Modal from "../modal/Modal";
import EmailIcon from "../../assets/icons/EmailIcon";
import LogForm from "../forms/logCall/LogForm";
import Button from "../button/Button";
import "./DecisionMakerCard.css";
import { useSelector } from "react-redux";
import { StyledInput } from "../StyledInputWithLoader";
import { updateContactLastDate, updateContactLastDateByName, updateProjectContactLastDate } from "../../store/actions/projects/projects.action";
import { useDispatch } from "react-redux";
import EmailModal from "../emailModal";
import ActivityModal from "../activityModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTasks } from "@fortawesome/free-solid-svg-icons";
import AddActivityModal from "../activityModal/addActivity";

const sortItems = [
  {
    id: 1,
    key: "last_modified_date",
    value: "updated",
  },
  {
    id: 2,
    key: "created_date",
    value: "newest",
  },
  {
    id: 3,
    key: "bid_due_date",
    value: "Bid due date",
  },
  {
    id: 4,
    key: "sf_size",
    value: "Square Feet",
  },
];

function toLocalISOString(date_str) {
  const date = new Date(date_str);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

const DecisionMakerCard = ({
  contact,
  emailTemplates,
  templateError,
  emailTemplateBody,
  handleSelectedTemplate,
  callLogHandler,
  handleEmailContent,
  projectName,
  projectId,
  profile,
  updateProjectLastDate,
  contactActivities
}) => {
  // const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [activityModalShow, setActivityModalShow] = useState(false);
  const [addActivityModalShow, setAddActivityModalShow] = useState(false);
  const [showLogCallModal, setShowLogCallModal] = useState(false);
  const [selectedSortItem, setSelectedSortItem] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const { loadingDate } = useSelector((state) => state.projects);
  const [last_date, setLastDate] = useState(contact.contact_date && toLocalISOString(contact.contact_date));
  const dispatch = useDispatch();

  const handleSelectedSortItem = (itemId) => {
    const item = sortItems.find((item) => item.id === itemId);
    setSelectedSortItem(item.value);
  };

  const selectedTemplateHandler = (templateId) => {
    const template = emailTemplates.find(
      (template) => template.id === templateId
    );
    setSelectedTemplate(template);
    handleSelectedTemplate(template.id, contact);
  };

  // const openEmailModalHandler = () => {
  //   setShowEmailModal(true);
  // };

  // const closeEmailModalHandler = () => {
  //   if (emailTemplateBody) {
  //     emailTemplateBody.subject = "";
  //     emailTemplateBody.text = "";
  //     setSelectedTemplate("");
  //   }
  //   setShowEmailModal(false);
  // };

  const sendEmail = (e, email) => {
    e.preventDefault();
    const subject = projectName;
    const emailBody = "Hi " + contact?.name.split(" ")[0] + ",";
    window.location.href =
      "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    const today = date ? new Date(date).toISOString() : null;
    setLastDate(date ? toLocalISOString(today) : null);
    dispatch(updateContactLastDate(date ? today.slice(0, 16) : null, contact.contact_id));
  };

  const handleOpenEmail = (contact) => {
    setEmailModalShow(true);
  }

  const convertHtml = (html) => {
    return html
      ?.replaceAll("&nbsp;", " ")
      ?.replaceAll("<div><div>", "\n")
      ?.replaceAll("<div>", "")
      ?.replaceAll("</div><br>", "\n")
      ?.replaceAll("</div>", "\n")
      ?.replaceAll("<br>", "\n")
      ?.replaceAll("<br/>", "\n")
      ?.replaceAll("<br />", "\n")
      ?.replaceAll("&#39;", "'")
      ?.replaceAll("<p>", "")
      ?.replaceAll("</p>", "");
  };

  const handleSendEmail = (appName, email_body) => {

    const recipient = contact?.email
    const recipientName = contact.name
    const greeting = `Hi ${recipientName.split(" ")[0]},`;

    const subject = `Interested in Bidding on ${projectName}`;
    const message = convertHtml(email_body);
    const body = `${greeting}\n\n${message}\n\n${profile?.proposal_point_contact_name}\n${profile?.proposal_point_contact_phone}\n${profile?.proposal_point_contact_email}\n\n${profile?.company_name && profile?.company_name + '\n'}${profile?.company_street && profile?.company_street + '\n'}${profile?.company_city ? profile?.company_city + ', ' : ''}${profile?.company_state} ${profile?.company_zip}`;
    const simple_body = `${greeting}\n\n${message}\n\n`;
    // Mailto link for default mail client
    const defaultMailLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Gmail link
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipient}&su=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Outlook link
    const outlookLink = `https://outlook.office.com/owa/?path=/mail/action/compose&to=${recipient}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(simple_body)}`;

    // Yahoo Mail link
    const yahooLink = `https://compose.mail.yahoo.com/?to=${recipient}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(simple_body)}`;

    switch (appName) {
      case 'gmail':
        window.open(gmailLink, '_blank');
        break;
      case 'yahoo':
        window.open(yahooLink, '_blank');
        break;
      case 'outlook':
        window.open(outlookLink, '_blank');
        break;
      case 'default':
        window.open(defaultMailLink, '_blank');
        break;
      default:
        window.open(defaultMailLink, '_blank');
        break;
    }

    const today = new Date().toISOString();
    setLastDate(toLocalISOString(today));
    updateProjectLastDate(toLocalISOString(today));
    dispatch(updateContactLastDateByName(today.slice(0, 16), '', contact?.contact_id, email_body));
    dispatch(updateProjectContactLastDate(today.slice(0, 16), projectId));
  }

  return (
    <div className="px-2 border border-gray py-4 mt-3 col-md-5 mx-md-3 px-lg-4">
      <div className="d-flex justify-content-between">
        <div className="text-start">
          <h6 className="text-16 fw-bold black-100">{contact.name}</h6>
          <h6 className="black-200 fw-light text-13">
            {contact.title
              ? contact.title === "Other"
                ? ""
                : contact.title
              : ""}
          </h6>
        </div>
        {/*<FavouriteIcon*/}
        {/*  width="20"*/}
        {/*  height="19"*/}
        {/*  fill="#276FB4"*/}
        {/*  className="me-lg-5"*/}
        {/*/>*/}
      </div>
      <div className="my-3">
        <div className="d-flex align-items-center">
          <EmailIcon width="22" height="18" fill="#CCCCCC" className="me-3" />
          <h6
            className="black-200 fw-light text-13 m-0 text-truncate text-primary cursor-pointer"
            onClick={(e) => sendEmail(e, contact.email)}
          >
            {contact.email}
          </h6>
        </div>
      </div>
      <div className="d-flex my-3 align-items-center flex-md-column flex-lg-column flex-xl-row gap-3">
        <div className="d-flex gap-3 align-items-center flex-md-column flex-lg-column flex-xl-row">
          <div>Last Contact</div>
          {loadingDate ? (
            <div>Saving...</div>
          ) : (
            <StyledInput
              type="datetime-local"
              name="today_date"
              onChange={handleDateChange}
              style={{
                paddingTop: "12px",
                paddingBottom: "12px",
                width: "max-content",
              }}
              value={last_date}
            />
          )}
        </div>
        <Button
          customClassName="btn-primary"
          style={{ fontSize: "12px", height: "auto" }}
          onClick={() => handleOpenEmail(contact)}
        >
          Send Email
        </Button>
      </div>
      {/*<div className="text-13 mt-5 border-top border-bottom py-5">*/}
      {/*  <div className="d-flex justify-content-between mb-3">*/}
      {/*    <h6 className="text-13 fw-bold black-100">*/}
      {/*      Activities on this project*/}
      {/*    </h6>*/}
      {/*    <h6 className="black-200 fw-light text-13">*/}
      {/*      {contact.user_project_activities}*/}
      {/*    </h6>*/}
      {/*  </div>*/}
      {/*  <div className="d-flex justify-content-between mb-3">*/}
      {/*    <h6 className="text-13 fw-bold black-100">Last Date</h6>*/}
      {/*    <h6 className="black-200 fw-light text-13">*/}
      {/*      {contact.last_date &&*/}
      {/*        new Date(contact.last_date).toLocaleDateString("en-US", {*/}
      {/*          year: "numeric",*/}
      {/*          month: "short",*/}
      {/*          day: "numeric",*/}
      {/*        })}*/}
      {/*    </h6>*/}
      {/*  </div>*/}
      {/*  <div className="d-flex justify-content-between mb-3">*/}
      {/*    <h6 className="text-13 fw-bold black-100">Total System Activities</h6>*/}
      {/*    <h6 className="black-200 fw-light text-13">*/}
      {/*      {contact.user_total_system_activities}*/}
      {/*    </h6>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="justify-content-between mt-5 contact-phone-buttons">
        <Button
          onClick={() => setShowLogCallModal(true)}
          customClassName="btn btn-outline-secondary-intel btn-small text-10 text-truncate contact-phone-button-1"
        >
          <PhoneIcon
            fill="currentColor"
            width="18"
            height="18"
            className="me-2"
          />
          View Phone Details
        </Button>
        <Button
          onClick={() => setAddActivityModalShow(true)}
          customClassName="btn btn-outline-secondary-intel btn-small text-10 text-truncate contact-phone-button-1"
        >
          <FontAwesomeIcon
            icon={faTasks}
            size="xl"
          />
          {" "}Log Activity
        </Button>
        {contactActivities.some(cont => cont.contact_id == contact.contact_id) &&
          <>

            <Button
              onClick={() => setActivityModalShow(true)}
              customClassName="btn btn-outline-secondary-intel btn-small text-10 text-truncate contact-phone-button-1"
            >
              <EmailIcon
                fill="currentColor"
                width="18"
                height="18"
                className="me-2"
              />
              View Activity
            </Button>
          </>
        }
        <Modal
          title={`${contact.name}’s Phone Details`}
          show={showLogCallModal}
          closeOnOutsideClick={true}
          onCloseModal={() => setShowLogCallModal(false)}
        >
          <LogForm
            phone={contact.phone}
            contactId={contact.id}
            selectedSortItem={selectedSortItem}
            sortItems={sortItems}
            handleSelectedSortItem={handleSelectedSortItem}
            setShowLogCallModal={setShowLogCallModal}
            callLogHandler={callLogHandler}
          />
        </Modal>
        <ActivityModal
          title={`${contact.name}’s Activity`}
          show={activityModalShow}
          closeOnOutsideClick={true}
          activities={contactActivities.filter(cont => cont.contact_id == contact.contact_id)}
          onCloseModal={() => setActivityModalShow(false)}
          onHide={()=> setActivityModalShow(false)}
        />
        <AddActivityModal
          title={`${contact.name}’s Activity`}
          show={addActivityModalShow}
          closeOnOutsideClick={true}
          email={contact.email}
          contact={contact}
          onCloseModal={() => setAddActivityModalShow(false)}
          onHide={()=> setAddActivityModalShow(false)}
        />
        <EmailModal
          app="project_board"
          projectName={projectName}
          show={emailModalShow}
          closeOnOutsideClick={true}
          onHide={() => setEmailModalShow(false)}
          onCloseModal={() => setEmailModalShow(false)}
          onChange={handleSendEmail}
        />
        {/*<Button*/}
        {/*  onClick={openEmailModalHandler}*/}
        {/*  customClassName="text-10 btn btn-primary btn-small contact-phone-button-2"*/}
        {/*>*/}
        {/*  Send Email*/}
        {/*</Button>*/}
        {/*<Modal*/}
        {/*  title={`New message to ${contact.name}’s`}*/}
        {/*  show={showEmailModal}*/}
        {/*  onCloseModal={closeEmailModalHandler}*/}
        {/*>*/}
        {/*  <SendEmailForm*/}
        {/*    selectedTemplate={selectedTemplate}*/}
        {/*    templateError={templateError}*/}
        {/*    emailTemplates={emailTemplates}*/}
        {/*    emailTemplateBody={emailTemplateBody}*/}
        {/*    contact={contact}*/}
        {/*    handleSelectedTemplate={selectedTemplateHandler}*/}
        {/*    handleSendEmail={handleSendEmail}*/}
        {/*    handleCloseEmailModal={closeEmailModalHandler}*/}
        {/*  />*/}
        {/*</Modal>*/}
      </div>
    </div>
  );
};

export default DecisionMakerCard;
