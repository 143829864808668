import { toastError, toastSuccess } from "../../../utils/helpers/helper";
import { createCrmContactAPI, deleteCrmContactAPI, getCrmContactsAPI, updateCrmContactAPI } from "../../../utils/requests/crm";
import { CRM_CREATE_FAIL, CRM_CREATE_REQUEST, CRM_CREATE_SUCCESS, CRM_DELETE_FAIL, CRM_DELETE_REQUEST, CRM_DELETE_SUCCESS, CRM_LIST_FAIL, CRM_LIST_REQUEST, CRM_LIST_SUCCESS, CRM_UPDATE_FAIL, CRM_UPDATE_REQUEST, CRM_UPDATE_SUCCESS } from "../../constants/crmConstants";

export const getCrmContacts = (query = "") => {
  return async (dispatch) => {
    dispatch({ type: CRM_LIST_REQUEST });
    return getCrmContactsAPI(query)
      .then((response) => {
        dispatch({ type: CRM_LIST_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({
          type: CRM_LIST_FAIL,
          error: error,
        });
        toastError("Unable to fetch Crm Contacts");
      });
  };
};

export const saveCrmContacts = (data) => {
  return async (dispatch) => {
    dispatch({ type: CRM_CREATE_REQUEST });
    return createCrmContactAPI(data)
      .then((response) => {
        dispatch({ type: CRM_CREATE_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({
          type: CRM_CREATE_FAIL,
          error: error,
        });
        toastError("Unable to save contact");
      });
  };
};

export const updateCrmContact = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: CRM_UPDATE_REQUEST });
    return updateCrmContactAPI(id, data)
      .then((response) => {
        dispatch({ type: CRM_UPDATE_SUCCESS, payload: response });
        toastSuccess("Contact updated successfully");
      })
      .catch((error) => {
        dispatch({
          type: CRM_UPDATE_FAIL,
          error: error,
        });
        toastError("Unable to save contact");
      });
  };
};

export const deleteCrmContact = (contactId) => {
  return async (dispatch) => {
    dispatch({ type: CRM_DELETE_REQUEST });
    return deleteCrmContactAPI(contactId)
      .then((response) => {
        dispatch({ type: CRM_DELETE_SUCCESS, payload: contactId });
        toastSuccess("Contact deleted successfully");
      })
      .catch((error) => {
        dispatch({
          type: CRM_DELETE_FAIL,
          error: error,
        });
        toastError("Unable to delete contact");
      });
  };
};