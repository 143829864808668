import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Tabs,
} from "react-bootstrap";
import LoginModal from "../../components/LoginModal";
import { useDispatch, useSelector } from "react-redux";
import useIsMobile from "../../utils/helpers/use-is-mobile";
import { useNavigate } from "react-router";
import {
  addPageFavorite,
  createPageView,
  fetchPageFavorite,
  removePageFavorite,
} from "../../store/actions/users/users.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { getProjectTypePricingList, getStateLaborPricingList } from "../../store/actions/mortgage_calculator/mortgage_calculator.actions";
import {
  addDeal,
  getDeals,
} from "../../store/actions/deals/deals.action";
import Spinner from "../../components/spinner/Spinner";
import ProjectDataCard from "../projectBoardList/components/ProjectDataCard";
import "./dealsDataCard.css";
import PricingModal from "../../components/pricingModal";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import { Link } from "react-router-dom";


const DealMarketplace = () => {
  const path = global.window.location.pathname.slice(1);

  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState(1);
  const [pricingModalShow, setPricingModelShow] = useState(false);
  const [states, setStates] = useState(JSON.parse(localStorage.getItem("deals_states"), []));

  const { user } = useSelector((state) => state.auth);
  const { user: profile, loading: profileLoading } = useSelector((state) => state.userDetails);
  const { stateLabors } = useSelector((state) => state.stateLaborPricingList);
  const { projectTypePricing } = useSelector(
    (state) => state.projectTypePricingList
  );
  const { loading, deals } = useSelector((state) => state.dealActions);
  const { isFavorite, loading: fetchLoader } = useSelector(
    (state) => state.fetchPageFavorite
  );
  
  const dispatch = useDispatch();
  
  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removePageFavorite({ page_name: "Deal Marketplace" }));
    } else {
      dispatch(
        addPageFavorite({ page_name: "Deal Marketplace", page_url: path })
      );
    }
  };

  const handleProjectDetailCLick = (e, value) => {
    if (!user) {
      setModalShow(true);
      return;
    }
    let deal = deals?.results?.find(deal => deal.id == value);
    let deal_owner = deal.user.id === user?.id;
    let deal_purchaser = deal?.deal_purchasers?.find(d => d?.user__profile === user?.id);
    console.log(value, deal, deal_owner, deal_purchaser);
    if (deal_owner || deal_purchaser) {
      if (deal_owner)
        window.location.href = `/deal/${deal.url_slug}`;
      else
        window.location.href = `/deal/${deal_purchaser.url_slug}`;

    } else if (profile?.deals_wallet_credits >= 2) {
      dispatch(addDeal(value));
    } else {
      setPricingModelShow(true);
    }
  }

  const getPageSize = () => {
    const width = window.innerWidth;
    let columns = 1;
  
    if (width >= 1280) {
      columns = 4;
    } else if (width >= 1024) {
      columns = 3;
    } else if (width >= 640) {
      columns = 2;
    }
  
    return columns * 3; // Load 3 rows
  }

  const handlePageChange = () => {
    if (states?.length)
      dispatch(getDeals(`states=${states.map(state => state?.name).join(',')}&page=${page+1}&page_size=${getPageSize()}`));
    else
      dispatch(getDeals(`page=${page+1}&page_size=${getPageSize()}`));
    setPage(page+1)
  };

  useEffect(() => {
    if (projectTypePricing.length === 0) {
      dispatch(getProjectTypePricingList());
    }
  }, [dispatch, projectTypePricing.length]);

  useEffect(() => {
    if (user)
      dispatch(fetchPageFavorite("Deal Marketplace"));
    states?.length ?
      dispatch(getDeals(`states=${states.map(state => state?.name).join(',')}&page=${page}&page_size=${getPageSize()}`)) :
      dispatch(getDeals(`page=${page}&page_size=${getPageSize()}`));
    localStorage.setItem(`ganarpro_app${user?.id}`, "deal");
    
    const pageRecord = "Deal Marketplace";
    const type = "Deal Marketplace";
    dispatch(createPageView(pageRecord, path, type));

  }, [dispatch]);

  useEffect(() => {
    if (states?.length)
      dispatch(getDeals(`states=${states.map(state => state?.name).join(',')}&page=${1}&page_size=${getPageSize()}`));
    else
      dispatch(getDeals(`page=${1}&page_size=${getPageSize()}`));
    
    setPage(1)
    localStorage.setItem("deals_states", JSON.stringify(states));
  }, [states]);

  useEffect(() => {
    if (stateLabors.length === 0) {
      dispatch(getStateLaborPricingList());
    }
  }, [stateLabors.length]);

  return (
    <div className="bg-white">
      {(loading && !deals?.next) || profileLoading ? (
        <div className="m-auto large-screen-pag full-loader">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="bg bg-success pb-5">
            <Row className="pt-5">
              <Col className="d-flex justify-content-center align-items-center gap-2 pt-5 mt-5">
                <h1 className="text-white" style={{ fontSize: "4rem" }}>
                  Deal Opportunity Marketplace{" "}
                </h1>
                {user && (
                  <span className="d-flex justify-content-start">
                    <FontAwesomeIcon
                      size={"2x"}
                      icon={isFavorite ? solidStar : regularStar}
                      style={{
                        color: isFavorite ? "gold" : "transparent",
                        stroke: isFavorite ? "" : "gold",
                        strokeWidth: isFavorite ? "0" : "20",
                        cursor: "pointer",
                      }}
                      onClick={handleFavoriteToggle}
                      className="fw-bolder align-self-center my-4"
                    />
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-center justify-content-center mt-3">
                <h2 className="text-white">
                  Share deals publicly, add them to your pipeline, and
                  collaborate to close more effectively.
                </h2>
              </Col>
              <Link className="fs-4" style={{ color: 'blue' }} to="https://learn.ganarpro.com/deal-opportunity-marketplace/">Terms apply</Link>
            </Row>
          </div>
          <Container className="mb-5 mt-5">
            {/* <StyledForm> */}
              <div
                className="d-flex align-items-end gap-3 mb-5"
                style={{ width: "max-content", minWidth: "200px" }}
              >
                <p>Filter By: </p>
                <Form.Group as={Col}>
                  <Form.Label style={{ minWidth: '300px' }}></Form.Label>
                  <MultiSelectDropdown
                    customHandler={(e) => setStates(e)}
                    selectedRegions={states}
                  />
                </Form.Group>
              </div>
              <div className="project-grid">
                {deals.results?.map((project) => (
                  <ProjectDataCard
                    key={project.id}
                    {...project}
                    no_of_contacts={project?.deal_contacts?.length}
                    showPurchase
                    handleProjectDetailCLick={handleProjectDetailCLick}
                  />
                ))}
              </div>
              {!deals.results?.length &&
                <div className="fs-4 text-secondary fw-bold">
                  No Deal found
                </div>
              }
            {/* </StyledForm> */}
            {loading && deals?.next ? (
                <Spinner />
              ) : deals?.next && (
                  <Button
                    style={{ width: '100%'}}
                    className="mt-3 fs-5"
                    variant="secondary"
                    onClick={handlePageChange}
                  >
                    Currently showing {deals.results.length} deals. Load More {(deals.count - deals.results.length) > getPageSize() ? getPageSize() : deals.count - deals.results.length} deals
                  </Button>
              )}
          </Container>
        </>
      )}
      <PricingModal
        show={pricingModalShow}
        app="deals_credit"
        onHide={() => setPricingModelShow(false)}
      />
      <LoginModal show={modalShow} onHide={() => setModalShow(false)}/>
    </div>
  );
};

export default DealMarketplace;
