import React, { useState } from "react";
import { fetchRegions } from "../../store/actions/gc_qualify/gcQualify.actions";
import AsyncSelect from "react-select/async";

const MultiSelectDropdown = ({ customHandler, selectedRegions, isMulti = true }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const customFilter = (option, searchText) => {
    if (
      option.label.toLowerCase().includes(searchText.toLowerCase()) ||
      option.value.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleChange = (value) => {
    setSelectedValue(value);
    customHandler(value);
  };
  return (
    <AsyncSelect
      menuPortalTarget={document.body}
      cacheOptions
      defaultOptions
      isSearchable
      value={selectedValue ? selectedValue : selectedRegions}
      filterOption={customFilter}
      getOptionLabel={(e) => e.name}
      getOptionValue={(e) => e.name}
      loadOptions={fetchRegions}
      isMulti={isMulti}
      placeholder={isMulti && "Select State..."}
      // onInputChange={handleSelectRegion}
      onChange={handleChange}
      styles={{
        zIndex: 9999, // Increase z-index of the dropdown
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999, // Increase z-index of the dropdown menu
        }),
        control: (provided) => ({
          ...provided,
          zIndex: 1, // Keeps the input field's z-index lower
        }),
      }}
    />
  );
};

export default MultiSelectDropdown;
