import { delete$, get$, post$, put$ } from "./default";
import { api } from "../constants/api";

export const getCrmContactsAPI = (query) => {
    return get$(api.crm.getAllContacts, query);
};

export const createCrmContactAPI = (data) => {
    return post$(api.crm.createContact, data);
}

export const updateCrmContactAPI = (contactId, data) => {
    return put$(api.crm.updateContact.replace(":contactId", contactId), data);
};

export const deleteCrmContactAPI = (contactId) => {
    return delete$(api.crm.deleteContact.replace(":contactId", contactId));
};