import {
  CONTACT_DETAIL_FAIL,
  CONTACT_DETAIL_REQUEST,
  CONTACT_DETAIL_SUCCESS,
  PROJECT_TYPE_DETAIL_FAIL,
  PROJECT_TYPE_DETAIL_REQUEST,
  PROJECT_TYPE_DETAIL_SUCCESS,
  PROJECT_TYPE_LIST_FAIL,
  PROJECT_TYPE_LIST_REQUEST,
  PROJECT_TYPE_LIST_SUCCESS,
  PROPOSAL_CREATE_FAIL,
  PROPOSAL_CREATE_REQUEST,
  PROPOSAL_CREATE_SUCCESS,
  PROPOSAL_DELETE_FAIL,
  PROPOSAL_DELETE_REQUEST,
  PROPOSAL_DELETE_SUCCESS,
  PROPOSAL_DETAIL_FAIL,
  PROPOSAL_DETAIL_REQUEST,
  PROPOSAL_DETAIL_SUCCESS,
  PROPOSAL_DOCUMENT_DELETE_FAIL,
  PROPOSAL_DOCUMENT_DELETE_REQUEST,
  PROPOSAL_DOCUMENT_DELETE_SUCCESS,
  PROPOSAL_DOCUMENT_FAIL,
  PROPOSAL_DOCUMENT_REQUEST,
  PROPOSAL_DOCUMENT_SUCCESS,
  PROPOSAL_DOWNLOAD_FAIL,
  PROPOSAL_DOWNLOAD_REQUEST,
  PROPOSAL_DOWNLOAD_SUCCESS,
  PROPOSAL_LIST_FAIL,
  PROPOSAL_LIST_REQUEST,
  PROPOSAL_LIST_SUCCESS,
  PROPOSAL_SET_SELECTED_SECTIONS,
  PROPOSAL_SET_VALUES,
  PROPOSAL_UPDATE_FAIL,
  PROPOSAL_UPDATE_REQUEST,
  PROPOSAL_UPDATE_SUCCESS,
  SUBSCRIPTION_CREATE_FAIL,
  SUBSCRIPTION_CREATE_REQUEST,
  SUBSCRIPTION_CREATE_SUCCESS,
  UPLOAD_PROPOSAL_DOCUMENT_FAILURE,
  UPLOAD_PROPOSAL_DOCUMENT_REQUEST,
  UPLOAD_PROPOSAL_DOCUMENT_SUCCESS,
  USER_PROJECT_TYPE_LIST_FAIL,
  USER_PROJECT_TYPE_LIST_REQUEST,
  USER_PROJECT_TYPE_LIST_SUCCESS,
} from "../../constants/proposalConstants";
import {
  isEmpty,
  toastError,
  toastSuccess,
} from "../../../utils/helpers/helper";
import {
  createSubscriptionAPI,
  deleteProposalAPI,
  getAllProjectTypeAPI,
  getAllProposalAPI,
  getAllUserProjectTypeAPI,
  getProjectTypesDetailAPI,
  getProposalDetailAPI,
  saveProposalAPI,
  updateProposalAPI,
} from "../../../utils/requests/proposals";
import axios from "axios";
import { getContactRolesAPI } from "../../../utils/requests/callLogs";

export const saveProposal = (data, download = false) => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_CREATE_REQUEST,
    });
    return saveProposalAPI(data)
      .then(async (response) => {
        toastSuccess("Proposal Saved Successfully");

        dispatch({
          type: PROPOSAL_CREATE_SUCCESS,
          payload: data,
        });
        dispatch(getProjectType());
        dispatch(saveSelectedSections(data.id, data.sections));
        if (download) {
          document.location.href = `/my_proposal?show_tab=recent&download_id=${response.data?.id}&name=${data.project_name}_${Number(data.bid_amount)}`;
        } else {
          document.location.href = `/my_proposal?show_tab=recent`;
        }
      })
      .catch((error) => {
        toastError("Proposal Creation Failed");
        dispatch({
          type: PROPOSAL_CREATE_FAIL,
          payload: error.data,
        });
      });
  };
};

// export const downloadProposal =
//   (proposalId, proposal) => async (dispatch, getState) => {
//     try {
//       dispatch({
//         type: PROPOSAL_DOWNLOAD_REQUEST,
//       });
//       let { access } = getState();
//       if (isEmpty(access)) {
//         access = JSON.parse(localStorage.getItem("token"));
//       }
//       const config = {
//         headers: {
//           "content-type": "application/json",
//           Authorization: `Bearer ${access}`,
//         },
//         responseType: "blob",
//       };
//       const { data } = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/proposals/download/${proposalId}`,
//         proposal,
//         config
//       );
//       dispatch({
//         type: PROPOSAL_DOWNLOAD_SUCCESS,
//       });
//       const url = window.URL.createObjectURL(new Blob([data]));
//
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute(
//         "download",
//         `${proposal.project_name}_$${Number(
//           proposal.bid_amount
//         )}_Clean_Proposal.docx`
//       ); //or any other extension
//       document.body.appendChild(link);
//       link.click();
//     } catch (error) {
//       const message =
//         error.response && error.response.data
//           ? error.response.data
//           : error.message;
//
//       toastError("Proposal Download Failed");
//       dispatch({
//         type: PROPOSAL_DOWNLOAD_FAIL,
//         payload: error.data,
//       });
//     }
//   };

export const downloadProposal =
  (proposalId, name, checkBox) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_DOWNLOAD_REQUEST });

      let { access } = getState().auth; // Adjust based on where the access token is stored
      if (!access) {
        access = JSON.parse(localStorage.getItem("token"));
      }

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        responseType: "blob", // To handle binary data
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/proposals/download/${proposalId}`,
        {proposalId: proposalId},
        config
      );

      dispatch({ type: PROPOSAL_DOWNLOAD_SUCCESS });

      const contentDisposition = response.headers["content-disposition"];
      let filename = `${name}_Clean_Proposal.docx`;

      const contentType = response.headers["content-type"];
      let blobType = "application/octet-stream"; // Default MIME type

      if (contentType.includes("application/zip")) {
        blobType = "application/zip";
        filename += ".zip";
      } else if (contentType.includes("application/pdf")) {
        blobType = "application/pdf";
        filename += ".pdf";
      } else if (
        contentType.includes("application/msword") ||
        contentType.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        blobType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        filename += ".docx";
      }

      const blob = new Blob([response.data], { type: blobType });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;

      toastError("Proposal Download Failed");
      dispatch({ type: PROPOSAL_DOWNLOAD_FAIL, payload: message });
    }
  };

export const getUserProposals = (page = 1) => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_LIST_REQUEST,
    });
    return getAllProposalAPI(page)
      .then(async (response) => {
        dispatch({
          type: PROPOSAL_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROPOSAL_LIST_FAIL,
          payload: error,
        });
      });
  };
};

export const getContactRoles = (projectId) => {
  return async (dispatch) => {
    dispatch({
      type: CONTACT_DETAIL_REQUEST,
    });
    return getContactRolesAPI(projectId)
      .then(async (response) => {
        dispatch({
          type: CONTACT_DETAIL_SUCCESS,
          payload: response.info,
        });
      })
      .catch((error) => {
        dispatch({
          type: CONTACT_DETAIL_FAIL,
          payload: error,
        });
      });
  };
};

export const getProposalDetail = (proposalId) => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_DETAIL_REQUEST,
    });
    return getProposalDetailAPI(proposalId)
      .then(async (response) => {
        dispatch({
          type: PROPOSAL_DETAIL_SUCCESS,
          payload: response,
        });
        dispatch({
          type: PROJECT_TYPE_DETAIL_SUCCESS,
          payload: response.project_type,
        });

        dispatch({
          type: PROPOSAL_SET_SELECTED_SECTIONS,
          payload: response.sections,
        });

        const projectDetailResponse = {
          ...response,
          project_type: response.project_type.name,
          project_details: response.project_type,
        };
        dispatch({ type: PROPOSAL_SET_VALUES, payload: projectDetailResponse });
      })
      .catch((error) => {
        dispatch({
          type: PROPOSAL_DETAIL_FAIL,
          payload: error,
        });
      });
  };
};

export const updateProposal = (proposalId, data, download = false) => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_UPDATE_REQUEST,
    });
    return updateProposalAPI(proposalId, data)
      .then(async (response) => {
        toastSuccess("Proposal Updated Successfully");
        dispatch({
          type: PROPOSAL_UPDATE_SUCCESS,
          payload: response,
        });
        dispatch(saveSelectedSections(data.id, data.sections));
        if (download) {
          toastSuccess("Proposal Download Started");
          document.location.href = `/my_proposal?show_tab=recent&download_id=${proposalId}&name=${data.project_name}_${Number(data.bid_amount)}`;
        } else {
          document.location.href = "/my_proposal?show_tab=recent";
        }
      })
      .catch((error) => {
        toastError("Proposal Update Failed");
        dispatch({
          type: PROPOSAL_UPDATE_FAIL,
          payload: error.data,
        });
      });
  };
};

export const deleteProposal = (proposalId, editId) => {
  return async (dispatch) => {
    dispatch({
      type: PROPOSAL_DELETE_REQUEST,
    });
    return deleteProposalAPI(proposalId)
      .then(async (response) => {
        toastSuccess("Proposal Deleted Successfully");
        dispatch({
          type: PROPOSAL_DELETE_SUCCESS,
          payload: proposalId,
        });
        if (editId !== undefined) {
          window.location.href = "/my_proposal?show_tab=recent";
        }
      })
      .catch((error) => {
        toastError("Proposal Deletion Failed");
        dispatch({
          type: PROPOSAL_DELETE_FAIL,
          payload: error,
        });
      });
  };
};

export const createSubscription = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SUBSCRIPTION_CREATE_REQUEST,
    });
    return createSubscriptionAPI(data)
      .then(async (response) => {
        toastSuccess("Payment is Successful");
        dispatch({
          type: SUBSCRIPTION_CREATE_SUCCESS,
          payload: response,
        });
        document.location.href = "/";
      })
      .catch((error) => {
        toastError(`Payment Failed ${error.data?.detail || error.data?.error}`);
        dispatch({
          type: SUBSCRIPTION_CREATE_FAIL,
          payload: error.data.detail,
        });
      });
  };
};

export const getProjectType = () => {
  return async (dispatch) => {
    dispatch({
      type: PROJECT_TYPE_LIST_REQUEST,
    });
    return getAllProjectTypeAPI()
      .then(async (response) => {
        dispatch({
          type: PROJECT_TYPE_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROJECT_TYPE_LIST_FAIL,
          payload: error,
        });
      });
  };
};

export const getUserProjectType = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_PROJECT_TYPE_LIST_REQUEST,
    });
    return getAllUserProjectTypeAPI()
      .then(async (response) => {
        dispatch({
          type: USER_PROJECT_TYPE_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER_PROJECT_TYPE_LIST_FAIL,
          payload: error,
        });
      });
  };
};

export const getProjectTypeDetail = (projectTypeId) => {
  return async (dispatch) => {
    dispatch({
      type: PROJECT_TYPE_DETAIL_REQUEST,
    });
    return getProjectTypesDetailAPI(projectTypeId)
      .then(async (response) => {
        dispatch({
          type: PROJECT_TYPE_DETAIL_SUCCESS,
          payload: response,
        });

        let cleaning = {};
        let template = response?.user_template || response?.template;
        const parser = new DOMParser();
        const doc = parser.parseFromString(template, "text/html");
        cleaning = { heading: doc.querySelector("p")?.textContent };
        doc.querySelectorAll("ol").forEach((olTag) => {
          let key = olTag.previousElementSibling.textContent
            .toLowerCase()
            .replace(" clean", "");
          let values = [];
          for (let i = 0; i < olTag.children.length; i++) {
            values.push(olTag.children[i].textContent);
          }
          cleaning[key] = values;
        });

        dispatch({
          type: PROPOSAL_SET_VALUES,
          payload: { user_template: template, cleaning: cleaning },
        });
      })
      .catch((error) => {
        dispatch({
          type: PROJECT_TYPE_DETAIL_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchProposalFromStorage = () => {
  return async (dispatch) => {
    const proposal = JSON.parse(localStorage.getItem("proposal"));
    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: proposal,
    });
    if (proposal) {
      dispatch(getProjectTypeDetail(proposal?.project_type));
    }

    // localStorage.removeItem("proposal");
  };
};

export const saveSelectedSections = (user_id, sections) => {
  return async () => {
    localStorage.setItem(
      `selectedSections_${user_id}`,
      JSON.stringify(sections)
    );
  };
};

export const getSelectedSections = (user_id) => {
  return async (dispatch) => {
    let sections = localStorage.getItem(`selectedSections_${user_id}`);
    if (sections) {
      sections = JSON.parse(sections);
      dispatch({
        type: PROPOSAL_SET_SELECTED_SECTIONS,
        payload: sections,
      });
    }
  };
};

const uploadDocumentRequest = () => ({
  type: UPLOAD_PROPOSAL_DOCUMENT_REQUEST,
});

const uploadDocumentSuccess = (data) => ({
  type: UPLOAD_PROPOSAL_DOCUMENT_SUCCESS,
  payload: data,
});

const uploadDocumentFailure = (error) => ({
  type: UPLOAD_PROPOSAL_DOCUMENT_FAILURE,
  payload: error,
});

// The action creator for uploading documents
export const uploadProposalDocuments =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch(uploadDocumentRequest());

      let { access } = getState();
      if (isEmpty(access)) {
        access = JSON.parse(localStorage.getItem("token"));
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/proposals/documents`,
        formData,
        config
      );
      dispatch(uploadDocumentSuccess(data));
      toastSuccess("Document Upload Successful");
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch(uploadDocumentFailure(error.message));

      toastError("Document Upload Failed");
    }
  };

// The action creator for uploading documents
export const viewProposalDocuments =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch(uploadDocumentRequest());

      let { access } = getState();
      if (isEmpty(access)) {
        access = JSON.parse(localStorage.getItem("token"));
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/proposals/documents`,
        formData,
        config
      );
      dispatch(uploadDocumentSuccess(data));
      toastSuccess("Document Upload Successful");
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch(uploadDocumentFailure(error.message));

      toastError("Document Upload Failed");
    }
  };

// The action creator for uploading documents
export const deleteProposalDocuments =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROPOSAL_DOCUMENT_DELETE_REQUEST,
      });

      let { access } = getState();
      if (isEmpty(access)) {
        access = JSON.parse(localStorage.getItem("token"));
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
        data: formData,
      };
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/proposals/documents_delete`,
        config
      );
      dispatch({
        type: PROPOSAL_DOCUMENT_DELETE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({
        type: PROPOSAL_DOCUMENT_DELETE_FAIL,
        payload: message,
      });
    }
  };

export const fetchProposalDocuments = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROPOSAL_DOCUMENT_REQUEST,
    });

    let { access } = getState();
    if (isEmpty(access)) {
      access = JSON.parse(localStorage.getItem("token"));
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${access}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/proposals/documents_show`,
      config
    );
    dispatch({
      type: PROPOSAL_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data
        ? error.response.data
        : error.message;
    dispatch({
      type: PROPOSAL_DOCUMENT_FAIL,
      payload: message,
    });

    // toastError("Document Failed");
  }
};
