import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import ProposalForm from "../../components/ProposalForm";
import RecentProposal from "../../components/RecentProposal";
import ProposalPreviewForm from "../../components/ProposalPreviewForm";
import LoginModal from "../../components/LoginModal";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadProposal,
  getProposalDetail,
  saveProposal,
  updateProposal,
} from "../../store/actions/proposals/proposals.actions";
import ProposalPreviewModal from "../../components/ProposalPreviewModal";
import useIsMobile from "../../utils/helpers/use-is-mobile";
import SubscriptionModal from "../../components/subscriptionModal";
import { useNavigate } from "react-router";
import {
  PROPOSAL_RESSET_VALUES,
  PROPOSAL_SET_VALUES,
} from "../../store/constants/proposalConstants";
import { isSubscriptionActive, toastError } from "../../utils/helpers/helper";
import {
  addPageFavorite,
  createPageView,
  fetchPageFavorite,
  removePageFavorite,
} from "../../store/actions/users/users.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";
import RecentPaintProposal from "../../components/RecentPaintProposal";
import { getPaintProposalDetail, getProposalTemplates, savePaintProposal, updatePaintProposal } from "../../store/actions/paint_proposal/paint_proposal.action";
import { template } from "lodash";

const StyledSectionTwo = styled.div`
  background-color: rgb(246 248 250);
`;
const StyledRow = styled(Row)`
  padding-top: 4rem;
`;

const StyledPreviewText = styled.h3`
  color: #000;
  font-weight: 700;
`;

const StyledTabs = styled(Tabs)`
  background-color: #fff;
  h2 {
    color: #000;
  }
  button {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .nav-item button#noanim-tab-example-tab-new {
    color: #fff;
    background-color: #276fb4;
  }
`;

const PaintDocumentGenerator = () => {
  const isMobile = useIsMobile();
  let params = useParams();
  const { search } = window.location;
  const show_tab = new URLSearchParams(search).get("show_tab");
  let [searchParams] = useSearchParams();
  const project_type = searchParams.get("project_type");
  const { id } = params;
  const [modalShow, setModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [proposalPreviewModal, setProposalPreviewModal] = useState(false);
  const [showTab, setShowTab] = useState("home");
  const { user } = useSelector((state) => state.auth);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { user: profile, loading } = useSelector((state) => state.userDetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const proposalList = useSelector((state) => state.proposalList);
  const { proposals } = proposalList;
  const { proposal } = useSelector((state) => state.proposalDetail);
  const { projectTypes } = useSelector((state) => state.projectTypeList);
  const { proposalTemplates } = useSelector((state) => state.proposalTemplates);
  const values = useSelector((state) => state.proposalValues);
  const sections = useSelector((state) => state.proposalSections);
  const { error: errorForm, loading: createLoader } = useSelector(
    (state) => state.proposalCreate
  );
  const { loading: downloader } = useSelector(
    (state) => state.proposalDownload
  );
  const { hasFeatureAccess, enforceAccess } = useFeatureAccess("Paint Proposal");
  const { isFavorite, loading: fetchLoader } = useSelector(
    (state) => state.fetchPageFavorite
  );

  let proposalId;
  let proposalObject;
  if (proposals.length > 0) {
    if (id) {
      proposalId = proposal?.id;
      proposalObject = proposal;
    }
  }

  const ganarpro_app = localStorage.getItem(`ganarpro_app${user?.id}`);
  const paint_app = ganarpro_app ? ganarpro_app == "paint" : !profile?.is_cleaning_company;

  const handleSetPaymentModalShow = (e) => {
    e.preventDefault();
    localStorage.setItem("proposal", JSON.stringify(values));
    setPaymentModalShow(true);
  };

  const showFormTab = (e) => {
    if (e === "home") {
      setShowTab("home");
    } else if (e === "recent") {
      setShowTab("recent");
    } else if (e === "new") {
      dispatch({ type: PROPOSAL_RESSET_VALUES });

      const store_template = localStorage.getItem(`template_${user?.id}`);
      let selectedTemplate = proposalTemplates.find(
        (template) => template.name.toLowerCase() === (store_template ? store_template?.toLowerCase() : "drywall")
      );

      dispatch({
        type: PROPOSAL_SET_VALUES,
        payload: {
          proposal_template: selectedTemplate.name,
          disclosure: selectedTemplate.disclosure,
          scope: selectedTemplate.scope,
          user_scope: selectedTemplate.user_scope,
          scope_name: `Project Proposal for ${selectedTemplate.name.includes("Paint") ? "Paint" : "Drywall"}`
        },
      });

      navigate("/paint_proposal");
    } else if (id !== undefined) {
      setShowTab("home");
    }
  };
  const handleDownload = (e, id, proposal) => {
    let newValues = values;

    let trueKeys = Object.keys(sections)
      .map((key) => {
        if (sections[key]) {
          return key;
        }
      })
      .filter((key) => key);

    if (user) {
      newValues = {
        ...values,
        ...profile,
        current_date: new Date().toUTCString().slice(0, 16),
      };
    }

    try {
      delete newValues['project_type']
      newValues = {
        ...newValues,
        sections: trueKeys.join(","),
      };

      if (!user.id) {
        localStorage.setItem("proposal", JSON.stringify(values));
        setModalShow(true);
      } else {
        if (id) {
          dispatch(updatePaintProposal(values?.id, newValues, true));
        } else if (!hasFeatureAccess) {
          enforceAccess();
        } else {
          dispatch(savePaintProposal(newValues, true));
        }
      }
    } catch (e) {
      toastError("Unable to download proposal");
    }
  };

  const path = global.window.location.pathname.slice(1);

  useEffect(() => {
    const pageRecord = id ? values?.project_name : "Paint Proposal";
    const type = "Paint Proposal";

    dispatch(createPageView(pageRecord, path, type));
  }, [dispatch, values?.project_name]);

  useEffect(() => {
    if (id && user) {
      dispatch(getPaintProposalDetail(id));
      // setShowTab("home");
    }
    if (show_tab) {
      setShowTab("recent");
    }

  }, [dispatch, id, show_tab, user]);

  useEffect(() => {
    if (!proposalTemplates.length)
      return;

    const store_template = localStorage.getItem(`template_${user?.id}`);
    let selectedTemplate = proposalTemplates.find(
      (template) => template.name.toLowerCase() === (store_template ? store_template?.toLowerCase() : "drywall")
    );

    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: {
        proposal_template: selectedTemplate?.name,
        user_scope: selectedTemplate?.user_scope || selectedTemplate?.scope,
        disclosure: selectedTemplate?.disclosure,
        scope_name: `Project Proposal for ${selectedTemplate.name.includes("Paint") ? "Paint" : "Drywall"}`
      },
    });

  },[proposalTemplates])

  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removePageFavorite({ page_name: "Paint Proposal" })); // Assuming page_url is the identifier
    } else {
      dispatch(
        addPageFavorite({ page_name: "Paint Proposal", page_url: path })
      );
    }
  };

  useEffect(() => {
    if(!paint_app) {
      navigate('/my_proposal');
      return;
    }
    dispatch(fetchPageFavorite("Paint Proposal"));
    dispatch(getProposalTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (!values?.border_color) {
      dispatch({
        type: PROPOSAL_SET_VALUES,
        payload: { border_color: localStorage.getItem('border_color') }
      });
    }
  }, [values?.border_color])

  return (
    <div className="bg-white ">
      <Container>
        <Row className="justify-content-end">
          <Col md={6}>
            <FontAwesomeIcon
              size={"2x"}
              icon={isFavorite ? solidStar : regularStar}
              style={{
                color: isFavorite ? "gold" : "transparent",
                stroke: isFavorite ? "" : "gold",
                strokeWidth: isFavorite ? "0" : "20",
                cursor: "pointer",
              }}
              onClick={handleFavoriteToggle}
              className="fw-bolder align-self-center my-4"
            />
          </Col>
        </Row>
        <StyledRow>
          <Col xs={12} md={12} className="my-proposal-col">
            <StyledTabs
              activeKey={showTab}
              onSelect={showFormTab}
              transition={true}
              id="noanim-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="new"
                title="+"
                style={{ background: "blue", color: "white" }}
              />
              <Tab
                eventKey="home"
                title={
                  values?.project_name
                    ? values.project_name
                    : "Create New Proposal"
                }
                className="text-uppercase"
              >
                <Row>
                  <StyledSectionTwo className="col-sm-8 py-4 px-4">
                    <StyledPreviewText className="text-start">
                      Preview Section
                    </StyledPreviewText>
                    <div style={{ textTransform: "none" }}>
                      <ProposalPreviewForm />
                      <div className="text-center row mb-5 m-4 justify-content-center">
                        {isMobile ? (
                          <>
                            <Button
                              onClick={() => setProposalPreviewModal(true)}
                              className="me-5 col-md-3 "
                              disabled={downloader}
                            >
                              <h4>Preview</h4>
                            </Button>
                          </>
                        ) : (
                          <>
                            <div className="text-center"></div>
                            <Button
                              onClick={() => setProposalPreviewModal(true)}
                              className="me-5  col-md-2"
                            >
                              <h4> Preview</h4>
                            </Button>
                          </>
                        )}
                        {user &&
                          (isMobile ? (
                            <>
                              <Button
                                className="btn-success"
                                style={{ color: "#fff" }}
                                onClick={(e) =>
                                  handleDownload(e, values?.id, values?.project_name)
                                }
                                disabled={downloader || createLoader}
                              >
                                <h4>
                                  Download{" "}
                                  {downloader ||
                                    (createLoader && (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ))}
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>{" "}
                                </h4>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                className="btn-success  col-md-2 "
                                style={{ color: "#fff" }}
                                onClick={(e) =>
                                  handleDownload(e, values?.id, values?.project_name)
                                }
                                disabled={downloader || createLoader}
                              >
                                <h4>
                                  Download{" "}
                                  {downloader ||
                                    (createLoader && (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ))}
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>{" "}
                                </h4>
                              </Button>
                            </>
                          ))}
                      </div>
                    </div>
                  </StyledSectionTwo>
                  <div className="col-sm-4">
                    <ProposalForm
                      setModalShow={setModalShow}
                      setPaymentModalShow={setPaymentModalShow}
                      setShowTab={setShowTab}
                    />
                  </div>
                </Row>
              </Tab>
              <Tab eventKey="recent" title="Saved Proposals">
                <RecentPaintProposal
                  setModalShow={setModalShow}
                  showFormTab={showFormTab}
                />
              </Tab>
            </StyledTabs>
          </Col>
        </StyledRow>
      </Container>
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
      <SubscriptionModal
        show={paymentModalShow}
        onHide={() => setPaymentModalShow(false)}
      />
      <ProposalPreviewModal
        show={proposalPreviewModal}
        onHide={setProposalPreviewModal}
      />
    </div>
  );
};

export default PaintDocumentGenerator;
